import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Finalize from "../../../common/components/Finalize";
import FormStepper from "../../../common/components/FormStepper";
import Notes from "../../../common/components/Notes";
import PharmacyAddForm from "../../../common/components/PharmacyAddForm";
import PharmacyEditForm from "../../../common/components/PharmacyEditForm";
import ProviderAddForm from "../../../common/components/ProviderAddForm";
import ProviderEditForm from "../../../common/components/ProviderEditForm";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Toastr from "../../../common/components/Toast";
import CallAttempt from "./CallAttempt";
import FinalStepContent from "./FinalStep";
import FirstStepContent from "./FirstStep";
import ForthStepContent from "./ForthStep";
import MedicalHistory from "./MedicalHistory";
import SecondStepContent from "./SecondStep";
import ThirdStepContent from "./ThirdStep";

import { Drawer } from "antd";
import { newPatientOptions } from "../../../common/constants/initialData";
import { getProviderSchedule, putRequest } from "../../../services/api.services";
import { validateSteps } from "../../../services/validations";
import {
  actionAddAndSelect,
  filterAppointments,
  get_patient_notes,
  patient_insurances_id_insurances,
  resetPatientStates,
  saveCheckQuestion,
  setGlobalToastr,
  setStepData,
  set_communication_compelete,
  update_patient_ops,
} from "../../../store/actions";

const initialCondition = [
  { step: "demographics", status: false },
  { step: "insurance", status: false },
  { step: "medical", status: false },
  { step: "questions", status: false },
  { step: "schedule", status: false },
  { step: "communication", status: false },
  { step: "attempt", status: false },
  { step: "notes", status: false },
  { step: "finalize", status: false },
];

const formSteps = [
  {
    title: "Demographics",
    isConfirmed: true,
    content: FirstStepContent,
  },
  {
    title: "Insurance Details",
    isConfirmed: true,
    content: SecondStepContent,
  },
  {
    title: "Medical History",
    isConfirmed: true,
    content: MedicalHistory,
  },
  {
    title: "Questionnaire",
    isConfirmed: true,
    content: ThirdStepContent,
  },
  {
    title: "Appointment Schedule",
    isConfirmed: true,
    content: ForthStepContent,
  },
  {
    title: "Communication",
    isConfirmed: true,
    content: FinalStepContent,
  },
  {
    title: "Call Attempts",
    isConfirmed: true,
    content: CallAttempt,
  },
  {
    title: "Notes",
    isConfirmed: true,
    content: Notes,
  },
  {
    title: "Finalize",
    isConfirmed: true,
    content: Finalize,
  },
];

const formStepsAdd = [
  {
    title: "Demographics",
    isConfirmed: false,
    content: FirstStepContent,
  },
];

const formStepsProviderAdd = [
  {
    title: "Healthcare Provider Location",
    isConfirmed: false,
    content: ProviderAddForm,
  },
];

const formStepsProviderEdit = [
  {
    title: "Healthcare Provider Location Edit",
    isConfirmed: false,
    content: ProviderEditForm,
  },
];

const formStepsPharmacyAdd = [
  {
    title: "Pharmacy Information",
    isConfirmed: false,
    content: PharmacyAddForm,
  },
];

const formStepsPharmacyEdit = [
  {
    title: "Pharmacy Information Edit",
    isConfirmed: false,
    content: PharmacyEditForm,
  },
];

const NewPatientSideBar = React.forwardRef(({ isShow, handleCloseSidebar, selected, stepProps }, ref) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const dropdownOption = useSelector((state) => state.newPatient.dropdownOption);
  const scheduleOptions = useSelector((state) => state.newPatient.scheduleOptions);
  const [isFluSeason, setIsFluSeason] = useState(false);
  const [isOver62, setIsOver62] = useState(false);
  const scheduleLoading = useSelector((state) => state.newPatient.scheduleLoading);
  const locations = useSelector((state) => state.common.locations);
  const providers = useSelector((state) => state.common.only_providers);
  const filteredLocations = useSelector((state) => state.common.filteredLocations);
  const locationsAll = useSelector((state) => state.procedure.locations);
  const compelete_step_communication = useSelector((state) => state.common.compelete_step_communication);
  const checkApointment = useSelector((state) => state.common.checkApointment);

  const filteredProviders = useSelector((state) => state.common.filteredProviders);
  const providersAll = useSelector((state) => state.procedure.providers);
  const referralConditions = useSelector((state) => state.common.referralConditions);
  const referredProviders = useSelector((state) => state.common.referredProviders);
  const pharmacies = useSelector((state) => state.common.pharmacies);

  const pcpList = useSelector((state) => state.common.pcpList);
  const stepData = useSelector((state) => ({
    demographics: state.newPatient.demographics,
    insurance: state.newPatient.insurance,
    medical: {
      ...state.newPatient.medical,
      previous_medical_history: resource?.icd_codes || [],
    },
    questions: state.newPatient.questions,
    schedule: state.newPatient.schedule,
    communication: state.newPatient.communication,
    attempt: state.newPatient.attempt,
  }));
  const patientsNote = useSelector((state) => state.newPatient.patientsNote);
  const attempt = useSelector((state) => state.newPatient.attempt);
  const [hideTitle, setHideTitle] = useState(false);
  const isSave = useSelector((state) => state.patientPending.isSave);
  const enterInsuranceCheck = useSelector((state) => state.newPatient.enterInsuranceCheck);
  const action_add_and_select = useSelector((state) => state.common.action_add_and_select);
  const questions = useSelector((state) => state.newPatient.questions);
  const patient_all_insurances_id = useSelector((state) => state.newPatient.patient_all_insurances_id);
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [title, setTitle] = useState("");
  const [valueCheckAddForm, setValueCheckAddForm] = useState(false);
  const [isProviderAdding, setIsProviderAdding] = useState(false);
  const [isPharmacyAdding, setIsPharmacyAdding] = useState(false);
  const [valueProviderEdit, setValueProviderEdit] = useState(null);
  const [isProviderEdit, setIsProviderEdit] = useState(false);

  const [isPharmacyEdit, setIsPharmacyEdit] = useState(false);
  const [valuePharmacyEdit, setValuePharmacyEdit] = useState(null);

  const [conditions, setConditions] = useState(initialCondition);
  const [timer, setTimer] = useState(0);
  const [noteCancelValue, setNoteCancelValue] = useState("");
  const [showNote, setShowNote] = useState(false);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (selected === -1) {
      dispatch(setStepData("isAdded", true));
      setValueCheckAddForm(true);
    } else {
      setValueCheckAddForm(false);
      dispatch(setStepData("isAdded", false));
    }
  }, [selected]);

  useEffect(() => {
    if (resource?.communication_record?.length > 0) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [resource]);

  useEffect(() => {
    if (stepProps == -1) {
      setStep(0);
    } else {
      setStep(stepProps);
    }
  }, [stepProps]);

  const isToastr = useMemo(() => {
    if (isSave) {
      setTimer(0);
      setTimeout(() => {
        setTimer(600);
      }, 2000);
      return true;
    }
    return false;
  }, [isSave]);

  useEffect(() => {
    if (resource) {
      let a = moment();
      let b = resource?.date_of_birth ? moment(resource?.date_of_birth) : undefined;
      let ops_patient_id = resource?.id ? resource?.id : "";
      const fullName = `${resource?.first_name || ""} ${resource?.last_name || ""} `;
      const newTitle = fullName.toUpperCase() + `(` + a.diff(b, "years") + ` yo MALE)` + ` (OPS ID: ${ops_patient_id})`;
      setTitle(newTitle);

      if (a.diff(b, "years") >= 65) {
        setIsOver62(true);
      } else {
        setIsOver62(false);
      }
      if (Number(moment().format("MM")) < 10 && Number(moment().format("MM")) > 2) {
        setIsFluSeason(false);
      } else {
        setIsFluSeason(true);
      }

      setShowNote(false);
      setNoteCancelValue("");
      dispatch(get_patient_notes(resource?.id));
    }
  }, [resource]);

  useEffect(() => {
    const newConditions = [...conditions];
    if (resource) {
      for (let i = 0; i < 7; i++) {
        const validate = validateSteps(stepData[conditions[i].step], newPatientOptions[conditions[i].step]);
        newConditions[i].status = validate;
      }
      if (valueCheckAddForm) {
        newConditions[0].status = false;
      }
      if (stepData["medical"]) {
        if (
          stepData["medical"]["alcoholic_drink_pw"] != "" &&
          ((stepData["medical"]["allergies"] && stepData["medical"]["allergies"].length > 0) || stepData["medical"]["no_drug_allergies"] == true) &&
          stepData["medical"]["amputation"] !== -1 &&
          (isFluSeason ? stepData["medical"]["flu_vaccination"] !== -1 : stepData["medical"]["flu_vaccination"] != null) &&
          (isOver62
            ? stepData["medical"]["pneumococcal"] != -1
            : stepData["medical"]["pneumococcal"] != null ||
              stepData["medical"]["pneumococcal"] == -1 ||
              stepData["medical"]["pneumococcal"] == null) &&
          stepData["medical"]["aortic_disease"] !== -1 &&
          stepData["medical"]["heart_disease"] !== -1 &&
          ((stepData["medical"]["medications"] && stepData["medical"]["medications"].length > 0) || stepData["medical"]["no_medication"] == true) &&
          ((stepData["medical"]["previous_medical_history"] && stepData["medical"]["previous_medical_history"].length > 0) ||
            stepData["medical"]["no_medical_history"] == true ||
            stepData["medical"]["no_medical_history"] == 1) &&
          stepData["medical"]["previous_smoking_ppd"] != "" &&
          ((stepData["medical"]["previous_surgical_history"] && stepData["medical"]["previous_surgical_history"].length > 0) ||
            stepData["medical"]["no_surgical_history"] == true ||
            stepData["medical"]["no_surgical_history"] == 1) &&
          (stepData["medical"]["smoking_ppd"] != "" || stepData["medical"]["smoking_ppd"] == 0) &&
          stepData["medical"]["years_of_smoking"] != ""
        ) {
          newConditions[2].status = true;
        } else {
          newConditions[2].status = false;
        }
      }
      if (questions && Object.keys(questions).length > 0) {
        const mapQuestion = Object.keys(questions).map((r) => {
          return questions[r];
        });
        if (!mapQuestion.some((e) => e === -1)) {
          newConditions[3].status = true;
        } else {
          newConditions[3].status = false;
        }
      }
      if (
        patient_all_insurances_id &&
        patient_all_insurances_id.some(
          (variable) => variable.is_verified && variable.is_verified === 1 && variable.is_eligible && variable.is_eligible === 1
        )
      ) {
        newConditions[1].status = true;
      } else {
        newConditions[1].status = false;
      }
      if (enterInsuranceCheck) {
        newConditions[1].status = true;
      }

      if (checkApointment) {
        newConditions[4].status = true;
      } else {
        if (resource?.is_completed_scheduling && resource?.is_completed_scheduling == 1) {
          newConditions[4].status = true;
        } else {
          newConditions[4].status = false;
        }
      }
      if (check) {
        newConditions[5].status = true;
      } else {
        newConditions[5].status = false;
      }
      if (attempt && attempt?.call_attempts && attempt?.call_attempts.length > 0) {
        newConditions[6].status = true;
      } else {
        newConditions[6].status = false;
      }
      if (patientsNote && patientsNote.length > 0) {
        newConditions[7].status = true;
      } else {
        newConditions[7].status = false;
      }
      dispatch(saveCheckQuestion(newConditions[3].status));
      setConditions(newConditions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resource,
    step,
    isOver62,
    isFluSeason,
    enterInsuranceCheck,
    patient_all_insurances_id,
    checkApointment,
    valueCheckAddForm,
    attempt,
    patientsNote,
    check,
    questions,
  ]);

  const handleSubmit = () => {
    if (step < 8) {
      setStep(step + 1);
    } else {
      // Todo: handleSubmit
    }
  };

  const onGetPending = async () => {
    await dispatch(set_communication_compelete(false));
  };

  const handleScheduleOption = (idxOfSchedule, newOption) => {
    const newScheduleOption = [...scheduleOptions];
    newScheduleOption[idxOfSchedule] = newOption;
    dispatch(setStepData("scheduleOptions", newScheduleOption));
  };

  const handleProviderSchedule = async (location_id, provider_id, idxOfSchedule) => {
    if (location_id && provider_id) {
      return;
    }
    const newOption = [...dropdownOption];
    if (!location_id && !provider_id) {
      newOption[idxOfSchedule] = { type: "", list: [] };
      dispatch(setStepData("dropdownOption", newOption));
      return;
    }
    try {
      const result = await getProviderSchedule(resource?.id, location_id, provider_id);
      let type = "";
      if (!location_id) {
        type = "location";
      } else if (!provider_id) {
        type = "provider";
      }
      newOption[idxOfSchedule] = { type, list: result.data };
      dispatch(setStepData("dropdownOption", newOption));
    } catch (error) {
      dispatch(setStepData("dropdownOption", dropdownOption));
    }
  };

  // test initial scheduling
  const initialSchedule = async () => {
    dispatch(setStepData("initialLoading", true));
  };

  const handleSchedule = (location_id, provider_id, period, prev, idxOfSchedule, action = "", custom, load_next) => {
    const loadingOfSchedules = [...scheduleLoading];
    if (action === "row" && idxOfSchedule >= loadingOfSchedules.length - 1) {
      return;
    }
    if (action === "row") {
      loadingOfSchedules[idxOfSchedule + 1] = true;
    } else {
      loadingOfSchedules[idxOfSchedule] = true;
    }
    dispatch(setStepData("scheduleLoading", loadingOfSchedules));
    dispatch(filterAppointments(resource?.id, location_id, provider_id, period, prev, idxOfSchedule, action, custom, load_next));
  };

  const checkIsInsurances = async (value) => {
    if (value) {
      await dispatch(patient_insurances_id_insurances(value));
    }
  };

  const setStateAddForm = (value) => {
    setValueCheckAddForm(value);
  };

  const setProviderAddForm = (value) => {
    setIsProviderAdding(value);
  };

  const setProviderEditForm = (status, valueDetail) => {
    setIsProviderEdit(status);
    setValueProviderEdit(valueDetail);
  };

  const setPharmacyAddForm = (value) => {
    setIsPharmacyAdding(value);
  };

  const setPharmacyEditForm = (value, valueDetail) => {
    setIsPharmacyEdit(value);
    setValuePharmacyEdit(valueDetail);
  };

  const handleCloseStep = async (step, currentStep) => {
    if (step !== 4 && currentStep == 3) {
      await putRequest(resource?.id, questions, "questionnaire");
    }
    if (step == 0) {
      dispatch(update_patient_ops(resource?.id));
    }
  };

  useEffect(() => {
    setIsProviderAdding(false);
    setIsProviderEdit(false);
    setValueProviderEdit(null);
    setIsPharmacyEdit(false);
    setValuePharmacyEdit(null);
  }, [resource]);

  useEffect(() => {
    if (action_add_and_select) {
      setTimeout(() => {
        dispatch(actionAddAndSelect());
      }, 100);
    }
  }, [action_add_and_select]);

  const actionShowNote = (value) => {
    setShowNote(value);
  };

  const onCancel = () => {
    handleCloseSidebar();
    dispatch(resetPatientStates());
  };

  const setNoteCancelValueCheck = (value) => {
    setNoteCancelValue(value);
  };

  const checkIsAdding = (value) => {
    setHideTitle(value);
  };

  const checkAction = (value) => {
    setCheck(value);
  };

  const handleClickStep = async (index) => {
    if (index === 4 && resource?.id) {
      if (questions && Object.keys(questions).length > 0) {
        const mapQuestion = Object.keys(questions).map((r) => {
          return questions[r];
        });
        if (!mapQuestion.some((e) => e === -1)) {
          setStep(index);
        } else {
          dispatch(
            setGlobalToastr({
              header: "Appointment Schedule",
              message: "Please save questionnaire",
              type: "failed",
            })
          );
        }
      }
    } else {
      setStep(index);
    }
  };

  return (
    <Drawer
      title={
        valueCheckAddForm
          ? "ADD NEW PATIENT"
          : isProviderAdding
          ? "ADD NEW PROVIDER"
          : isPharmacyAdding
          ? "ADD NEW PHARMACY"
          : `Patient dashboard - ${resource?.first_name} ${resource?.last_name}`
      }
      placement="right"
      closable
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div data-testid="NewPatientSidebar" id="out-step" ref={ref} className="resourceContainer new">
        {isToastr && timer < 600 && (
          <div style={{ position: "fixed", top: "60px", right: "2px", zIndex: 10 }}>
            <Toastr type="New Patient" status="success" msg="Added New Patient Successfully!" />
          </div>
        )}
        <ScrollWrapper css="x-hidden">
          <FormStepper
            compelete_step_communication={compelete_step_communication}
            checkIsAdding={(value) => {
              checkIsAdding(value);
            }}
            showNote={showNote}
            checkAction={checkAction}
            setNoteCancelValue={setNoteCancelValueCheck}
            noteCancelValue={noteCancelValue}
            onCancel={onCancel}
            actionShowNote={actionShowNote}
            checkIsInsurances={(value) => {
              checkIsInsurances(value);
            }}
            type="newPatient"
            onGetPending={onGetPending}
            setStateAddForm={setStateAddForm}
            valueProviderEdit={valueProviderEdit}
            valuePharmacyEdit={valuePharmacyEdit}
            setProviderEditForm={setProviderEditForm}
            setProviderAddForm={setProviderAddForm}
            setPharmacyAddForm={setPharmacyAddForm}
            setPharmacyEditForm={setPharmacyEditForm}
            steps={
              valueCheckAddForm
                ? formStepsAdd
                : isProviderAdding
                ? formStepsProviderAdd
                : isProviderEdit
                ? formStepsProviderEdit
                : isPharmacyAdding
                ? formStepsPharmacyAdd
                : isPharmacyEdit
                ? formStepsPharmacyEdit
                : formSteps
            }
            currentStep={step}
            handleStep={(index) => {
              handleClickStep(index);
            }}
            onCloseStep={handleCloseStep}
            handleContinue={handleSubmit}
            locations={locations}
            providers={providers}
            filteredLocations={filteredLocations || locationsAll}
            filteredProviders={filteredProviders || providersAll}
            conditions={conditions}
            referredProviders={referredProviders}
            referralConditions={referralConditions}
            pcpList={pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList}
            handleAppointmentSchedule={handleSchedule}
            initialAppointmentSchedule={initialSchedule}
            handleProviderSchedule={handleProviderSchedule}
            handleScheduleOption={handleScheduleOption}
            handleCloseSidebar={handleCloseSidebar}
            pharmacies={pharmacies}
            isFormOpened={valueCheckAddForm}
          />

          <div style={{ width: "100%", paddingBottom: "2rem" }} />
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default NewPatientSideBar;
