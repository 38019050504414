import { Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../../common/components/Loading";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { StaticResource } from "../../constant";
import SchedulesSidebar from "./SchedulesSidebar";

import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../../common/components/icons/DeleteIconButton";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import {
  deleteProviderSchedule,
  getLocations,
  getProcedureData,
  getProvidersSortSchedule,
  getProviderUser,
  getReferralConditions,
  postProviderSaveSchedule,
  setGlobalLoading,
} from "../../../../store/actions/common.js";

const { Option } = Select;

const Schedules = ({ title }) => {
  const [isSidebar, setIsSidebar] = useState(false);

  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [userID, setUserID] = useState(1);
  const [loading, setLoading] = useState(true);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.common.locations);
  const ScheduleRow = useSelector((state) => state.common.providers);
  const [tableSrow, setTableSrow] = useState([]);
  const loading_save_schedule = useSelector((state) => state.common.loading_save_schedule);
  const conditions = useSelector((state) => state.common.referralConditions);
  const providerUser = useSelector((state) => state.common.providerUser);
  const procedureData = useSelector((state) => state.common.procedureData);

  useEffect(() => {
    setTableSrow(ScheduleRow);
    if (loading) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [ScheduleRow, loading]);

  const tableRows = new Array(1).fill(0);
  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, id, row) => async () => {
    if (!row.isAdd) {
      setLoading(true);
      setRow(index);
      setUserID(id);
      if (!providerUser || providerUser?.id !== id) {
        await dispatch(getProviderUser(id));
        handleSidebar();
      }
      setLoading(false);
    }
  };

  const fetchData = async () => {
    // dispatch(getLocations());
    dispatch(getProvidersSortSchedule());
    // dispatch(getReferralConditions());
    dispatch(getProcedureData());
  };

  const onSlected = async (item, week, day, type) => {
    if (!type) {
      setLoading(true);
      const postApi = await dispatch(
        postProviderSaveSchedule(userID, {
          location_id: item,
          week_of_month: week,
          day: day,
        })
      );
      const renderApi = await dispatch(getProvidersSortSchedule());
      if (postApi && renderApi) {
        setLoading(false);
      }
    } else {
      const index = tableSrow.findIndex((r) => r.isAdd && r.isAdd == type);
      if (index > -1) {
        setLoading(true);
        const postApi = await dispatch(
          postProviderSaveSchedule(tableSrow[index]["provider_id"], {
            location_id: item,
            week_of_month: week,
            day: day,
          })
        );
        const renderApi = await dispatch(getProvidersSortSchedule());
        if (postApi && renderApi) {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData().then(() => {
      setLoading(false);
    });
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  const addNew = (type) => {
    if (!tableSrow.some((r) => r.isAdd && r.isAdd == type)) {
      setTableSrow([
        {
          isAdd: type,
          value: null,
          locations: [],
          provider_id: null,
        },
        ...tableSrow,
      ]);
    }
  };

  const handleDelete = async (row, index, type) => {
    if (activeDelete !== row?.id) {
      setActiveDelete(row?.id);
      return;
    }
    if (index === activeIndex) {
      setRow(-1);
    }
    setLoading(true);
    await dispatch(
      deleteProviderSchedule(row?.id, {
        week_of_month: Number(type),
      })
    );
    await dispatch(getProvidersSortSchedule());
    setLoading(false);
  };

  const changeLocation = (value, type) => {
    const index = tableSrow.findIndex((r) => r.isAdd && r.isAdd == type);
    if (index > -1) {
      tableSrow[index] = { ...tableSrow[index], provider_id: value };
      setTableSrow([...tableSrow]);
    }
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      {loading_save_schedule && <Loading></Loading>}
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableHeader">
            <div className="tableItem">
              <div className="th" style={{ width: "calc(22% - 12px)", borderLeft: "0" }}>
                <b>Week 1</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Mon</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Tues</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Wed</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Thur</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Fri</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Sat</b>
              </div>
              <div
                className="th"
                style={{
                  width: "60px",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                onClick={() => {
                  addNew("1");
                }}
              >
                <b style={{ color: "blue" }}>Add +</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper css="no-padding-admin">
                <div className="tableList">
                  {(tableSrow || []).map((row, index) => {
                    if ((row.locations || []).some((r) => r.pivot && r.pivot.week_of_month == 1) || (row.isAdd && row.isAdd == "1")) {
                      return (
                        <div
                          className={`tableItem yellow ${providerUser && providerUser?.id === row?.id ? "active" : ""} ${row.isAdd ? "active" : ""}`}
                          key={`resultTable-${title}-${index}`}
                          onClick={handleRow(index, row?.id, row)}
                        >
                          {row.isAdd && row.isAdd == 1 ? (
                            <div
                              className="td with-icon"
                              style={{
                                width: "calc(22% - 12px)",
                                paddingRight: "2px",
                              }}
                            >
                              <Select
                                onClear={() => {
                                  changeLocation("", "1");
                                }}
                                disabled={loading}
                                placeholder="Provider"
                                suffixIcon={<ExpandSVG />}
                                onFocus={handleRow(index, row?.id, row)}
                                allowClear={activeIndex == index}
                                onChange={(value) => {
                                  changeLocation(value, "1");
                                }}
                                style={{
                                  width: "100%",
                                  borderBottom: "0px",
                                }}
                              >
                                {(tableSrow.filter((check) => !(check.locations || []).some((r) => r.pivot && r.pivot.week_of_month == 1)) || []).map(
                                  (opt) => {
                                    return (
                                      <Option key={`${index}-1-${opt?.id}`} value={opt?.id}>
                                        {opt?.value}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </div>
                          ) : (
                            <div
                              className="td with-icon"
                              style={{
                                width: "calc(22% - 12px)",
                                paddingRight: "2px",
                              }}
                            >
                              <p>{row?.value}</p>
                            </div>
                          )}
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              allowClear={activeIndex == index}
                              onFocus={handleRow(index, row?.id, row)}
                              onChange={(value) => onSlected(value || "", 1, 1, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 1 && r.pivot && r.pivot.week_of_month === 1) || {}).id}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-1-1-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              placeholder="Location"
                              onFocus={handleRow(index, row?.id, row)}
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 1, 2, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 2 && r.pivot && r.pivot.week_of_month === 1) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-1-2-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              placeholder="Location"
                              onFocus={handleRow(index, row?.id, row)}
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 1, 3, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 3 && r.pivot && r.pivot.week_of_month === 1) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-1-3-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              placeholder="Location"
                              onFocus={handleRow(index, row?.id, row)}
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 1, 4, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 4 && r.pivot && r.pivot.week_of_month === 1) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-1-4-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              placeholder="Location"
                              onFocus={handleRow(index, row?.id, row)}
                              onChange={(value) => onSlected(value || "", 1, 5, row.isAdd ? row.isAdd : false)}
                              suffixIcon={<ExpandSVG />}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 5 && r.pivot && r.pivot.week_of_month === 1) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-1-5-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              placeholder="Location"
                              onFocus={handleRow(index, row?.id, row)}
                              onChange={(value) => onSlected(value || "", 1, 6, row.isAdd ? row.isAdd : false)}
                              suffixIcon={<ExpandSVG />}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 6 && r.pivot && r.pivot.week_of_month === 1) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-1-6-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DeleteIcon icon="can" handleClick={() => handleDelete(row, index, "1")} selected={activeDelete === row?.id} />
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {emptyRows.map((row, index) => (
                    <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                      <div className="td" style={{ width: "22%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>

          <div className="tableHeader">
            <div className="tableItem">
              <div className="th" style={{ width: "calc(22% - 12px)", borderLeft: "0" }}>
                <b>Week 2</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Mon</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Tues</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Wed</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Thur</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Fri</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Sat</b>
              </div>
              <div
                className="th"
                style={{
                  width: "60px",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                onClick={() => {
                  addNew("2");
                }}
              >
                <b style={{ color: "blue" }}>Add +</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper css="no-padding-admin">
                <div className="tableList">
                  {(tableSrow || []).map((row, index) => {
                    if ((row.locations || []).some((r) => r.pivot && r.pivot.week_of_month == 2) || (row.isAdd && row.isAdd == "2")) {
                      return (
                        <div
                          className={`tableItem yellow ${providerUser && providerUser?.id === row?.id ? "active" : ""} ${row.isAdd ? "active" : ""}`}
                          key={`resultTable-2-${title}-${index}`}
                          onClick={handleRow(index, row?.id, row)}
                        >
                          {row.isAdd && row.isAdd == 2 ? (
                            <div
                              className="td with-icon"
                              style={{
                                width: "calc(22% - 12px)",
                                paddingRight: "2px",
                              }}
                            >
                              <Select
                                onClear={() => {
                                  changeLocation("", "2");
                                }}
                                disabled={loading}
                                placeholder="Provider"
                                onFocus={handleRow(index, row?.id, row)}
                                suffixIcon={<ExpandSVG />}
                                allowClear={activeIndex == index}
                                onChange={(value) => {
                                  changeLocation(value, "2");
                                }}
                                style={{
                                  width: "100%",
                                  borderBottom: "0px",
                                }}
                              >
                                {(tableSrow.filter((check) => !(check.locations || []).some((r) => r.pivot && r.pivot.week_of_month == 2)) || []).map(
                                  (opt) => {
                                    return (
                                      <Option key={`${index}-2-${opt?.id}`} value={opt?.id}>
                                        {opt?.value}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </div>
                          ) : (
                            <div
                              className="td with-icon"
                              style={{
                                width: "calc(22% - 12px)",
                                paddingRight: "2px",
                              }}
                            >
                              <p>{row?.value}</p>
                            </div>
                          )}
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              placeholder="Location"
                              onFocus={handleRow(index, row?.id, row)}
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 2, 1, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 1 && r.pivot && r.pivot.week_of_month === 2) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-2-1-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 2, 2, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 2 && r.pivot && r.pivot.week_of_month === 2) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-2-2-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 2, 3, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 3 && r.pivot && r.pivot.week_of_month === 2) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-2-3-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              onChange={(value) => onSlected(value || "", 2, 4, row.isAdd ? row.isAdd : false)}
                              suffixIcon={<ExpandSVG />}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 4 && r.pivot && r.pivot.week_of_month === 2) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-2-4-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 2, 5, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 5 && r.pivot && r.pivot.week_of_month === 2) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-2-5-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 2, 6, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 6 && r.pivot && r.pivot.week_of_month === 2) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-2-6-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DeleteIcon icon="can" handleClick={() => handleDelete(row, index, "2")} selected={activeDelete === row?.id} />
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {emptyRows.map((row, index) => (
                    <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                      <div className="td" style={{ width: "22%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>

          <div className="tableHeader">
            <div className="tableItem">
              <div className="th" style={{ width: "calc(22% - 12px)", borderLeft: "0" }}>
                <b>Week 3</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Mon</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Tues</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Wed</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Thur</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Fri</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Sat</b>
              </div>
              <div
                className="th"
                style={{
                  width: "60px",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                onClick={() => {
                  addNew("3");
                }}
              >
                <b style={{ color: "blue" }}>Add +</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper css="no-padding-admin">
                <div className="tableList">
                  {(tableSrow || []).map((row, index) => {
                    if ((row.locations || []).some((r) => r.pivot && r.pivot.week_of_month == 3) || (row.isAdd && row.isAdd == "3")) {
                      return (
                        <div
                          className={`tableItem yellow ${providerUser && providerUser?.id === row?.id ? "active" : ""} ${row.isAdd ? "active" : ""}`}
                          key={`resultTable-3-${title}-${index}`}
                          onClick={handleRow(index, row?.id, row)}
                        >
                          {row.isAdd && row.isAdd == 3 ? (
                            <div
                              className="td with-icon"
                              style={{
                                width: "calc(22% - 12px)",
                                paddingRight: "2px",
                              }}
                            >
                              <Select
                                onClear={() => {
                                  changeLocation("", "3");
                                }}
                                disabled={loading}
                                onFocus={handleRow(index, row?.id, row)}
                                placeholder="Provider"
                                suffixIcon={<ExpandSVG />}
                                allowClear={activeIndex == index}
                                onChange={(value) => {
                                  changeLocation(value, "3");
                                }}
                                style={{
                                  width: "100%",
                                  borderBottom: "0px",
                                }}
                              >
                                {(tableSrow.filter((check) => !(check.locations || []).some((r) => r.pivot && r.pivot.week_of_month == 3)) || []).map(
                                  (opt) => {
                                    return (
                                      <Option key={`${index}-3-${opt?.id}`} value={opt?.id}>
                                        {opt?.value}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </div>
                          ) : (
                            <div
                              className="td with-icon"
                              style={{
                                width: "calc(22% - 12px)",
                                paddingRight: "2px",
                              }}
                            >
                              <p>{row?.value}</p>
                            </div>
                          )}
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 3, 1, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 1 && r.pivot && r.pivot.week_of_month === 3) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-3-1-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 3, 2, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 2 && r.pivot && r.pivot.week_of_month === 3) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-3-2-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 3, 3, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 3 && r.pivot && r.pivot.week_of_month === 3) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-3-3-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 3, 4, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 4 && r.pivot && r.pivot.week_of_month === 3) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-3-4-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 3, 5, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 5 && r.pivot && r.pivot.week_of_month === 3) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-3-5-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 3, 6, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 6 && r.pivot && r.pivot.week_of_month === 3) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-3-6-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DeleteIcon icon="can" handleClick={() => handleDelete(row, index, "3")} selected={activeDelete === row?.id} />
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {emptyRows.map((row, index) => (
                    <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                      <div className="td" style={{ width: "22%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>

          <div className="tableHeader">
            <div className="tableItem">
              <div className="th" style={{ width: "calc(22% - 12px)", borderLeft: "0" }}>
                <b>Week 4</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Mon</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Tues</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Wed</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Thur</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Fri</b>
              </div>
              <div className="th" style={{ width: "calc(13% - 8px)" }}>
                <b>Sat</b>
              </div>
              <div
                className="th"
                style={{
                  width: "60px",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                onClick={() => {
                  addNew("4");
                }}
              >
                <b style={{ color: "blue" }}>Add +</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper css="no-padding-admin">
                <div className="tableList">
                  {(tableSrow || []).map((row, index) => {
                    if ((row.locations || []).some((r) => r.pivot && r.pivot.week_of_month == 4) || (row.isAdd && row.isAdd == "4")) {
                      return (
                        <div
                          className={`tableItem yellow ${providerUser && providerUser?.id === row?.id ? "active" : ""} ${row.isAdd ? "active" : ""}`}
                          key={`resultTable-4-${title}-${index}`}
                          onClick={handleRow(index, row?.id, row)}
                        >
                          {row.isAdd && row.isAdd == 4 ? (
                            <div
                              className="td with-icon"
                              style={{
                                width: "calc(22% - 12px)",
                                paddingRight: "2px",
                              }}
                            >
                              <Select
                                onClear={() => {
                                  changeLocation("", "4");
                                }}
                                disabled={loading}
                                onFocus={handleRow(index, row?.id, row)}
                                placeholder="Provider"
                                suffixIcon={<ExpandSVG />}
                                allowClear={activeIndex == index}
                                onChange={(value) => {
                                  changeLocation(value, "4");
                                }}
                                style={{
                                  width: "100%",
                                  borderBottom: "0px",
                                }}
                              >
                                {(tableSrow.filter((check) => !(check.locations || []).some((r) => r.pivot && r.pivot.week_of_month == 4)) || []).map(
                                  (opt) => {
                                    return (
                                      <Option key={`${index}-4-${opt?.id}`} value={opt?.id}>
                                        {opt?.value}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                            </div>
                          ) : (
                            <div
                              className="td with-icon"
                              style={{
                                width: "calc(22% - 12px)",
                                paddingRight: "2px",
                              }}
                            >
                              <p>{row?.value}</p>
                            </div>
                          )}
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 4, 1, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 1 && r.pivot && r.pivot.week_of_month === 4) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-4-1-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 4, 2, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 2 && r.pivot && r.pivot.week_of_month === 4) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-4-2-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 4, 3, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 3 && r.pivot && r.pivot.week_of_month === 4) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-4-3-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 4, 4, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 4 && r.pivot && r.pivot.week_of_month === 4) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-4-4-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 4, 5, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 5 && r.pivot && r.pivot.week_of_month === 4) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-4-5-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "calc(13% - 8px)",
                              paddingRight: "2px",
                            }}
                          >
                            <Select
                              disabled={loading}
                              onFocus={handleRow(index, row?.id, row)}
                              placeholder="Location"
                              suffixIcon={<ExpandSVG />}
                              onChange={(value) => onSlected(value || "", 4, 6, row.isAdd ? row.isAdd : false)}
                              value={(row.locations.find((r) => r.pivot && r.pivot.day === 6 && r.pivot && r.pivot.week_of_month === 4) || {}).id}
                              allowClear={activeIndex == index}
                              style={{
                                width: "100%",
                                borderBottom: "0px",
                              }}
                            >
                              {(locations || []).map((opt) => {
                                return (
                                  <Option key={`${index}-4-6-${opt?.id}`} value={opt?.id}>
                                    {opt?.value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            className="td"
                            style={{
                              width: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DeleteIcon icon="can" handleClick={() => handleDelete(row, index, "4")} selected={activeDelete === row?.id} />
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {emptyRows.map((row, index) => (
                    <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                      <div className="td" style={{ width: "22%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                      <div className="td" style={{ width: "12%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>
      {providerUser && activeIndex >= 0 && (
        <SchedulesSidebar
          ref={sidebarRef}
          procedureData={procedureData}
          selectedProcedureData={(providerUser && providerUser.procedure_types ? providerUser.procedure_types : []).map((r) => r?.value)}
          selectedConditions={(providerUser && providerUser.conditions ? providerUser.conditions : []).map((r) => r?.value)}
          userDetail={providerUser || {}}
          resource={StaticResource}
          conditions={conditions}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default Schedules;
