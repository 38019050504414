import React, { useState } from "react";
import CardBlock from "../../../common/components/Card";
import "../style.scss";
import OBLOptimizer from "./OBLOptimizer";
// Icons

const ProcedureDetail = (props) => {
  const [insuranceState, setInsuranceState] = useState([]);

  const handleUpdate = (type, index, value, indexItem) => {
    if (indexItem || indexItem === 0) {
      insuranceState[index]["dataCPT"][indexItem][type] = value;
      setInsuranceState([...insuranceState]);
    } else {
      insuranceState[index][type] = value;
      setInsuranceState([...insuranceState]);
    }
  };

  const handleAddCPT = (index) => {
    insuranceState[index]["dataCPT"].push({
      cpt: "",
      percentage: "",
    });
    setInsuranceState([...insuranceState]);
  };

  const removeInsurance = (index) => {
    insuranceState.splice(index, 1);
    setInsuranceState([...insuranceState]);
  };

  const removeCPT = (index, indexItem) => {
    if (insuranceState[index]["dataCPT"][indexItem]) {
      insuranceState[index]["dataCPT"].splice(indexItem, 1);
      setInsuranceState([...insuranceState]);
    } else {
      insuranceState[index]["dataCPT"] = [];
      setInsuranceState([...insuranceState]);
    }
  };

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}>
      <div className="fullContainer has-no-sidebar">
        <div
          className="mainContainer admin_clinical_logic"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <CardBlock
            title="OBL Optimizer"
            varient={"full"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    insuranceState.push({
                      insurance_name: "",
                      insurance_company_id: "",
                      dataCPT: [
                        {
                          cpt: "",
                          percentage: "",
                        },
                      ],
                    });
                    setInsuranceState([...insuranceState]);
                  }}
                >
                  Add Insurance Groups +
                </button>
              </div>
            }
          >
            <OBLOptimizer
              insuranceState={insuranceState}
              handleAddCPT={handleAddCPT}
              handleUpdate={handleUpdate}
              removeInsurance={removeInsurance}
              removeCPT={removeCPT}
            />
          </CardBlock>
        </div>
      </div>
    </div>
  );
};

export default ProcedureDetail;
