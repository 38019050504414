import { DatePicker, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandSVG, LeftCharvenSVG } from "../../../common/components/icons/SVGIcon";
import {
  checkApointment,
  filterProviders,
  getFollowUp,
  getProcedureEvents,
  getProcedurePending,
  getTimeSlots,
  saveProcedureSchedule,
  setGlobalLoading,
  setProcedureData,
  setStepData,
} from "../../../store/actions";

const { Option } = Select;

const getValue = (options, target, key) => {
  if (key && options) {
    const opt = options.find((option) => option[key] == target);
    if (opt) {
      return opt?.value;
    }
    return "";
  }
  return "";
};

const SecondStepComponent = ({ handleSaveSuccess = () => {} }) => {
  const locations = useSelector((state) => state.procedure.locations);
  const providers = useSelector((state) => state.procedure.providers);
  const clincLocations = useSelector((state) => state.procedure.clincLocations);
  const followupProviders = useSelector((state) => state.procedure.followupProviders);
  const resource = useSelector((state) => state.procedure.resource);
  const loading = useSelector((state) => state.procedure.loadingSchedule);
  const planDetails = useSelector((state) => state.procedure.planDetails);
  const loadingFirst = useSelector((state) => state.procedure.loadingTimeSlots);
  const loadingSecond = useSelector((state) => state.procedure.loadingFollowUp);
  const firstClinic = useSelector((state) => state.procedure.timeSlot);

  const [timeSlots, setTimeSlots] = useState([]);
  const [lock, setLock] = useState(false);
  const [followupSlots, setFollowupSlots] = useState([]);

  const timeSlotsCheck = useSelector((state) => state.procedure.timeSlots);
  const followupSlotsCheck = useSelector((state) => state.procedure.followupSlots);

  const secondClinic = useSelector((state) => state.procedure.followSlot);
  const existingFollowUp = useSelector((state) => state.procedure.existing_followup);

  const filteredProviders = useSelector((state) => state.common.filteredProviders);

  useEffect(() => {
    // dispatch(filterProviders(""));
  }, []);

  const [followupTime, setFollowupTime] = useState("");
  const [fristTimeCheck, setFristTimeCheck] = useState(false);
  const [fristFollowCheck, setFristFollowCheck] = useState(false);
  const [endarterectomy, setEndarterectomy] = useState([]);
  const [follow_Up, setFollow_Up] = useState([]);

  useEffect(() => {
    if (
      secondClinic == -1 &&
      (firstClinic == -1 || resource.appointment_date) &&
      timeSlotsCheck &&
      followupSlotsCheck &&
      timeSlotsCheck.length > 0 &&
      followupSlotsCheck.length > 0
    ) {
      setLock(true);
    } else {
      setLock(false);
    }
  }, [secondClinic, firstClinic, resource, followupTime]);

  const [activeCustom, setActiveCustom] = useState({
    first: false,
    second: false,
  });

  const [customAppointment, setCustomAppointment] = useState({
    first: {},
    second: {},
  });

  const dispatch = useDispatch();

  const [timeOption, setTimeOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });
  const [followOption, setFollowOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });
  const [questions, setQuestions] = useState({
    taking_metformin: -1,
    taking_eliquis: -1,
    taking_coumadin: -1,
    taking_plavix: -1,
    receive_coronary_stent: -1,
  });

  useEffect(() => {
    setFollowupSlots(followupSlotsCheck);
  }, [followupSlotsCheck]);

  useEffect(() => {
    setTimeSlots(timeSlotsCheck);
  }, [timeSlotsCheck]);

  const saveAllDate = (type) => {
    if (type === "time") {
      if (timeSlots && timeSlots[0]) {
        const checkTimeSlots = timeSlots[0];
        if (checkTimeSlots) {
          if (endarterectomy && !endarterectomy.some((r) => r.start === checkTimeSlots.start)) {
            setEndarterectomy([...endarterectomy, checkTimeSlots]);
          }
        }
      }
    }

    if (type === "follow") {
      if (followupSlots && followupSlots[0]) {
        const checkFollowupSlots = followupSlots[0];
        if (checkFollowupSlots) {
          if (follow_Up && !follow_Up.some((r) => r.start === checkFollowupSlots.start)) {
            setFollow_Up([...follow_Up, checkFollowupSlots]);
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingFirst || loadingSecond));
  }, [loading, loadingFirst, loadingSecond]);

  useEffect(() => {
    if (resource && resource.patient) {
      const intialQuestions = {
        taking_metformin: resource.patient.taking_metformin !== null ? resource.patient.taking_metformin : -1,
        taking_eliquis: resource.patient.taking_eliquis !== null ? resource.patient.taking_eliquis : -1,
        taking_coumadin: resource.patient.taking_coumadin !== null ? resource.patient.taking_coumadin : -1,
        taking_plavix: resource.patient.taking_plavix !== null ? resource.patient.taking_plavix : -1,
        receive_coronary_stent: resource.patient.receive_coronary_stent !== null ? resource.patient.receive_coronary_stent : -1,
      };
      setQuestions(intialQuestions);
    }

    if (existingFollowUp) {
      setFollowupTime(existingFollowUp.appointment_date);
    }
  }, [resource]);

  const title = useMemo(() => {
    if (planDetails && planDetails.procedure) {
      return planDetails.procedure;
    }
    return "Procedure";
  }, [planDetails]);

  const setFiirst = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const setSecond = (index) => {
    dispatch(setProcedureData("followSlot", index));
  };

  const setSecondNoSave = (index) => {
    dispatch(setProcedureData("followSlot", index));
  };

  const onSaveSchedule = async (questionsCheck, firstClinicCheck, timeSlotsCheck, secondClinicCheck, followupSlotsCheck) => {
    const date = moment(new Date()).format("MM/DD/YYYY");
    const timeSlot = firstClinicCheck > -1 ? timeSlotsCheck[firstClinicCheck] : timeSlotsCheck && timeSlotsCheck[0] ? timeSlotsCheck[0] : "";
    const followup =
      secondClinicCheck > -1 ? followupSlotsCheck[secondClinicCheck] : followupSlotsCheck && followupSlotsCheck[0] ? followupSlotsCheck[0] : "";
    const params = {
      procedure_date: timeSlot && moment(timeSlot.start).format("MM/DD/YYYY"),
      procedure_time: timeSlot && moment(timeSlot.start).format("hh:mm A"),
      procedure_location: timeSlot && timeSlot.location,
      procedure_provider: timeSlot && timeSlot.provider,
      followup_date: followup && moment(followup.start).format("MM/DD/YYYY"),
      followup_time: followup && moment(followup.start).format("hh:mm A"),
      followup_location: followup && followup.location,
      followup_provider: followup && followup.provider,
      followup_doctor: followup & followup.doctor,
      followup_start_slot: followup & followup.start_slot,
      doc_slot: followup && followup.slot,
      ...questionsCheck,
    };

    if (params.procedure_date && params.followup_date) {
      await dispatch(setGlobalLoading(true));
      await dispatch(setProcedureData("loadingSchedule", true));
      await dispatch(saveProcedureSchedule(resource?.id, params));
      await dispatch(getProcedurePending({}));
      await dispatch(checkApointment(true));
      await dispatch(setStepData("loadingEvents", true));
      await dispatch(getProcedureEvents({ date }));
      await dispatch(setGlobalLoading(false));
      handleSaveSuccess();
    }
  };

  const handleChangeSlots = (type) => (value) => {
    const prev_date =
      type == "location" || type == "provider"
        ? ""
        : firstClinic !== -1
        ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
        : timeSlots[0]
        ? moment(timeSlots[0].start).format("MM/DD/YYYY")
        : "";
    const newOption = { ...timeOption, prev_date, [type]: value };
    setTimeOption(newOption);
    dispatch(setProcedureData("loadingTimeSlots", true));
    dispatch(getTimeSlots(resource.patient_id, resource?.id, newOption));
    setFiirst(-1);
  };

  const handleChangeFollow = (type) => (value) => {
    const prev_date =
      type == "location" || type == "provider"
        ? ""
        : secondClinic !== -1
        ? moment(followupSlots[secondClinic].start).format("MM/DD/YYYY")
        : followupSlots[0]
        ? moment(followupSlots[0].start).format("MM/DD/YYYY")
        : timeSlots[firstClinic]
        ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
        : "";
    const newOption = { ...followOption, prev_date, [type]: value };
    setFollowOption(newOption);
    dispatch(setProcedureData("loadingFollowUp", true));
    dispatch(getFollowUp(resource.patient_id, resource?.id, newOption));
    setSecond(-1);
  };

  const selectTimeSlot = (row, index) => {
    setFollow_Up([]);
    const newOption = {
      location: row?.location,
      provider: row?.provider,
      prev_date: moment(row?.start).format("MM/DD/YYYY"),
      period: moment(row?.start).format("A"),
    };
    setFiirst(index);
    dispatch(setProcedureData("loadingFollowUp", true));
    dispatch(
      getFollowUp(
        resource.patient_id,
        resource?.id,
        {
          ...followOption,
          prev_date: moment(row?.start).format("MM/DD/YYYY"),
        },
        false,
        "true"
      )
    );
  };

  const handleMoreDates = (type) => {
    saveAllDate(type);
    if (timeSlots && timeSlots.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(timeSlots[timeSlots.length - 1].start).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource?.id, option, false, false));
      setFiirst(-1);
    }
    if (followupSlots && followupSlots.length > 0 && type === "follow") {
      const newOption = {
        ...followOption,
        prev_date: moment(followupSlots[followupSlots.length - 1].start).format("MM/DD/YYYY"),
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(getFollowUp(resource.patient_id, resource?.id, newOption, false, false));
      setSecondNoSave(-1);
    }
  };

  const previous = (type) => {
    if (
      endarterectomy.length == 0 &&
      type === "time" &&
      moment(moment(resource.appointment_date).subtract(7, "days").format("MM/DD/YYYY")) > moment() &&
      !fristTimeCheck
    ) {
      const option = {
        ...timeOption,
        prev_date: moment(moment(resource.appointment_date).subtract(7, "days").format("MM/DD/YYYY")).format("MM/DD/YYYY"),
      };
      setFristTimeCheck(true);
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource?.id, option, null));
      setFiirst(-1);
    } else if (
      endarterectomy.length == 0 &&
      type === "time" &&
      moment(moment(resource.appointment_date).subtract(7, "days").format("MM/DD/YYYY")) > moment() &&
      fristTimeCheck &&
      timeSlots &&
      timeSlots[0] &&
      timeSlots[0].start &&
      moment(moment(timeSlots[0].start).subtract(7, "days").format("MM/DD/YYYY")) > moment()
    ) {
      const option = {
        ...timeOption,
        prev_date: moment(moment(timeSlots[0].start).subtract(7, "days").format("MM/DD/YYYY")).format("MM/DD/YYYY"),
      };
      setFristTimeCheck(true);
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource?.id, option, null));
      setFiirst(-1);
    }
    if (endarterectomy.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(endarterectomy[endarterectomy.length - 1].start).format("MM/DD/YYYY"),
      };
      //
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource?.id, option, null));
      setFiirst(-1);
      const aa = endarterectomy;
      if (aa) {
        aa.splice(endarterectomy.length - 1, 1);
        setEndarterectomy(aa);
      }
    }
  };

  const selectPreviousFollowup = () => {
    /*
    If the user has select next before
     */
    if (follow_Up.length > 0) {
      const newOption = {
        ...followOption,
        prev_date: moment(follow_Up[follow_Up.length - 1].start).format("MM/DD/YYYY"),
        is_follow: true,
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(getFollowUp(resource.patient_id, resource?.id, newOption, null));
      setSecondNoSave(-1);
      const aa = follow_Up;
      if (aa) {
        aa.splice(follow_Up.length - 1, 1);
        setFollow_Up(aa);
      }
    }
    if (follow_Up.length == 0 && moment(moment(followupTime).subtract(7, "days").format("MM/DD/YYYY")) > moment() && !fristFollowCheck) {
      const newOption = {
        ...followOption,
        prev_date: moment(moment(followupTime).subtract(7, "days").format("MM/DD/YYYY")).format("MM/DD/YYYY"),
        is_follow: true,
      };
      setFristFollowCheck(true);
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(getFollowUp(resource.patient_id, resource?.id, newOption, null));
    } else if (
      follow_Up.length == 0 &&
      moment(moment(followupTime).subtract(7, "days").format("MM/DD/YYYY")) > moment() &&
      fristFollowCheck &&
      followupSlots &&
      followupSlots[0] &&
      followupSlots[0].start &&
      moment(moment(followupSlots[0].start).subtract(7, "days").format("MM/DD/YYYY")) > moment()
    ) {
      const newOption = {
        ...followOption,
        prev_date: moment(moment(followupSlots[0].start).subtract(7, "days").format("MM/DD/YYYY")).format("MM/DD/YYYY"),
        is_follow: true,
      };
      setFristFollowCheck(true);
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(getFollowUp(resource.patient_id, resource?.id, newOption, null));
    }
  };

  const handleCustomChange = (idx, type, value) => {
    const customAppointments = { ...customAppointment };
    customAppointments[idx][type] = value;
    setCustomAppointment(customAppointments);
  };

  const emptySlots =
    timeSlots && timeSlots.length && timeSlots.length < 3
      ? new Array(3 - timeSlots.length).fill("empty")
      : !timeSlots || !timeSlots.length
      ? new Array(3).fill("empty")
      : [];
  const emptyFollows =
    followupSlots && followupSlots.length && followupSlots.length < 3
      ? new Array(3 - followupSlots.length).fill("empty")
      : !followupSlots || !followupSlots.length
      ? new Array(3).fill("empty")
      : [];
  const checkActive = (slot) => {
    if (moment(slot.start).format("MM/DD/YYYY") === moment(resource.appointment_date).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  };

  const checkFollowActive = (slot) => {
    if (moment(slot.start).format("MM/DD/YYYY") === moment(followupTime).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  };

  const handleTimeSlot = (value, index) => {
    const mixSlot = [...timeSlots];
    mixSlot[index]["start_slot"] = value;
    const dateSlot = moment()
      .hours(8)
      .minutes(0)
      .add(value * 15, "minutes")
      .format("HH:mm:ss");
    if (mixSlot[index]["start"] && mixSlot[index]["start"].includes(" ")) {
      const mixSplit = mixSlot[index]["start"].split(" ");
      if (mixSplit[0]) {
        mixSlot[index]["start"] = `${mixSplit[0]} ${dateSlot}`;
      }
    }
    setTimeSlots(mixSlot);
  };

  const handleTimeSlotFollow = (value, index) => {
    const mixSlot = [...followupSlots];
    const dateSlot = moment()
      .hours(8)
      .minutes(0)
      .add(value * 15, "minutes")
      .format("HH:mm:ss");
    mixSlot[index]["start_slot"] = value;
    if (mixSlot[index]["start"] && mixSlot[index]["start"].includes(" ")) {
      const mixSplit = mixSlot[index]["start"].split(" ");
      if (mixSplit[0]) {
        mixSlot[index]["start"] = `${mixSplit[0]} ${dateSlot}`;
      }
    }
    setFollowupSlots(mixSlot);
  };

  const onHandleClick = () => {
    if (!loading && !loadingSecond) {
      onSaveSchedule(questions, firstClinic, timeSlots, secondClinic, followupSlots);
    }
  };

  return (
    <>
      <div className="resourceContent pr-7px" style={{ position: "relative" }}>
        <div className="resourceInfo d-flex" style={{ justifyContent: "space-between" }}>
          <b>{title}</b>
        </div>

        <div style={{ marginBottom: "4px" }}>
          <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Location"
                value={timeOption.location || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeSlots("location")}
              >
                {(locations || []).map((loc, index) => (
                  <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                    {loc?.value}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              Date
              <div>
                <button
                  className="more-dates backward"
                  style={{
                    marginRight: "0.5rem",
                    padding: "2px",
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                    border: "none",
                    background: "transparent",
                    color: "#3d70be",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    previous("time");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
                <button
                  className="more-dates forward"
                  style={{
                    padding: "2px",
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                    border: "none",
                    background: "transparent",
                    color: "#3d70be",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    handleMoreDates("time");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
              </div>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Time"
                value={timeOption.period || undefined}
                suffixIcon={<span />}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeSlots("period")}
              >
                <Option value="am">AM</Option>
                <Option value="pm">PM</Option>
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Provider"
                value={timeOption.provider || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeSlots("provider")}
              >
                {(providers || []).map((provider, index) => (
                  <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                    {provider?.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {(timeSlots || []).map((slot, index) => (
            <React.Fragment key={`time-slots-${index}`}>
              <div
                className={`resourceInfo between t-row ${
                  checkActive(slot) === "active" && firstClinic !== index && firstClinic == -1 ? "active" : ""
                } ${firstClinic === index ? "active" : ""}`}
                style={{ alignItems: "center" }}
                onClick={() => selectTimeSlot(slot, index)}
              >
                <div className="w-25">{getValue(locations, slot.location, "id")}</div>
                <div className="w-25">{slot.start && moment(slot.start).format("MM/DD/YYYY")}</div>
                <div
                  className="w-25 time-select"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 0,
                    marginTop: 0,
                  }}
                >
                  <Select
                    placeholder="Timer check all"
                    value={
                      checkActive(slot) === "active" && resource.appointment_date
                        ? moment(resource.appointment_date).format("hh:mm A")
                        : slot?.start_slot
                    }
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleTimeSlot(value, index);
                    }}
                  >
                    {(slot?.slots || []).map((time, index) => (
                      <Option key={`time-${time}-${index}`} value={time}>
                        {moment()
                          .hours(8)
                          .minutes(0)
                          .add(time * 15, "minutes")
                          .format("hh:mm A")}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="w-25">{getValue(providers, slot.provider, "id")}</div>
              </div>
              <hr className="small-block" />
            </React.Fragment>
          ))}
          {emptySlots.map((schedule, index) => (
            <React.Fragment key={`schedule-${schedule}-${index}`}>
              <div className={`resourceInfo between t-row`} />
              <hr className="small-block" />
            </React.Fragment>
          ))}
        </div>

        {activeCustom["first"] && (
          <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Location"
                value={customAppointment["first"].location || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={(value) => handleCustomChange("first", "location", value)}
              >
                {(locations || []).map((loc, index) => (
                  <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                    {loc?.value}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                paddingLeft: 0,
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <DatePicker
                onChange={(value) => {
                  handleCustomChange("first", "date", moment(value).format("MM/DD/YYYY"));
                }}
                style={{
                  width: "100%",
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: 0,
                }}
                format={"MM/DD/YYYY"}
                placeholder="Date"
                suffixIcon={<ExpandSVG />}
              />
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <TimePicker
                format={"h:mm A"}
                use12Hours
                placeholder="Time"
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: 0,
                }}
                disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                minuteStep={15}
                onChange={(value) => handleCustomChange("first", "time", moment(value).format("h:mm A"))}
              />
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Provider"
                value={customAppointment["first"].provider || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={(value) => handleCustomChange("first", "provider", value)}
              >
                {(providers || []).map((provider, index) => (
                  <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                    {provider?.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}

        <div className="resourceInfo d-flex" style={{ justifyContent: "space-between", marginTop: "24px" }}>
          <b>{`${title} Follow-Up`}</b>
        </div>

        <div style={{ marginBottom: "4px" }}>
          <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Location"
                value={followOption.location || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeFollow("location")}
              >
                {(clincLocations || []).map((loc, index) => (
                  <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                    {loc?.value}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              Date
              <div>
                <button
                  className="more-dates backward"
                  style={{
                    marginRight: "0.5rem",
                    padding: "2px",
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                    border: "none",
                    background: "transparent",
                    color: "#3d70be",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    selectPreviousFollowup();
                  }}
                >
                  <LeftCharvenSVG />
                </button>
                <button
                  className="more-dates forward"
                  style={{
                    padding: "2px",
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                    border: "none",
                    background: "transparent",
                    color: "#3d70be",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    handleMoreDates("follow");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
              </div>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Time"
                value={followOption.period || undefined}
                suffixIcon={<span />}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeFollow("period")}
              >
                <Option value="am">AM</Option>
                <Option value="pm">PM</Option>
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Provider"
                value={followOption.provider || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeFollow("provider")}
              >
                {(followupProviders || []).map((provider, index) => (
                  <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                    {provider?.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {(followupSlots || []).map((slot, index) => (
            <React.Fragment key={`time-slots-${index}`}>
              <div
                className={`resourceInfo between t-row ${
                  checkFollowActive(slot) === "active" && secondClinic !== index && secondClinic == -1 ? "active" : ""
                } ${secondClinic === index ? "active" : ""}`}
                style={{ alignItems: "center" }}
                onClick={() => setSecond(index)}
              >
                <div className="w-25">{getValue(clincLocations, slot.location, "id")}</div>
                <div className="w-25">{slot.start && moment(slot.start).format("MM/DD/YYYY")}</div>
                <div
                  className="w-25 time-select"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 0,
                    marginTop: 0,
                  }}
                >
                  <Select
                    placeholder="Timer check all"
                    value={checkFollowActive(slot) === "active" && followupTime ? moment(followupTime).format("hh:mm A") : slot?.start_slot}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleTimeSlotFollow(value, index);
                    }}
                  >
                    {(slot?.slots || []).map((time, index) => (
                      <Option key={`time-${time}-${index}`} value={time}>
                        {moment()
                          .hours(8)
                          .minutes(0)
                          .add(time * 15, "minutes")
                          .format("hh:mm A")}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="w-25">{getValue(filteredProviders || providers, slot.doctor, "id")}</div>
              </div>
              <hr className="small-block" />
            </React.Fragment>
          ))}
          {emptyFollows.map((schedule, index) => (
            <React.Fragment key={`following-schedule-${schedule}-${index}`}>
              <div className={`resourceInfo between t-row`} />
              <hr className="small-block" />
            </React.Fragment>
          ))}
        </div>

        {activeCustom["second"] && (
          <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Location"
                value={customAppointment["second"].location || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={(value) => handleCustomChange("second", "location", value)}
              >
                {(clincLocations || []).map((loc, index) => (
                  <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                    {loc?.value}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                paddingLeft: 0,
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <DatePicker
                onChange={(value) => {
                  handleCustomChange("second", "date", moment(value).format("MM/DD/YYYY"));
                }}
                style={{
                  width: "100%",
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: 0,
                }}
                format={"MM/DD/YYYY"}
                placeholder="Date"
                suffixIcon={<ExpandSVG />}
              />
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <TimePicker
                format={"h:mm A"}
                use12Hours
                placeholder="Time"
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: 0,
                }}
                disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                minuteStep={15}
                onChange={(value) => handleCustomChange("second", "time", moment(value).format("h:mm A"))}
              />
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Provider"
                value={customAppointment["second"].provider || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={(value) => handleCustomChange("second", "provider", value)}
              >
                {(followupProviders || []).map((provider, index) => (
                  <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                    {provider?.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}
        {!lock ? (
          <div className="actionContentNew" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
            <button
              className={`bottom-btn`}
              onClick={() => {
                onHandleClick();
              }}
            >
              Save
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SecondStepComponent;
