import { DatePicker, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandSVG, SearchSVG } from "../../../common/components/icons/SVGIcon";
import { getReferralConditions } from "../../../store/actions";

const { Search } = Input;
const { Option } = Select;

const PriorFilters = ({ filter, handleSearch, handleChangeFilter }) => {
  const types = useSelector((state) => state.prior.types);
  const providers = useSelector((state) => state.common.providers);
  const diseases = useSelector((state) => state.common.referralConditions);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getReferralConditions());
  }, []);

  const onChangeDate = (type) => (date, dateString) => {
    const newFilter = {
      ...filter,
      [type]: dateString,
    };
    handleChangeFilter(newFilter);
  };

  const changeSearch = (value) => {
    handleSearch(value);
  };

  const changeCode = (type) => (value) => {
    const newFilter = {
      ...filter,
      [type]: value,
    };
    handleChangeFilter(newFilter);
  };

  return (
    <div className="filterContainer" style={{ flexWrap: "wrap" }}>
      <div className="searchFieldContainer" style={{ width: "20%" }}>
        <div className="searchField">
          <Search placeholder="Search Patient" onChange={(e) => changeSearch(e.target?.value)} style={{ width: "100%" }} suffix={<SearchSVG />} />
        </div>
      </div>

      <div className="statusFilterContainer" style={{ flexWrap: "wrap", width: "80%" }}>
        <div className="dateFilter" style={{ flex: 1, minWidth: "90px" }}>
          <DatePicker
            format={"MM/DD/YYYY"}
            placeholder="DOB..."
            suffixIcon={<ExpandSVG />}
            onChange={onChangeDate("date_of_birth")}
            style={{ width: "100%" }}
          />
        </div>
        <div className="dateFilter" style={{ flex: 1, minWidth: "90px" }}>
          <DatePicker
            format={"MM/DD/YYYY"}
            placeholder="DOS..."
            suffixIcon={<ExpandSVG />}
            onChange={onChangeDate("date_of_procedure")}
            style={{ width: "100%" }}
          />
        </div>
        <div className="resultFilter" style={{ flex: 1, minWidth: "90px" }}>
          <Select placeholder="Provider..." suffixIcon={<ExpandSVG />} allowClear={true} onChange={changeCode("provider")} style={{ width: "100%" }}>
            {(providers || []).map((provider, index) => (
              <Option key={`auth-code-${provider?.id}-${index}`} value={provider?.id}>
                {provider?.value}
              </Option>
            ))}
          </Select>
        </div>
        <div className="resultFilter" style={{ flex: 1, minWidth: "90px" }}>
          <Select
            placeholder="Insurance Verified..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeCode("insurance_carrier")}
            style={{ width: "100%" }}
          ></Select>
        </div>
        <div className="resultFilter" style={{ flex: 1, minWidth: "90px" }}>
          <Select placeholder="ICD..." suffixIcon={<ExpandSVG />} allowClear={true} onChange={changeCode("icd_code")} style={{ width: "100%" }}>
            {(diseases || []).map((icd, index) => (
              <Option key={`auth-code-${icd?.id}-${index}`} value={icd?.id}>
                {icd?.value}
              </Option>
            ))}
          </Select>
        </div>
        <div className="resultFilter" style={{ flex: 1, minWidth: "90px" }}>
          <Select
            placeholder="CPT..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeCode("cpt_code")}
            style={{ width: "100%" }}
          ></Select>
        </div>
      </div>
    </div>
  );
};

export default PriorFilters;
