import { Checkbox, Collapse, Icon, Input, Select } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { SearchSVG } from "../../../../common/components/icons/SVGIcon";
import { SelectOptions, SelectOptionsExtra } from "../../../../data/hpi";
import { getLocal } from "../../../../helpers/Local";
import {
  HPIGetConditions,
  HPISetConditions,
  copy_node_hpi,
  deleteNoteHpi,
  setGlobalLoading,
  setIsSaveHPI,
  update_copy_hpi,
} from "../../../../store/actions";
import { clinic_copy_last } from "src/services/api.services";

let timer = null;
const HPIConditionList = ({
  rows,
  options,
  onChangeData,
  onClickConditionPanel,
  updateNewData,
  onChangeDataNotAPI = () => {},
  setIsSaveActiveNextTab = () => {}, // check if change any value will return true
}) => {
  const [data, setData] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [searchInput, setSearch] = useState("");
  const [indexesLeftCarotidA, setIndexesLeftCarotidA] = useState(false);
  const [indexesRightCarotidA, setIndexesRightCarotidA] = useState(false);
  const [checkedCopy, setCheckedCopy] = useState(false);
  const [indexesLeftCarotidS, setIndexesLeftCarotidS] = useState(false);
  const [indexesRightCarotidS, setIndexesRightCarotidS] = useState(false);
  const [stateShowRed, setStateShowRed] = useState({});
  const [first, setFirst] = useState(false);
  const [checkHover, setCheckHover] = useState({});
  const [checkHover2, setCheckHover2] = useState({});
  const [firstCheck, setFirstCheck] = useState(false);
  const leftRight = useSelector((state) => state.clinicProviderHPI.leftRight);
  const errorHPI = useSelector((state) => state.common.errorHPI);
  const has_aaa_condition = useSelector((state) => state.clinicProviderHPI.has_aaa_condition);
  const dispatch = useDispatch();
  const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);
  const can_copy_hpi_from_previous_visit = useSelector((state) => state.clinicProviderHPI.can_copy_hpi_from_previous_visit);
  const copy_hpi_from_previous_appointment = useSelector((state) => state.clinicProviderHPI.copy_hpi_from_previous_appointment);
  const globalLoading = useSelector((state) => state.common.globalLoading);
  const [masterAaa, setMasterAaa] = useState(false);

  const { resource } = useSelector((state) => ({
    resource: state?.patientSnapshot?.resource,
  }));

  useEffect(() => {
    setCheckedCopy(copy_hpi_from_previous_appointment);
  }, [copy_hpi_from_previous_appointment]);

  useEffect(() => {
    let filteredOptions_ = [];
    // map data when delete all and generate form api coppy
    let rowIndex = 0;
    if (rows && rows.length > 0) {
      if (!firstCheck) {
        for (let row of rows) {
          let questIndex = 0;
          for (let question of row?.questions) {
            onNewState(question, rowIndex, questIndex, rows);
            questIndex++;
          }
          setFirstCheck(true);
          rowIndex++;
        }
      }
    }
    //
    for (let option of options) {
      let isExist = false;
      for (let row of rows) {
        if (option["condition"] === row["condition"]) isExist = true;
      }
      if (!isExist) filteredOptions_.push(option);
    }
    setFilteredOptions(filteredOptions_);
  }, [rows, options]);

  useEffect(() => {
    // if (!data || data.length == 0) {
    //   setData(rows);
    // }
    setData(rows);
  }, [rows]);

  useEffect(() => {
    if (has_aaa_condition && has_aaa_condition === 1) setMasterAaa(true);
  }, [has_aaa_condition]);

  useEffect(() => {
    // check carotid
    if (leftRight && data?.length > 0) {
      // check left
      if (leftRight?.left_stenosis == 50) {
        let isShowCheck = false;
        const checkDataCarotid = (data || []).find((r) => r?.condition === "Carotid Disease");
        if (checkDataCarotid?.questions && checkDataCarotid?.questions.length > 0) {
          if (checkDataCarotid.questions[0] && checkDataCarotid.questions[0]["checked_choice"] == "Yes") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[1] && checkDataCarotid.questions[1]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[2] && checkDataCarotid.questions[2]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[3] && checkDataCarotid.questions[3]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
        }
        if (isShowCheck) {
          setIndexesLeftCarotidS(true);
        } else {
          setIndexesLeftCarotidS(false);
        }
      } else if (leftRight?.left_stenosis == 70) {
        let isShowCheck = false;
        const checkDataCarotid = (data || []).find((r) => r?.condition === "Carotid Disease");
        if (checkDataCarotid?.questions && checkDataCarotid?.questions.length > 0) {
          if (checkDataCarotid.questions[0] && checkDataCarotid.questions[0]["checked_choice"] == "Yes") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[1] && checkDataCarotid.questions[1]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[2] && checkDataCarotid.questions[2]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[3] && checkDataCarotid.questions[3]["checked_choice"] == "Left") {
            isShowCheck = true;
          }
        }
        if (isShowCheck) {
          setIndexesLeftCarotidS(true);
          setIndexesLeftCarotidA(false);
        } else {
          setIndexesLeftCarotidS(false);
          setIndexesLeftCarotidA(true);
        }
      }
      // check right
      if (leftRight?.right_stenosis == 50) {
        let isShowCheck = false;
        const checkDataCarotid = (data || []).find((r) => r?.condition === "Carotid Disease");
        if (checkDataCarotid?.questions && checkDataCarotid.questions.length > 0) {
          if (checkDataCarotid.questions[0] && checkDataCarotid.questions[0]["checked_choice"] == "Yes") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[1] && checkDataCarotid.questions[1]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[2] && checkDataCarotid.questions[2]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[3] && checkDataCarotid.questions[3]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
        }
        if (isShowCheck) {
          setIndexesRightCarotidS(true);
        } else {
          setIndexesRightCarotidS(false);
        }
      } else if (leftRight?.right_stenosis == 70) {
        let isShowCheck = false;
        const checkDataCarotid = (data || []).find((r) => r?.condition === "Carotid Disease");
        if (checkDataCarotid?.questions && checkDataCarotid.questions.length > 0) {
          if (checkDataCarotid.questions[0] && checkDataCarotid.questions[0]["checked_choice"] == "Yes") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[1] && checkDataCarotid.questions[1]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[2] && checkDataCarotid.questions[2]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
          if (checkDataCarotid.questions[3] && checkDataCarotid.questions[3]["checked_choice"] == "Right") {
            isShowCheck = true;
          }
        }
        if (isShowCheck) {
          setIndexesRightCarotidS(true);
          setIndexesRightCarotidA(false);
        } else {
          setIndexesRightCarotidS(false);
          setIndexesRightCarotidA(true);
        }
      }
    }
  }, [leftRight, data]);

  const makeDataChoice = (value, index) => {
    let checked_choiceMix = [];
    if (value && index > -1) {
      const checkedChoiceData = value["questions"][index]["checked_choice"] || "";
      if (!Array.isArray(checkedChoiceData)) {
        checked_choiceMix = checkedChoiceData.split(",");
      } else {
        checked_choiceMix = checkedChoiceData;
      }
      const variance = value?.["questions"]?.[index]?.["variance"];
      if (variance) checked_choiceMix.push(variance);
    }
    return checked_choiceMix;
  };

  useEffect(() => {
    var stateRed = {};
    const objectRow = data.find((r) => r?.condition == "Intermittent Claudication");
    const objectRow2 = data.find((r) => r?.condition == "Peripheral Artery Disease (PAD)");
    const objectRow3 = data.find((r) => r?.condition == "Rest Pain (CLI)" || r?.condition == "Rest Pain");
    const objectRow4 = data.find((r) => r?.condition == "Acute Limb Ischemia (ALI)" || r?.condition == "Acute Limb Ischemia");
    const objectRow5 = data.find((r) => r?.condition == "Wound / Gangrene (CLI)" || r?.condition == "Wound / Gangrene");
    const objectRow6 = data.find((r) => r?.condition == "In-Stent or Restenosis");
    // DK 1 DK2 Peripheral Artery Disease (PAD) Intermittent Claudication
    if (objectRow !== null && typeof objectRow === "object" && objectRow2 !== null && typeof objectRow2 === "object") {
      const rowStruct = objectRow["questions"] || [];
      const rowStruct2 = objectRow2["questions"] || [];
      // index Intermittent Claudication
      const indexRow1 = rowStruct.findIndex(
        (r) =>
          r?.question ==
          "Does the patient have fatigue, discomfort, cramping, or pain of vascular origin in the muscles of the lower extremities that is consistently induced by exercise and consistently relieved by rest (within 10 min)?"
      );
      const indexRow2 = rowStruct.findIndex((r) => r?.question == "The patients RIGHT Lower Extremity claudication symptoms are described?");
      const indexRow4 = rowStruct.findIndex((r) => r?.question == "The patients LEFT Lower Extremity claudication symptoms are described?");
      const indexRow3 = rowStruct.findIndex(
        (r) =>
          r?.question ==
          "Does the patients symptoms affect their ability to perform an occupation, impairs basic activities of daily living, mobility, provide care to a spouse or family member,  loss of the ability to engage in recreational activities, or other QoL issues?"
      );
      const DataChoice1 = makeDataChoice(objectRow, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow, indexRow3);
      const DataChoice4 = makeDataChoice(objectRow, indexRow4);
      // index Peripheral Artery Disease (PAD)
      const indexRowPAD1 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on antiplatelet therapy for at least 12 weeks?");
      const indexRowPAD2 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on statin therapy for at least 12 weeks?");
      const indexRowPAD3 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on a smoking cessation plan for at least 12 weeks?");
      const indexRowPAD4 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on an exercise plan for at least 12 weeks?");
      const indexRowPAD5 = rowStruct2.findIndex((r) => r?.question == "Has the patient been on Cilostazol (Pletal) therapy for at least 12 weeks?");
      const DataChoicePAD1 = makeDataChoice(objectRow2, indexRowPAD1);
      const DataChoicePAD2 = makeDataChoice(objectRow2, indexRowPAD2);
      const DataChoicePAD3 = makeDataChoice(objectRow2, indexRowPAD3);
      const DataChoicePAD4 = makeDataChoice(objectRow2, indexRowPAD4);
      const DataChoicePAD5 = makeDataChoice(objectRow2, indexRowPAD5);
      // Right
      if (
        DataChoice1.indexOf("Right") > -1 &&
        DataChoice3.indexOf("Yes") > -1 &&
        (DataChoice2.indexOf("Moderate") > -1 || DataChoice2.indexOf("Severe") > -1)
      ) {
        if (
          (DataChoicePAD1.indexOf("Plavix") > -1 ||
            DataChoicePAD1.indexOf("Aspirin") > -1 ||
            DataChoicePAD1.indexOf("contraindication") > -1 ||
            DataChoicePAD1.indexOf("side_Effects") > -1 ||
            DataChoicePAD1.indexOf("cost") > -1 ||
            DataChoicePAD1.indexOf("refused") > -1) &&
          (DataChoicePAD2.indexOf("Yes") > -1 ||
            DataChoicePAD2.indexOf("Variance") > -1 ||
            DataChoicePAD2.indexOf("contraindication") > -1 ||
            DataChoicePAD2.indexOf("side_Effects") > -1 ||
            DataChoicePAD2.indexOf("cost") > -1 ||
            DataChoicePAD2.indexOf("refused") > -1) &&
          (DataChoicePAD3.indexOf("Varenicline") > -1 ||
            DataChoicePAD3.indexOf("Bupropion") > -1 ||
            DataChoicePAD3.indexOf("Nicotine Replacement") > -1 ||
            DataChoicePAD3.indexOf("Replacement") > -1 ||
            DataChoicePAD3.indexOf("Non-Smoker") > -1 ||
            DataChoicePAD3.indexOf("Cut Down") > -1) &&
          (DataChoicePAD4.indexOf("Supervised") > -1 ||
            DataChoicePAD4.indexOf("Structured Home") > -1 ||
            DataChoicePAD4.indexOf("Self-Directed") > -1 ||
            DataChoicePAD4.indexOf("Variance Health") > -1 ||
            DataChoicePAD4.indexOf("Variance Resources") > -1 ||
            DataChoicePAD4.indexOf("Variance Pain") > -1) &&
          (DataChoicePAD5.indexOf("Yes") > -1 ||
            DataChoicePAD5.indexOf("Variance") > -1 ||
            DataChoicePAD5.indexOf("contraindication") > -1 ||
            DataChoicePAD5.indexOf("side_Effects") > -1 ||
            DataChoicePAD5.indexOf("cost") > -1 ||
            DataChoicePAD5.indexOf("refused") > -1)
        ) {
          stateRed = {
            ...stateRed,
            "Intermittent Claudication": [
              ...(stateRed["Intermittent Claudication"] || []),
              `Medical necessity criteria met for Lower Extremity Angiography (Right)`,
            ],
          };
        }
      }
      // Left
      if (
        DataChoice1.indexOf("Left") > -1 &&
        DataChoice3.indexOf("Yes") > -1 &&
        (DataChoice4.indexOf("Moderate") > -1 || DataChoice4.indexOf("Severe") > -1)
      ) {
        if (
          (DataChoicePAD1.indexOf("Plavix") > -1 ||
            DataChoicePAD1.indexOf("Aspirin") > -1 ||
            DataChoicePAD1.indexOf("Variance") > -1 ||
            DataChoicePAD1.indexOf("contraindication") > -1 ||
            DataChoicePAD1.indexOf("side_Effects") > -1 ||
            DataChoicePAD1.indexOf("cost") > -1 ||
            DataChoicePAD1.indexOf("refused") > -1) &&
          (DataChoicePAD2.indexOf("Yes") > -1 ||
            DataChoicePAD2.indexOf("Variance") > -1 ||
            DataChoicePAD2.indexOf("contraindication") > -1 ||
            DataChoicePAD2.indexOf("side_Effects") > -1 ||
            DataChoicePAD2.indexOf("cost") > -1 ||
            DataChoicePAD2.indexOf("refused") > -1) &&
          (DataChoicePAD3.indexOf("Varenicline") > -1 ||
            DataChoicePAD3.indexOf("Bupropion") > -1 ||
            DataChoicePAD3.indexOf("Nicotine Replacement") > -1 ||
            DataChoicePAD3.indexOf("Replacement") > -1 ||
            DataChoicePAD3.indexOf("Non-Smoker") > -1 ||
            DataChoicePAD3.indexOf("Cut Down") > -1) &&
          (DataChoicePAD4.indexOf("Supervised") > -1 ||
            DataChoicePAD4.indexOf("Structured Home") > -1 ||
            DataChoicePAD4.indexOf("Self-Directed") > -1 ||
            DataChoicePAD4.indexOf("Variance Health") > -1 ||
            DataChoicePAD4.indexOf("Variance Resources") > -1 ||
            DataChoicePAD4.indexOf("Variance Pain") > -1) &&
          (DataChoicePAD5.indexOf("Yes") > -1 ||
            DataChoicePAD5.indexOf("Variance") > -1 ||
            DataChoicePAD5.indexOf("contraindication") > -1 ||
            DataChoicePAD5.indexOf("side_Effects") > -1 ||
            DataChoicePAD5.indexOf("cost") > -1 ||
            DataChoicePAD5.indexOf("refused") > -1)
        ) {
          stateRed = {
            ...stateRed,
            "Intermittent Claudication": [
              ...(stateRed["Intermittent Claudication"] || []),
              `Medical necessity criteria met for Lower Extremity Angiography (Left)`,
            ],
          };
        }
      }
    }
    // DK 3 Rest Pain
    if (objectRow3 !== null && typeof objectRow3 === "object") {
      const rowStruct = objectRow3["questions"] || [];
      const indexRow1 = rowStruct.findIndex((r) => r?.question == "Does the patient have ischemic rest pain (≥ 2 weeks)?");
      const indexRow2 = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have impaired perfusion of the RIGHT lower extremity (e.g. Abnormal ABI or TBI)?"
      );
      const indexRow3 = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have impaired perfusion of the LEFT lower extremity (e.g. Abnormal ABI or TBI)?"
      );
      const DataChoice1 = makeDataChoice(objectRow3, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow3, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow3, indexRow3);
      // Right
      if (DataChoice1.indexOf("Right") > -1 && DataChoice2.indexOf("Yes") > -1) {
        stateRed = {
          ...stateRed,
          "Rest Pain (CLI)": [...(stateRed["Rest Pain (CLI)"] || []), `Medical necessity criteria met for Lower Extremity Angiography (Right)`],
        };
      }
      // Left
      if (DataChoice1.indexOf("Left") > -1 && DataChoice3.indexOf("Yes") > -1) {
        stateRed = {
          ...stateRed,
          "Rest Pain (CLI)": [...(stateRed["Rest Pain (CLI)"] || []), `Medical necessity criteria met for Lower Extremity Angiography (Left)`],
        };
      }
    }
    // DK 4 Acute Limb Ischemia
    if (objectRow4 !== null && typeof objectRow4 === "object") {
      const rowStruct = objectRow4["questions"] || [];
      const indexRow1 = rowStruct.findIndex(
        (r) =>
          r?.question ==
          "Does the patient have ALI as defined as acute (< 2 weeks), severe hypoperfusion of the limb characterized by: pain, pallor, pulselessness, poikilothermia (cold), paresthesias, and paralysis?"
      );
      const indexRow2 = rowStruct.findIndex((r) => r?.question == "The patients RIGHT Lower Extremity ALI  symptoms are categorized?");
      const indexRow3 = rowStruct.findIndex((r) => r?.question == "The patients LEFT Lower Extremity ALI  symptoms are categorized?");
      const DataChoice1 = makeDataChoice(objectRow4, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow4, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow4, indexRow3);
      // Right
      if (DataChoice1.indexOf("Right") > -1 && (DataChoice2.indexOf("Viable") > -1 || DataChoice2.indexOf("Threatened") > -1)) {
        stateRed = {
          ...stateRed,
          "Acute Limb Ischemia (ALI)": [
            ...(stateRed["Acute Limb Ischemia (ALI)"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Right)`,
          ],
        };
      }
      //Left
      if (DataChoice1.indexOf("Left") > -1 && (DataChoice3.indexOf("Viable") > -1 || DataChoice3.indexOf("Threatened") > -1)) {
        stateRed = {
          ...stateRed,
          "Acute Limb Ischemia (ALI)": [
            ...(stateRed["Acute Limb Ischemia (ALI)"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Left)`,
          ],
        };
      }
    }
    // DK 5 Wound / Gangrene (CLI)
    if (objectRow5 !== null && typeof objectRow5 === "object") {
      const rowStruct = objectRow5["questions"] || [];
      const indexRow1 = rowStruct.findIndex(
        (r) => r?.question == "The patients RIGHT Lower Extremity wound  has been present ≥ 2 weeks despite local wound care and is located?"
      );
      const indexRow2 = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have impaired perfusion of the RIGHT lower extremity (e.g. Abnormal ABI or TBI)?"
      );
      const indexRow3 = rowStruct.findIndex(
        (r) => r?.question == "The patients LEFT Lower Extremity wound  has been present ≥ 2 weeks despite local wound care and is located?"
      );
      const indexRow4 = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have impaired perfusion of the LEFT lower extremity (e.g. Abnormal ABI or TBI)?"
      );
      const DataChoice1 = makeDataChoice(objectRow5, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow5, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow5, indexRow3);
      const DataChoice4 = makeDataChoice(objectRow5, indexRow4);
      // Right
      if (
        (DataChoice1.indexOf("Leg") > -1 ||
          DataChoice1.indexOf("Foot") > -1 ||
          DataChoice1.indexOf("Digit") > -1 ||
          DataChoice1.indexOf("Stump") > -1) &&
        DataChoice2.indexOf("Yes") > -1
      ) {
        stateRed = {
          ...stateRed,
          "Wound / Gangrene (CLI)": [
            ...(stateRed["Wound / Gangrene (CLI)"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Right)`,
          ],
        };
      }
      //Left
      if (
        (DataChoice3.indexOf("Leg") > -1 ||
          DataChoice3.indexOf("Foot") > -1 ||
          DataChoice3.indexOf("Digit") > -1 ||
          DataChoice3.indexOf("Stump") > -1) &&
        DataChoice4.indexOf("Yes") > -1
      ) {
        stateRed = {
          ...stateRed,
          "Wound / Gangrene (CLI)": [
            ...(stateRed["Wound / Gangrene (CLI)"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Left)`,
          ],
        };
      }
    }
    // DK 6 In-Stent or Restenosis
    if (objectRow6 !== null && typeof objectRow6 === "object") {
      const rowStruct = objectRow6["questions"] || [];
      const indexRow1 = rowStruct.findIndex((r) => r?.question == "Has the patient had previous endovascular procedure(s)?");
      const indexRow2 = rowStruct.findIndex((r) => r?.question == "Has the patient had any previous open surgical bypass procedure(s)?");
      const indexRow3 = rowStruct.findIndex(
        (r) =>
          r?.question == "Does the risk of non-treatment pose potential harm in the setting of duplex US evidence of hemodynamic significant lesions?"
      );
      const DataChoice1 = makeDataChoice(objectRow6, indexRow1);
      const DataChoice2 = makeDataChoice(objectRow6, indexRow2);
      const DataChoice3 = makeDataChoice(objectRow6, indexRow3);
      // Right
      if ((DataChoice1.indexOf("Right") > -1 || DataChoice2.indexOf("Right") > -1) && DataChoice3.indexOf("Right") > -1) {
        stateRed = {
          ...stateRed,
          "In-Stent or Restenosis": [
            ...(stateRed["In-Stent or Restenosis"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Right)`,
          ],
        };
      }
      //Left
      if ((DataChoice1.indexOf("Left") > -1 || DataChoice2.indexOf("Left") > -1) && DataChoice3.indexOf("Left") > -1) {
        stateRed = {
          ...stateRed,
          "In-Stent or Restenosis": [
            ...(stateRed["In-Stent or Restenosis"] || []),
            `Medical necessity criteria met for Lower Extremity Angiography (Left)`,
          ],
        };
      }
    }
    setStateShowRed(stateRed);
  }, [data]);

  const checkIndexOf = (value, valueCheck) => {
    if (value && valueCheck) {
      return value.toLowerCase().indexOf(valueCheck.toLowerCase()) !== -1;
    }
    return false;
  };

  useEffect(() => {
    const objectRow = rows.find((r) => r?.condition == "Peripheral Artery Disease (PAD)");
    if (objectRow !== null && typeof objectRow === "object" && objectRow) {
      const rowStruct = objectRow["questions"] || [];
      let stateCheck = {};
      // index plavix Aspirin
      const indexRowPA = rowStruct.findIndex((r) => r?.question == "Has the patient been on antiplatelet therapy for at least 12 weeks?");
      const indexRowAce = rowStruct.findIndex((r) => r?.question == "Has the patient been on ACE inhibitor or Angiotensin Receptor Blocker?");
      // index stain
      const indexRowS = rowStruct.findIndex((r) => r?.question == "Has the patient been on statin therapy for at least 12 weeks?");
      // index Benign Hypertension && Essential Hypertension // icd
      const indexRowEHBH = rowStruct.findIndex(
        (r) => r?.question == "Does the patient have a diagnosis of hypertension which is being managed by PCP?"
      );
      // index Diabetes // icd
      const indexRowD = rowStruct.findIndex((r) => r?.question == "Does the patient have a diagnosis of diabetes which is being managed by PCP?");
      // index chantix && Wellbutrin
      const indexRowCW = rowStruct.findIndex((r) => r?.question == "Has the patient been on a smoking cessation plan for at least 12 weeks?");
      // index Cilostazol
      const indexRowC = rowStruct.findIndex((r) => r?.question == "Has the patient been on Cilostazol (Pletal) therapy for at least 12 weeks?");
      // medications
      if (resource?.medications && Array.isArray(resource?.medications)) {
        // plavix Aspirin
        if (
          resource?.medications.some(
            (r) => r?.full_medication && (checkIndexOf(r?.full_medication, "Plavix") || checkIndexOf(r?.full_medication, "Clopidogrel"))
          )
        ) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowPA + 1}`]: [...(stateCheck[`quest_${indexRowPA + 1}`] || []), "Plavix"],
          };
        }
        if (resource?.medications.some((r) => r?.full_medication && checkIndexOf(r?.full_medication, "Aspirin"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowPA + 1}`]: [...(stateCheck[`quest_${indexRowPA + 1}`] || []), "Aspirin"],
          };
        }
        // statin
        if (
          resource?.medications.some(
            (r) =>
              r?.full_medication &&
              (checkIndexOf(r?.full_medication, "statin") ||
                checkIndexOf(r?.full_medication, "lipitor") ||
                checkIndexOf(r?.full_medication, "crestor") ||
                checkIndexOf(r?.full_medication, "rosuvastatin") ||
                checkIndexOf(r?.full_medication, "Altocor") ||
                checkIndexOf(r?.full_medication, "Altoprev") ||
                checkIndexOf(r?.full_medication, "Baycol") ||
                checkIndexOf(r?.full_medication, "cerivastatin") ||
                checkIndexOf(r?.full_medication, "Crestor") ||
                checkIndexOf(r?.full_medication, "Ezallor") ||
                checkIndexOf(r?.full_medication, "FloLipid") ||
                checkIndexOf(r?.full_medication, "fluvastatin") ||
                checkIndexOf(r?.full_medication, "Lescol") ||
                checkIndexOf(r?.full_medication, "Livalo") ||
                checkIndexOf(r?.full_medication, "lovastatin") ||
                checkIndexOf(r?.full_medication, "Mevacor") ||
                checkIndexOf(r?.full_medication, "Nikita") ||
                checkIndexOf(r?.full_medication, "pitavastatin") ||
                checkIndexOf(r?.full_medication, "Pravachol") ||
                checkIndexOf(r?.full_medication, "pravastatin") ||
                checkIndexOf(r?.full_medication, "rosuvastatin") ||
                checkIndexOf(r?.full_medication, "simvastatin") ||
                checkIndexOf(r?.full_medication, "Zocor") ||
                checkIndexOf(r?.full_medication, "Zypitamag"))
          )
        ) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowS + 1}`]: [...(stateCheck[`quest_${indexRowS + 1}`] || []), "Yes"],
          };
        }
        if (
          resource?.medications.some(
            (r) =>
              r?.full_medication &&
              (checkIndexOf(r?.full_medication, "Benazepril") ||
                checkIndexOf(r?.full_medication, "Captopril") ||
                checkIndexOf(r?.full_medication, "Enalapril") ||
                checkIndexOf(r?.full_medication, "Fosinopril") ||
                checkIndexOf(r?.full_medication, "Lisinopril") ||
                checkIndexOf(r?.full_medication, "Moexipril") ||
                checkIndexOf(r?.full_medication, "Perindopril") ||
                checkIndexOf(r?.full_medication, "Quinapril") ||
                checkIndexOf(r?.full_medication, "Ramipril") ||
                checkIndexOf(r?.full_medication, "Trandolapril") ||
                checkIndexOf(r?.full_medication, "Azilsartan") ||
                checkIndexOf(r?.full_medication, "Candesartan") ||
                checkIndexOf(r?.full_medication, "Irbesartan") ||
                checkIndexOf(r?.full_medication, "Losartan") ||
                checkIndexOf(r?.full_medication, "Olmesartan") ||
                checkIndexOf(r?.full_medication, "Telmisartan") ||
                checkIndexOf(r?.full_medication, "Valsartan") ||
                checkIndexOf(r?.full_medication, "Lotensin") ||
                checkIndexOf(r?.full_medication, "Vasotec") ||
                checkIndexOf(r?.full_medication, "Zestril") ||
                checkIndexOf(r?.full_medication, "Edarbi") ||
                checkIndexOf(r?.full_medication, "Atacand") ||
                checkIndexOf(r?.full_medication, "Cozaar") ||
                checkIndexOf(r?.full_medication, "Benicar") ||
                checkIndexOf(r?.full_medication, "Micardis") ||
                checkIndexOf(r?.full_medication, "Diovan"))
          )
        ) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowAce + 1}`]: [...(stateCheck[`quest_${indexRowAce + 1}`] || []), "Yes"],
          };
        }
        // chantix && Wellbutrin
        if (resource?.medications.some((r) => r?.full_medication && checkIndexOf(r?.full_medication, "Chantix"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowCW + 1}`]: [...(stateCheck[`quest_${indexRowCW + 1}`] || []), "Varenicline"],
          };
        }
        // none smoke
        if (resource?.smoking_ppd == 0) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowCW + 1}`]: [...(stateCheck[`quest_${indexRowCW + 1}`] || []), "Non-Smoker"],
          };
        }
        //
        if (resource?.medications.some((r) => r?.full_medication && checkIndexOf(r?.full_medication, "Wellbutrin"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowCW + 1}`]: [...(stateCheck[`quest_${indexRowCW + 1}`] || []), "Bupropion"],
          };
        }
        // Cilostazol
        if (resource?.medications.some((r) => r?.full_medication && checkIndexOf(r?.full_medication, "Cilostazol"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowC + 1}`]: [...(stateCheck[`quest_${indexRowC + 1}`] || []), "Yes"],
          };
        }
      }
      // previous medication history
      if (resource?.icd_codes && Array.isArray(resource?.icd_codes)) {
        // Benign Hypertension && Essential Hypertension
        if (
          resource?.icd_codes.some(
            (r) =>
              r?.disease?.icd_name &&
              ((checkIndexOf(r?.disease?.icd_name, "Benign") && checkIndexOf(r?.disease?.icd_name, "Hypertension")) ||
                (checkIndexOf(r?.disease?.icd_name, "Essential") && checkIndexOf(r?.disease?.icd_name, "Hypertension")))
          )
        ) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowEHBH + 1}`]: [...(stateCheck[`quest_${indexRowEHBH + 1}`] || []), "Yes"],
          };
        } else {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowEHBH + 1}`]: [...(stateCheck[`quest_${indexRowEHBH + 1}`] || []), "No"],
          };
        }
        // Diabetes
        if (resource?.icd_codes.some((r) => r?.disease?.icd_name && checkIndexOf(r?.disease?.icd_name, "Diabetes"))) {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowD + 1}`]: [...(stateCheck[`quest_${indexRowD + 1}`] || []), "Yes"],
          };
        } else {
          stateCheck = {
            ...stateCheck,
            [`quest_${indexRowD + 1}`]: [...(stateCheck[`quest_${indexRowD + 1}`] || []), "No"],
          };
        }
      }

      setCheckHover2((statePrev) => ({
        ...statePrev,
        ...stateCheck,
      }));
    }
  }, [resource, rows]);

  const handleSearch = (search) => {
    setSearch(search);

    let filteredOptions_ = [];
    for (let option of options) {
      let isExist = false;
      for (let row of rows) {
        if (option["condition"] === row["condition"]) isExist = true;
      }
      if (!isExist) {
        if (option["condition"].toLowerCase().includes(search.toLowerCase())) {
          filteredOptions_.push(option);
        }
      }
    }
    setFilteredOptions(filteredOptions_);
  };
  const onSelectOption = (option) => {
    setSelectedOption(option);
    setSearch(option["condition"]);
    setShowOptions(false);
  };
  const onAddCondition = () => {
    if (!selectedOption) return;
    let data_ = [...data];
    if (selectedOption.id === 2) setMasterAaa(true);
    data_.push({ ...selectedOption, history: "" });
    setSearch("");
    setSelectedOption(undefined);
    setData([...data_]);
    onChangeDataNotAPI([...data_]);
  };

  const onRemoveCondition = (conditionIndex) => {
    let data_ = [...data];
    data_.splice(conditionIndex, 1);
    const indexCheck = data_.findIndex((r) => r?.condition === "Peripheral Artery Disease (PAD)");
    if (indexCheck == -1) {
      const fill = data_.filter(
        (r) =>
          r?.condition !== "Acute Limb Ischemia (ALI)" &&
          r?.condition !== "Intermittent Claudication" &&
          r?.condition !== "Rest Pain (CLI)" &&
          r?.condition !== "In-Stent or Restenosis" &&
          r?.condition !== "Wound / Gangrene (CLI)"
      );
      setData([...fill]);
      onChangeDataNotAPI([...fill]);
    } else {
      setData([...data_]);
      onChangeDataNotAPI([...data_]);
    }
  };

  const onChangeCommentsNotLoad = (conditionIndex, questionIndex, comments) => {
    let data_ = [...data];
    data_[conditionIndex]["questions"][questionIndex]["comments"] = comments;
    setData([...data_]);
    dispatch(setIsSaveHPI(true));
    onChangeDataNotAPI([...data_]);
  };

  const onChangeComments = (conditionIndex, questionIndex, comments) => {
    let data_ = [...data];
    data_[conditionIndex]["questions"][questionIndex]["comments"] = comments;
    setData([...data_]);
    dispatch(setIsSaveHPI(true));
    onChangeDataNotAPI([...data_]);
  };

  const replaceTextTemp = (value, checkText) => {
    let checkText_non = "";
    if (checkText.indexOf("\n") > -1) {
      checkText_non = checkText.relace(/\n/g, "");
    }
    if (value.includes(` ${checkText}`) && !value.includes(` ${checkText} `)) {
      return value.replaceAll(` ${checkText}`, "");
    } else if (value.includes(` ${checkText}`) && value.includes(` ${checkText} `)) {
      return value.replaceAll(` ${checkText} `, "");
    } else if (value.includes(`${checkText_non}`)) {
      return value.replaceAll(`${checkText_non}`, "");
    } else {
      return value.replaceAll(`${checkText}`, "");
    }
  };

  const onNewState = (question, conditionIndex, questionIndex, rowsData) => {
    let data_ = [...rowsData];
    const is_multiple = question?.is_multiple == 1;
    const is_list = question?.is_multiple == 1;
    let comments = "";
    if (question?.comments === null && conditionIndex != -1 && questionIndex != -1) {
      if (!is_multiple) {
        comments = question?.comments || "";
        if (comments && comments != " " && comments.length > 0) {
          comments = comments || "";
          (question?.choices || []).map((r) => {
            if (r.option == question?.checked_choice) {
              if (!comments.includes(r.text_template)) {
                comments = comments
                  ? comments +
                    `${
                      r.text_template && r.text_template.slice(r.text_template?.length - 2, r.text_template?.length).includes("\n")
                        ? ""
                        : r.text_template
                        ? " "
                        : ""
                    }` +
                    (r.text_template || "")
                  : r.text_template || "";
              }
            } else if (r.option == "" || question?.checked_choice == "") {
              comments = replaceTextTemp(comments, r.text_template);
            } else {
              comments = replaceTextTemp(comments, r.text_template);
            }
          });
        } else {
          (question?.choices || []).map((r) => {
            if (r.option == question?.checked_choice) {
              comments = replaceTextTemp(comments, r.text_template);
            } else if (r.option == "" || question?.checked_choice == "") {
              comments = "";
            }
          });
        }
      } else {
        let checked_choiceMix = [];
        const checkedChoiceData = question?.checked_choice || "";
        if (!Array.isArray(checkedChoiceData)) {
          checked_choiceMix = checkedChoiceData.split(",");
        } else {
          checked_choiceMix = checkedChoiceData;
        }
        if (
          (checked_choiceMix && checked_choiceMix.length <= 1 && checked_choiceMix[0] == "") ||
          (checked_choiceMix && checked_choiceMix.length == 0)
        ) {
          comments = "";
        } else if (comments && comments != " " && comments.length > 0) {
          (question?.choices || []).map((r) => {
            const value = `${is_list ? `•${r.text_template}\n` : `${r.text_template}`}`;
            const valueCheck = `${is_list ? `•${r.text_template}` : `${r.text_template}`}`;
            if (r.option && checked_choiceMix.indexOf(r.option) > -1) {
              if (comments.indexOf(value) === -1 && comments.indexOf(valueCheck) === -1) {
                comments = comments
                  ? comments +
                    `${
                      value && value.slice(value?.length - 2, value?.length).includes("\n")
                        ? ""
                        : value && !value.slice(value?.length - 2, value?.length).includes("\n") && is_list
                        ? "\n"
                        : " "
                    }` +
                    value
                  : value;
              }
            } else if (r.option == "" || question?.checked_choice == "") {
              comments = replaceTextTemp(comments, value);
            } else {
              comments = replaceTextTemp(comments, value);
            }
          });
        } else {
          (question?.choices || []).map((r) => {
            const value = `${is_list ? `•${r.text_template}\n` : `${r.text_template}`}`;
            if (r.option && checked_choiceMix.indexOf(r.option) > -1) {
              comments += r.text_template
                ? value +
                  `${
                    value && value.slice(value?.length - 2, value?.length).includes("\n")
                      ? ""
                      : value && !value.slice(value?.length - 2, value?.length).includes("\n") && is_list
                      ? "\n"
                      : " "
                  }`
                : " ";
            } else if (r.option == "" || data?.checked_choice == "") {
              comments = "";
            }
          });
        }
      }
      data_[conditionIndex]["questions"][questionIndex]["comments"] = comments;

      clearTimeout(timer);
      timer = setTimeout(async () => {
        setData([...data_]);
        onChangeDataNotAPI([...data_]);
      }, 500);
    }
  };

  const importCondition = (value, dataState, valueRight, valueLeft, isRight, isLeft) => {
    if (!(dataState || []).some((r) => r?.condition === value)) {
      let detailCondition = (options || []).find((row) => row?.condition === value || row?.condition_name === value);
      if (detailCondition && detailCondition?.id) {
        // eslint-disable-next-line default-case
        switch (value) {
          case "Acute Limb Ischemia (ALI)":
            if (isRight) {
              detailCondition["questions"][0]["checked_choice"] = ["Right"];
            }
            if (isLeft) {
              detailCondition["questions"][0]["checked_choice"] = ["Left"];
            }
            if (isRight && isLeft) {
              detailCondition["questions"][0]["checked_choice"] = ["Left", "Right"];
            }
          case "Rest Pain (CLI)":
            if (isRight) {
              detailCondition["questions"][0]["checked_choice"] = ["Right"];
            }
            if (isLeft) {
              detailCondition["questions"][0]["checked_choice"] = ["Left"];
            }
            if (isRight && isLeft) {
              detailCondition["questions"][0]["checked_choice"] = ["Left", "Right"];
            }
            break;
          case "Intermittent Claudication":
            if (isRight) {
              detailCondition["questions"][0]["checked_choice"] = ["Right"];
            }
            if (isLeft) {
              detailCondition["questions"][0]["checked_choice"] = ["Left"];
            }
            if (isRight && isLeft) {
              detailCondition["questions"][0]["checked_choice"] = ["Left", "Right"];
            }
            break;
        }
        // th user chọn left hoặc right (chỗ này nếu data import chưa dc tồn tại thì push mới vào)
        if (isRight && !isLeft) {
          detailCondition["questions"] = detailCondition["questions"].map((r) => {
            if (r.question.includes(" LEFT ")) {
              const hasNoneOption = r.choices.some((choice) => choice.option === "None");
              if (hasNoneOption) {
                return { ...r, checked_choice: "None" };
              }
              const hasNoOption = r.choices.some((choice) => choice.option === "No");
              if (hasNoOption) {
                return { ...r, checked_choice: "No" };
              }
            }
            const hasRightOption = r.choices.some((choice) => choice.option === "Right");
            if (hasRightOption) {
              return { ...r, checked_choice: "Right" };
            }
            return { ...r, checked_choice: "" };
          });
        } else if (!isRight && isLeft) {
          detailCondition["questions"] = detailCondition["questions"].map((r) => {
            if (r.question.includes(" RIGHT ")) {
              const hasNoneOption = r.choices.some((choice) => choice.option === "None");
              if (hasNoneOption) {
                return { ...r, checked_choice: "None" };
              }
              const hasNoOption = r.choices.some((choice) => choice.option === "No");
              if (hasNoOption) {
                return { ...r, checked_choice: "No" };
              }
            }
            const hasLeftOption = r.choices.some((choice) => choice.option === "Left");
            if (hasLeftOption) {
              return { ...r, checked_choice: "Left" };
            }
            return { ...r, checked_choice: "" };
          });
        }
        dataState.push(detailCondition);
        setData([...dataState]);
        onChangeDataNotAPI([...dataState]);
      }
    } else {
      const dataMix = [...dataState];
      const indexData = (dataState || []).findIndex((row) => row?.condition === value || row?.condition_name === value);
      if (indexData != -1) {
        // eslint-disable-next-line default-case
        switch (value) {
          case "Acute Limb Ischemia (ALI)":
            if (isRight && isLeft) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left", "Right"];
            } else if (isRight) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Right"];
            } else if (isLeft) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left"];
            }
          // eslint-disable-next-line no-fallthrough
          case "Rest Pain (CLI)":
            if (isRight && isLeft) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left", "Right"];
            } else if (isRight) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Right"];
            } else if (isLeft) {
              dataMix[indexData]["questions"][0]["checked_choice"] =
                Array.isArray(dataMix[indexData]["questions"][0]["checked_choice"]) &&
                dataMix[indexData]["questions"][0]["checked_choice"].some((r) => r == "None")
                  ? ["None"]
                  : ["Left"];
            }
            break;
          case "Intermittent Claudication":
            if (isRight && isLeft) {
              dataMix[indexData]["questions"][0]["checked_choice"] = ["Left", "Right"];
            } else if (isRight) {
              dataMix[indexData]["questions"][0]["checked_choice"] = ["Right"];
            } else if (isLeft) {
              dataMix[indexData]["questions"][0]["checked_choice"] = ["Left"];
            }
            break;
        }
        // fix nếu như data đó đã tồn tại mà user thay đổi thêm left hoặc right
        const indexFix = dataMix.findIndex((r) => r.condition === value);
        if (indexFix !== -1 && (isRight || isLeft)) {
          if (isRight && !isLeft) {
            dataMix[indexFix]["questions"] = dataMix[indexFix]["questions"].map((r) => {
              if (r.question.includes(" LEFT ")) {
                const hasNoneOption = r.choices.some((choice) => choice.option === "None");
                if (hasNoneOption) {
                  return { ...r, checked_choice: "None" };
                }
                const hasNoOption = r.choices.some((choice) => choice.option === "No");
                if (hasNoOption) {
                  return { ...r, checked_choice: "No" };
                }
              } else {
                return { ...r };
              }
            });
          } else if (!isRight && isLeft) {
            dataMix[indexFix]["questions"] = dataMix[indexFix]["questions"].map((r) => {
              if (r.question.includes(" RIGHT ")) {
                const hasNoneOption = r.choices.some((choice) => choice.option === "None");
                if (hasNoneOption) {
                  return { ...r, checked_choice: "None" };
                }
                const hasNoOption = r.choices.some((choice) => choice.option === "No");
                if (hasNoOption) {
                  return { ...r, checked_choice: "No" };
                }
              } else {
                return { ...r };
              }
            });
          } else if (isRight && isLeft) {
            dataMix[indexFix]["questions"] = dataMix[indexFix]["questions"].map((r) => {
              if (r.question.includes(" RIGHT ") || r.question.includes(" LEFT ")) {
                return { ...r, checked_choice: "" };
              } else {
                return { ...r };
              }
            });
          }
        }
        setData([...dataMix]);
        onChangeDataNotAPI([...dataMix]);
      } else {
        setData([...dataState]);
        onChangeDataNotAPI([...dataState]);
      }
    }
  };

  const removeCondition = (value, dataState) => {
    if (value === "Abdominal Aortic Aneurysm (AAA) Disease" && masterAaa) return false;

    const indexData = dataState.findIndex((r) => r?.condition === value);
    if (indexData !== -1) {
      dataState.splice(indexData, 1);
      setData([...dataState]);
      onChangeDataNotAPI([...dataState]);
    }
  };

  const checkAAA = (dataState) => {
    const mixArr = (dataState || []).find((r) => r?.condition === "Peripheral Artery Disease (PAD)");
    if (mixArr && mixArr["questions"] && mixArr["questions"][9]) {
      if (mixArr["questions"][9] && mixArr["questions"][9]["checked_choice"] && mixArr["questions"][9]["checked_choice"] === "No") {
        importCondition("Abdominal Aortic Aneurysm (AAA) Disease", dataState);
      }
    }
  };

  const checkAddAAA = (dataState) => {
    const mixArr = (dataState || []).find((r) => r?.condition === "Peripheral Artery Disease (PAD)");
    const indexRight = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
    const indexLeft = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
    if (mixArr && indexRight != -1 && indexLeft != -1 && mixArr["questions"] && mixArr["questions"][indexRight] && mixArr["questions"][indexLeft]) {
      if (
        (mixArr["questions"][indexRight] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Wound/Gangrene") > -1) ||
        (mixArr["questions"][indexRight] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Rest Pain") > -1) ||
        (mixArr["questions"][indexRight] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Sudden Pain") > -1) ||
        (mixArr["questions"][indexRight] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Claudication") > -1) ||
        (mixArr["questions"][indexRight] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Restenosis") > -1) ||
        (mixArr["questions"][indexLeft] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Wound/Gangrene") > -1) ||
        (mixArr["questions"][indexLeft] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Rest Pain") > -1) ||
        (mixArr["questions"][indexLeft] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Sudden Pain") > -1) ||
        (mixArr["questions"][indexLeft] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Claudication") > -1) ||
        (mixArr["questions"][indexLeft] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
          mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Restenosis") > -1)
      ) {
        importCondition("Abdominal Aortic Aneurysm (AAA) Disease", dataState);
      }
    }
  };

  const checkRemoveAAA = (dataState) => {
    const mixArr = (dataState || []).find((r) => r?.condition === "Peripheral Artery Disease (PAD)");
    const indexRight = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
    const indexLeft = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
    if (mixArr && indexRight != -1 && indexLeft != -1 && mixArr["questions"] && mixArr["questions"][indexRight] && mixArr["questions"][indexLeft]) {
      if (
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Wound/Gangrene") === -1 &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Rest Pain") === -1 &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Sudden Pain") === -1 &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Claudication") === -1 &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Restenosis") === -1 &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Wound/Gangrene") === -1 &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Rest Pain") === -1 &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Sudden Pain") === -1 &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Claudication") === -1 &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Restenosis") === -1
      ) {
        removeCondition("Abdominal Aortic Aneurysm (AAA) Disease", dataState);
      }
    }
  };

  // logic thêm Does the patient suffer from RIGHT Lower Extremity symptoms? khi chọn left right các thành phần
  const checkConditionLogicExtra = (dataState) => {
    const mixArr = (dataState || []).find((r) => r?.condition === "Peripheral Artery Disease (PAD)");
    // right
    const indexRight = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
    // left
    const indexLeft = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
    //th chọn Wound/Gangrene
    if (
      (mixArr["questions"][indexRight] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Wound/Gangrene") > -1) ||
      (mixArr["questions"][indexLeft] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Wound/Gangrene") > -1)
    ) {
      checkAAA(dataState);
      importCondition(
        "Wound / Gangrene (CLI)",
        dataState,
        null,
        null,
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Wound/Gangrene") > -1,
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Wound/Gangrene") > -1
      );
    } else {
      checkRemoveAAA(dataState);
      removeCondition("Wound / Gangrene (CLI)", dataState);
    }
    //th chọn Sudden Pain
    if (
      (mixArr["questions"][indexRight] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Sudden Pain") > -1) ||
      (mixArr["questions"][indexLeft] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Sudden Pain") > -1)
    ) {
      checkAAA(dataState);
      importCondition(
        "Acute Limb Ischemia (ALI)",
        dataState,
        null,
        null,
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Sudden Pain") > -1,
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Sudden Pain") > -1
      );
    } else {
      checkRemoveAAA(dataState);
      removeCondition("Acute Limb Ischemia (ALI)", dataState);
    }
    //th chọn Restenosis
    if (
      (mixArr["questions"][indexRight] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Restenosis") > -1) ||
      (mixArr["questions"][indexLeft] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Restenosis") > -1)
    ) {
      checkAAA(dataState);
      importCondition(
        "In-Stent or Restenosis",
        dataState,
        null,
        null,
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Restenosis") > -1,
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Restenosis") > -1
      );
    } else {
      checkRemoveAAA(dataState);
      removeCondition("In-Stent or Restenosis", dataState);
    }
    //th chọn Rest Pain
    if (
      (mixArr["questions"][indexRight] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Rest Pain") > -1) ||
      (mixArr["questions"][indexLeft] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Rest Pain") > -1)
    ) {
      checkAAA(dataState);
      importCondition(
        "Rest Pain (CLI)",
        dataState,
        null,
        null,
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Rest Pain") > -1,
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Rest Pain") > -1
      );
    } else {
      checkRemoveAAA(dataState);
      removeCondition("Rest Pain (CLI)", dataState);
    }
    //th chọn Claudication
    if (
      (mixArr["questions"][indexRight] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Claudication") > -1) ||
      (mixArr["questions"][indexLeft] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"] &&
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Claudication") > -1)
    ) {
      checkAAA(dataState);
      importCondition(
        "Intermittent Claudication",
        dataState,
        null,
        null,
        mixArr?.["questions"]?.[indexRight]?.["checked_choice"].indexOf("Claudication") > -1,
        mixArr?.["questions"]?.[indexLeft]?.["checked_choice"].indexOf("Claudication") > -1
      );
    } else {
      checkRemoveAAA(dataState);
      removeCondition("Intermittent Claudication", dataState);
    }
  };

  const onSelectChoice = (conditionIndex, questionIndex, checked_choice, type) => {
    let data_ = [...data];
    dispatch(setIsSaveHPI(true));
    /*
    Type is is_multiple here. If multiple == 1 then type = true
    */
    //if this is a single choice question
    if (!type) {
      if (data_[conditionIndex]["questions"][questionIndex]["checked_choice"] != checked_choice) {
        data_[conditionIndex]["questions"][questionIndex]["checked_choice"] = checked_choice;
        data_[conditionIndex]["questions"][questionIndex]["variance"] = "";
      } else if (data_[conditionIndex]["questions"][questionIndex]["checked_choice"] == checked_choice) {
        data_[conditionIndex]["questions"][questionIndex]["checked_choice"] = "";
      }

      if (data_[conditionIndex]["condition"] == "Peripheral Artery Disease (PAD)") {
        if (
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?"
        ) {
          const mixArr = (data_ || []).find((r) => r?.condition === "Peripheral Artery Disease (PAD)");
          const indexRight = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
          const indexLeft = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
          if (mixArr && mixArr["questions"] && mixArr["questions"][indexRight] && mixArr["questions"][indexLeft]) {
            if (checked_choice == "No") {
              checkAddAAA(data_);
            } else {
              setData([...data_]);
              onChangeDataNotAPI([...data_]);
              removeCondition("Abdominal Aortic Aneurysm (AAA) Disease", data_);
            }
          }
        } else if (
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Does the patient suffer from RIGHT Lower Extremity symptoms?" ||
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Does the patient suffer from LEFT Lower Extremity symptoms?"
        ) {
          checkConditionLogicExtra(data_);
        } else {
          setData([...data_]);
          onChangeDataNotAPI([...data_]);
        }
      } else {
        setData([...data_]);
        onChangeDataNotAPI([...data_]);
      }
    } else {
      data_[conditionIndex]["questions"][questionIndex]["checked_choice"] = checked_choice;
      data_[conditionIndex]["questions"][questionIndex]["variance"] = "";

      if (data_[conditionIndex]["condition"] == "Peripheral Artery Disease (PAD)") {
        if (
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?"
        ) {
          const mixArr = (data_ || []).find((r) => r?.condition === "Peripheral Artery Disease (PAD)");
          // logic khi chọn left right sinh ra các trường phù hợp
          const indexRight = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from RIGHT Lower Extremity symptoms?");
          const indexLeft = mixArr["questions"].findIndex((r) => r?.question == "Does the patient suffer from LEFT Lower Extremity symptoms?");
          if (mixArr && mixArr["questions"] && mixArr["questions"][indexRight] && mixArr["questions"][indexLeft]) {
            if (checked_choice == "No") {
              checkAddAAA(data_);
            } else {
              setData([...data_]);
              onChangeDataNotAPI([...data_]);
              removeCondition("Abdominal Aortic Aneurysm (AAA) Disease", data_);
            }
          }
        } else if (
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Does the patient suffer from RIGHT Lower Extremity symptoms?" ||
          data_[conditionIndex]["questions"][questionIndex]["question"] == "Does the patient suffer from LEFT Lower Extremity symptoms?"
        ) {
          checkConditionLogicExtra(data_);
        } else {
          setData([...data_]);
          onChangeDataNotAPI([...data_]);
        }
      } else {
        setData([...data_]);
        onChangeDataNotAPI([...data_]);
      }
    }
  };

  const saveAll = () => {
    let data_ = [...data];
    setData([...data_]);
    onChangeDataNotAPI([...data_]);
    onChangeData(data_);
    dispatch(setIsSaveHPI(false));
  };

  const PanelToolbar = ({ onRemoveCondition, row }) => {
    if (row?.id < 38 || row?.id > 42) {
      return (
        <div
          className="atuoAccordion__result-item-close_btn"
          onClick={(e) => {
            if (idDelete && idDelete == row?.id) {
              e.stopPropagation();
              onRemoveCondition(row);
              setIdDelete(null);
            } else {
              e.stopPropagation();
              setIdDelete(row?.id);
            }
          }}
        >
          <Icon type="delete" style={{ color: row?.id == idDelete ? "red" : "black" }} />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const handleChangeNotes = (value, row, index) => {
    const dataCheck = [...data];
    if (dataCheck && dataCheck[index]) {
      dataCheck[index]["text"] = value;
      setData([...dataCheck]);
      dispatch(HPISetConditions([...dataCheck]));
    }
  };

  const procedure_id = getLocal("current_appointment_id");

  const coppyHandle = async (item, index, value) => {
    if (!globalLoading) {
      await dispatch(setGlobalLoading(true));
      data[index]["copy_from_previous_condition"] = value.target.checked ? 1 : 0;
      setData([...data]);
      if (value.target.checked === true) {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          await dispatch(
            copy_node_hpi(procedure_id, {
              condition_name: item?.condition,
              hpi_id: item?.hpi_id,
            })
          );
          const data = await dispatch(HPIGetConditions(procedure_id));
          if (data && data?.conditions) {
            updateNewData && updateNewData(data?.conditions);
          }
          await dispatch(setGlobalLoading(false));
        }, 500);
      } else {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          await dispatch(
            deleteNoteHpi(procedure_id, {
              condition_name: item?.condition,
              hpi_id: item?.hpi_id,
            })
          );
          const data = await dispatch(HPIGetConditions(procedure_id));
          if (data && data?.conditions) {
            updateNewData && updateNewData(data?.conditions);
          }
          await dispatch(setGlobalLoading(false));
        }, 500);
      }
    }
  };

  const handleVariAPI = useCallback(
    (value, index, question, indexKey) => {
      const dataCheck = [...data];
      if (dataCheck?.[index]?.["questions"]?.[indexKey] && Object.keys(dataCheck[index]["questions"][indexKey]).length > 0) {
        dataCheck[index]["questions"][indexKey]["checked_choice"] = "";
        dataCheck[index]["questions"][indexKey] = {
          ...dataCheck[index]["questions"][indexKey],
          variance: value,
        };
        setData([...dataCheck]);
        onChangeDataNotAPI([...dataCheck]);
        dispatch(HPISetConditions([...dataCheck]));
      }
    },
    [data]
  );

  const handleCheckedCopy = async (e) => {
    if (!globalLoading) {
      dispatch(setGlobalLoading(true));
      setCheckedCopy(e.target.checked);
      dispatch(update_copy_hpi(e.target.checked));
      // TODO check state when checked coopy and unchecked
      if (e.target.checked) {
        await clinic_copy_last(procedure_id);
        await dispatch(HPIGetConditions(procedure_id));
      } else {
        // reset data when unchecked
        const dataCheck = (data || []).map((r) => {
          return {
            ...r,
            questions: (r?.questions || []).map((quest) => {
              return {
                ...quest,
                checked_choice: "",
              };
            }),
          };
        });
        setData([...dataCheck]);
        onChangeDataNotAPI([...dataCheck]);
      }

      dispatch(setGlobalLoading(false));
    }
  };

  const handleVariExtraApi = useCallback(
    (value, index, question, indexKey) => {
      const dataCheck = [...data];
      if (dataCheck?.[index]?.["questions"]?.[indexKey] && Object.keys(dataCheck[index]["questions"][indexKey]).length > 0) {
        dataCheck[index]["questions"][indexKey]["checked_choice"] = "";
        dataCheck[index]["questions"][indexKey] = {
          ...dataCheck[index]["questions"][indexKey],
          variance_extra: value,
        };
        setData([...dataCheck]);
        onChangeDataNotAPI([...dataCheck]);
        dispatch(HPISetConditions([...dataCheck]));
      }
    },
    [data]
  );

  return (
    <div className="atuoAccordion">
      <div className="atuoAccordion__filter">
        <div className="atuoAccordion__filter-search white">
          <SearchInput disabled={!!is_locked} searchInput={searchInput} setSearch={handleSearch} setShowOptions={setShowOptions} />
        </div>
        <div className={`atuoAccordion__filter-action ${is_locked ? "disabled" : ""}`}>
          <button
            className="atuoAccordion__filter-add"
            style={{
              backgroundColor: is_locked ? "#c6c6c6" : "#005ef6",
            }}
            onClick={onAddCondition}
          >
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
        {showOptions && searchInput && (
          <div className="atuoAccordion__filter-result">
            <div className="atuoAccordion__filter-result-list">
              <ScrollWrapper css="no-padding">
                {!!filteredOptions.length &&
                  filteredOptions.map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`filter-option-${index}`}
                      onClick={() => {
                        onSelectOption(row);
                      }}
                    >
                      <p>{row["condition"]}</p>
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>
      <div style={{ width: "100%", padding: "1rem", background: "#fff", marginBottom: -10, marginTop: 15 }}>
        <Checkbox onChange={handleCheckedCopy} disabled={is_locked || can_copy_hpi_from_previous_visit == 0} checked={checkedCopy}>
          Copy from previous visit
        </Checkbox>
      </div>
      <div className="atuoAccordion__result">
        <div className="atuoAccordion__result-list">
          <ScrollWrapper css="no-padding x-hidden hpi_container">
            <Collapse
              accordion
              onChange={(key) => {
                onClickConditionPanel(key);
              }}
            >
              {((data && [...data, { isSave: true }]) || []).map((row, index) => {
                if (row?.isSave) {
                  return (
                    <div
                      key={`bull ${index}`}
                      style={{
                        width: "100%",
                        height: 50,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                      }}
                    >
                      <button id={"save_all_HPI"} onClick={saveAll} disabled={is_locked || globalLoading} className="common-btn">
                        Save
                      </button>
                    </div>
                  );
                }
                return (
                  <Collapse.Panel
                    id={row?.condition ? row?.condition.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_") : null}
                    className="atuoAccordion__result-item"
                    key={`collapse-${_.snakeCase(row["id"])}`}
                    header={row.condition}
                    extra={<PanelToolbar onRemoveCondition={() => onRemoveCondition(index)} row={row} />}
                  >
                    <div className="content">
                      <div className="hpiList">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                          className="HPIItem"
                        >
                          <Checkbox
                            checked={row?.copy_from_previous_condition === 1 ? true : false}
                            onChange={(e) => coppyHandle(row, index, e)}
                            disabled={is_locked || row?.copy_from_previous_condition === -1}
                          >
                            <span style={{ width: 200 }}>Copy note from previous visit</span>
                          </Checkbox>
                        </div>
                        {(row.questions || []).map((question, index2) => (
                          <HPIConditionItem
                            errorHPI={errorHPI}
                            handleVariExtra={handleVariExtraApi}
                            handleVari={handleVariAPI}
                            varianceExtra={question?.variance_extra || ""}
                            setFirst={setFirst}
                            first={first}
                            condition={row?.condition}
                            checkHover={checkHover}
                            checkHover2={checkHover2}
                            is_multiple={question?.is_multiple == 1}
                            variance={question?.variance}
                            is_list={question?.is_list == 1}
                            indexRow={index}
                            key={`questionList-${index}-${index2}`}
                            data={question}
                            indexKey={index2}
                            isArtery={row?.condition && row?.condition.indexOf("Peripheral Artery") > -1}
                            onChangeCommentsNotLoad={(comments) => onChangeCommentsNotLoad(index, index2, comments)}
                            onChangeComments={(comments) => onChangeComments(index, index2, comments)}
                            onSelectChoice={(checked_choice) => onSelectChoice(index, index2, checked_choice, question?.is_multiple == 1)}
                            isLocked={is_locked}
                          />
                        ))}
                        <div className="HPIItem">
                          {row?.condition == "Intermittent Claudication" ||
                          row?.condition == "Rest Pain (CLI)" ||
                          row?.condition == "Acute Limb Ischemia (ALI)" ||
                          row?.condition == "Wound / Gangrene (CLI)" ||
                          row?.condition == "In-Stent or Restenosis" ? null : (
                            <Input.TextArea
                              disabled={is_locked}
                              placeholder={"Notes"}
                              autoSize={{ minRows: 3 }}
                              style={{
                                borderRadius: 0,
                                backgroundColor: "#f4f7fb",
                              }}
                              value={row?.text || ""}
                              onChange={(e) => {
                                handleChangeNotes(e.target?.value, row, index);
                              }}
                            />
                          )}
                          {/* Show red info */}
                          {(stateShowRed[row.condition] || []).map((r, index) => {
                            return (
                              <div key={index} style={{ width: "100%", marginTop: "10px", color: "#317CFE", fontWeight: "bold" }}>
                                <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} /> {r}
                                <br />
                              </div>
                            );
                          })}
                        </div>
                        {row.condition === "Carotid Disease" && indexesLeftCarotidS ? (
                          <div style={{ width: "100%", marginTop: "10px", color: "red" }}>
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} />
                            Medical necessity criteria met for Carotid Endarterectomy (Left) - Symptomatic per SVS 2022 Guidelines
                            <br />
                          </div>
                        ) : null}
                        {row.condition === "Carotid Disease" && indexesRightCarotidS ? (
                          <div style={{ width: "100%", marginTop: "10px", color: "red" }}>
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} />
                            Medical necessity criteria met for Carotid Endarterectomy (Right) - Symptomatic per SVS 2022 Guidelines
                            <br />
                          </div>
                        ) : null}
                        {row.condition === "Carotid Disease" && indexesLeftCarotidA ? (
                          <div style={{ width: "100%", marginTop: "10px", color: "red" }}>
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} />
                            Medical necessity criteria met for Carotid Endarterectomy (Left) - Asymptomatic per SVS 2022 Guidelines
                            <br />
                          </div>
                        ) : null}
                        {row.condition === "Carotid Disease" && indexesRightCarotidA ? (
                          <div style={{ width: "100%", marginTop: "10px", color: "red" }}>
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" style={{ display: "inline-block" }} />
                            Medical necessity criteria met for Carotid Endarterectomy (Right) - Asymptomatic per SVS 2022 Guidelines
                            <br />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

const SearchInput = (props) => {
  const [searchInput, setSearch] = useState("");
  const inpRef = useRef(null);
  const debouncedSearch = useDebounce(searchInput, 400);
  useEffect(() => {
    setSearch(props.searchInput);
  }, [props.searchInput]);

  useEffect(() => {
    props.setSearch(searchInput);
  }, [debouncedSearch]);

  const handleSearch = (search) => {
    setSearch(search);
  };
  const handleFocus = () => {
    inpRef.current.input.input.setAttribute("style", "border:2px solid #0f62fe !important");
    inpRef.current.input.input.parentNode.parentNode.style.border = "none";
    props.setShowOptions(true);
  };
  const handleBlur = () => {
    inpRef.current.input.input.setAttribute("style", "border:none !important");
    inpRef.current.input.input.parentNode.parentNode.style.border = "1px solid #dfe3e6";
    setTimeout(() => {
      props.setShowOptions(false);
    }, 250);
  };

  return (
    <Input.Search
      ref={inpRef}
      className="hpi_search"
      disabled={props.disabled}
      value={searchInput}
      placeholder="Search Condition"
      onChange={(e) => handleSearch(e.target?.value)}
      style={{ width: "100%" }}
      suffix={<SearchSVG />}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

let timers = null;
const HPIConditionItem = ({
  is_multiple,
  data,
  onChangeCommentsNotLoad,
  onChangeComments,
  handleVari,
  onSelectChoice,
  isLocked,
  onChangeData,
  is_list,
  isArtery,
  checkHover,
  checkHover2,
  indexKey,
  condition,
  errorHPI,
  indexRow,
  variance,
  varianceExtra,
  handleVariExtra,
}) => {
  const [question, setQuestion] = useState("");
  const [isVari, setIsVari] = useState(false);
  const [isAce, setIsAce] = useState(false);
  const [iODocState, setIoDocState] = useState("");
  const [hoverDelete, setHoverDelete] = useState({
    index: 0,
    status: false,
  });
  const [checkedChoice, setCheckedChoice] = useState(null);
  const [checkedChoiceMix, setCheckedChoiceMix] = useState([]);
  const [change, setChange] = useState(null);
  const [comments, setComments] = useState("");
  const [choices, setChoices] = useState([]);

  const { Option } = Select;

  const replaceTextTemp = (value, checkText) => {
    if (value.includes(` ${checkText}`) && !value.includes(` ${checkText} `)) {
      return value.replaceAll(` ${checkText}`, "");
    } else if (value.includes(` ${checkText}`) && value.includes(` ${checkText} `)) {
      return value.replaceAll(` ${checkText} `, "");
    } else {
      return value.replaceAll(`${checkText}`, "");
    }
  };

  useEffect(() => {
    clearTimeout(timers);
    timers = setTimeout(() => {
      onChangeCommentsNotLoad(comments);
    }, 1000);
  }, [comments]);

  useEffect(() => {
    if (!is_multiple) {
      let question = "";
      let choices = [];
      let comments = "";
      let checked_choice = "";
      question = data?.question;
      choices = data?.choices;
      comments = data?.comments || "";
      checked_choice = data?.checked_choice;
      if (comments && comments != " " && comments.length > 0) {
        comments = comments || "";
        (data?.choices || []).map((r) => {
          if (r.option == data?.checked_choice) {
            if (!comments.includes(r.text_template)) {
              comments = comments
                ? comments +
                  `${
                    r.text_template && r.text_template.slice(r.text_template?.length - 2, r.text_template?.length).includes("\n")
                      ? ""
                      : r.text_template
                      ? " "
                      : ""
                  }` +
                  (r.text_template || "")
                : r.text_template || "";
            }
          } else if (r.option == "" || data?.checked_choice == "") {
            comments = replaceTextTemp(comments, r.text_template);
          } else {
            comments = replaceTextTemp(comments, r.text_template);
          }
        });
      } else {
        (data?.choices || []).map((r) => {
          if (r.option == data?.checked_choice) {
            comments = replaceTextTemp(comments, r.text_template);
          } else if (r.option == "" || data?.checked_choice == "") {
            comments = "";
          }
        });
      }
      setQuestion(question);
      setChoices(choices);
      setComments(comments);
      setCheckedChoice(checked_choice);
    } else {
      let question = "";
      let choices = [];
      let comments = data?.comments || "";
      let checked_choiceMix = [];
      question = data?.question;
      choices = data?.choices;
      const checkedChoiceData = data?.checked_choice || "";
      if (!Array.isArray(checkedChoiceData)) {
        checked_choiceMix = checkedChoiceData.split(",");
      } else {
        checked_choiceMix = checkedChoiceData;
      }
      if (
        (checked_choiceMix && checked_choiceMix.length <= 1 && checked_choiceMix[0] == "") ||
        (checked_choiceMix && checked_choiceMix.length == 0)
      ) {
        comments = "";
      } else {
        if (comments && comments != " " && comments.length > 0) {
          comments = "";
          (data?.choices || []).map((r) => {
            const value = `${is_list ? `•${r.text_template}\n` : `${r.text_template}`}`;
            const valueCheck = `${is_list ? `•${r.text_template}` : `${r.text_template}`}`;
            if (r.option && checked_choiceMix.indexOf(r.option) > -1) {
              if (comments.indexOf(value) === -1 && comments.indexOf(valueCheck) === -1) {
                comments = comments
                  ? comments +
                    `${
                      value && value.slice(value?.length - 2, value?.length).includes("\n")
                        ? ""
                        : value && !value.slice(value?.length - 2, value?.length).includes("\n") && is_list
                        ? "\n"
                        : " "
                    }` +
                    value
                  : value;
              }
            } else if (r.option == "" || data?.checked_choice == "") {
              comments = replaceTextTemp(comments, value);
            } else {
              comments = replaceTextTemp(comments, value);
            }
          });
        } else {
          (data?.choices || []).map((r) => {
            const value = `${is_list ? `•${r.text_template}\n` : `${r.text_template}`}`;
            if (r.option && checked_choiceMix.indexOf(r.option) > -1) {
              comments += r.text_template
                ? value +
                  `${
                    value && value.slice(value?.length - 2, value?.length).includes("\n")
                      ? ""
                      : value && !value.slice(value?.length - 2, value?.length).includes("\n") && is_list
                      ? "\n"
                      : " "
                  }`
                : " ";
            } else if (r.option == "" || data?.checked_choice == "") {
              comments = "";
            }
          });
        }
      }
      setCheckedChoiceMix(checked_choiceMix);
      setQuestion(question);
      setChoices(choices);
      setComments(comments);
    }
  }, [JSON.stringify(data), change]);

  const handleInsideMix = (choice, mixArray) => {
    if (choice !== "") {
      if (!mixArray.some((r) => r === choice.option)) {
        if (choice?.option === "None") {
          mixArray = ["None"];
        } else {
          const noneIndex = mixArray?.indexOf("None");
          if (noneIndex !== -1) {
            mixArray.splice(noneIndex, 1);
            mixArray.push(choice.option);
          } else {
            mixArray.push(choice.option);
          }
        }
      } else {
        mixArray = mixArray.filter((r) => r !== choice.option);
      }
      setCheckedChoiceMix([...mixArray]);
      onSelectChoice([...mixArray]);
    }
  };

  const handleMix = (choice, index, condition, question) => {
    let checked_choiceMix = [];
    const checkedChoiceData = data?.checked_choice || "";
    if (!Array.isArray(data?.checked_choice)) {
      checked_choiceMix = checkedChoiceData.split(",");
    } else {
      checked_choiceMix = checkedChoiceData;
    }
    let mixArray = [...checked_choiceMix];
    const valueCheck = ["Sudden Pain", "Claudication", "Rest Pain", "Wound/Gangrene", "Restenosis"];
    if (
      condition &&
      condition === "Peripheral Artery Disease (PAD)" &&
      (question == "Does the patient suffer from RIGHT Lower Extremity symptoms?" ||
        question == "Does the patient suffer from LEFT Lower Extremity symptoms?" ||
        question == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?")
    ) {
      if (hoverDelete?.status && hoverDelete?.index === index) {
        handleInsideMix(choice, mixArray);
        setHoverDelete(null);
      } else if (valueCheck.some((r) => r === choice?.option) && checked_choiceMix.indexOf(choice?.option) !== -1) {
        setHoverDelete({
          index: index,
          status: true,
        });
      } else {
        handleInsideMix(choice, mixArray);
      }
    } else {
      handleInsideMix(choice, mixArray);
    }
    setChange(Math.random());
  };

  useEffect(() => {
    if (
      question === "Has the patient been on Cilostazol (Pletal) therapy for at least 12 weeks?" ||
      question === "Has the patient been on statin therapy for at least 12 weeks?" ||
      question === "Has the patient been on ACE inhibitor or Angiotensin Receptor Blocker?"
    ) {
      setIsVari(true);
    } else if (
      question == "Has the patient been on antiplatelet therapy for at least 12 weeks?" ||
      JSON.stringify(question) == "Has the patient been on antiplatelet therapy for at least 12 weeks?"
    ) {
      setIsVari(true);
    } else {
      setIsVari(false);
    }
  }, [question]);

  return (
    <div className={`HPIItem`}>
      <div
        className="HPIItem__label"
        // style={{ color: (data && !data?.checked_choice) || data?.checked_choice == "" || data?.checked_choice?.length == 0 ? "red" : "#000" }}
      >
        {question}
      </div>
      {is_multiple ? (
        <div className={`HPIItem__options`}>
          {choices &&
            choices.map((choice, index) => {
              return isVari && choice.option.toLowerCase() === "Variance".toLowerCase() ? (
                <>
                  <div className={`editableText material varianceContainer`} style={{ width: "125px" }}>
                    <Select
                      placeholder={choice?.option}
                      style={
                        data?.checked_choice == "" && errorHPI && !variance
                          ? { border: "1px solid red", width: "100%", backgroundColor: "#ff000010" }
                          : !variance
                          ? { width: "100%", border: "1px solid #dfe3e6", backgroundColor: "#f4f7fb" }
                          : { border: "1px solid #0f62fe", width: "100%", backgroundColor: "#dbe3ee" }
                      }
                      onChange={(e) => {
                        setIoDocState(e);
                        handleVari && handleVari(e, indexRow, question, indexKey);
                      }}
                      value={variance || iODocState}
                      optionKey={"value"}
                      valueKey={"label"}
                    >
                      {(SelectOptions || [])?.map((condition, index) => (
                        <Option
                          style={{
                            textTransform: "capitalize",
                          }}
                          key={`condition-${condition?.value} - ${index}`}
                          value={condition.value}
                        >
                          {condition?.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {variance && variance == "contraindication" ? (
                    <div className={`editableText material varianceContainer`} style={{ width: "125px" }}>
                      <Select
                        placeholder={choice?.option}
                        style={
                          !varianceExtra
                            ? { border: "1px solid red", width: "100%", backgroundColor: "#ff000010" }
                            : { border: "1px solid #0f62fe", width: "100%", backgroundColor: "#dbe3ee" }
                        }
                        onChange={(e) => {
                          handleVariExtra && handleVariExtra(e, indexRow, question, indexKey);
                        }}
                        value={varianceExtra}
                        optionKey={"value"}
                        valueKey={"label"}
                      >
                        {(SelectOptionsExtra[question] || [])?.map((condition, index) => (
                          <Option
                            style={{
                              textTransform: "capitalize",
                            }}
                            key={`condition-${condition?.value} - ${index}`}
                            value={condition.value}
                          >
                            {condition?.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  ) : null}
                </>
              ) : (
                <button
                  id={`${choice.option && choice.option.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")}_${
                    question && question.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")
                  }`}
                  key={`question-choice-${index}`}
                  className={`HPIItem__option ${isLocked == 0 && "not-locked"}
                ${
                  checkedChoiceMix.indexOf(choice.option) > -1
                    ? hoverDelete?.status && hoverDelete?.index === index
                      ? "hover-delete"
                      : "active"
                    : checkHover &&
                      checkHover[`quest_${indexKey + 1}`] &&
                      checkHover[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                      isArtery
                    ? "hover-show"
                    : checkHover2 &&
                      checkHover2[`quest_${indexKey + 1}`] &&
                      checkHover2[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                      isArtery
                    ? "hover-green-show"
                    : "non-active"
                }`}
                  style={
                    // check if selected option have and isArtery == true
                    checkHover2 &&
                    checkHover2[`quest_${indexKey + 1}`] &&
                    checkHover2[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                    isArtery
                      ? {}
                      : data?.checked_choice == "" && errorHPI && !variance
                      ? {
                          border: "1px solid red",
                          backgroundColor: "#ff000010",
                        }
                      : {}
                  }
                  onClick={() => {
                    handleMix(choice, index, condition, question);
                    setIoDocState("");
                  }}
                  disabled={isLocked == 1}
                >
                  {choice.option}
                </button>
              );
            })}
        </div>
      ) : (
        <div className={`HPIItem__options`}>
          {choices &&
            choices.map((choice, index) => {
              return isVari && choice.option.toLowerCase() === "Variance".toLowerCase() ? (
                <>
                  <div className={`editableText material varianceContainer`} style={{ width: "125px" }}>
                    <Select
                      placeholder={choice?.option}
                      style={
                        data?.checked_choice == "" && errorHPI && !variance
                          ? { border: "1px solid red", width: "100%", backgroundColor: "#ff000010" }
                          : !variance
                          ? { width: "100%", border: "1px solid #dfe3e6", backgroundColor: "#f4f7fb" }
                          : { border: "1px solid #0f62fe", width: "100%", backgroundColor: "#dbe3ee" }
                      }
                      className="varianceSelect"
                      onChange={(e) => {
                        setIoDocState(e);
                        handleVari && handleVari(e, indexRow, question, indexKey);
                      }}
                      value={variance || ""}
                      optionKey={"value"}
                      valueKey={"label"}
                    >
                      {(SelectOptions || [])?.map((condition, index) => (
                        <Option
                          style={{
                            textTransform: "capitalize",
                          }}
                          key={`condition-${condition?.value} - ${index}`}
                          value={condition.value}
                        >
                          {condition?.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {variance && variance == "contraindication" ? (
                    <div className={`editableText material varianceContainer`} style={{ width: "125px" }}>
                      <Select
                        placeholder={choice?.option}
                        style={
                          !varianceExtra
                            ? { border: "1px solid red", width: "100%", backgroundColor: "#ff000010" }
                            : { border: "1px solid #0f62fe", width: "100%", backgroundColor: "#dbe3ee" }
                        }
                        onChange={(e) => {
                          handleVariExtra && handleVariExtra(e, indexRow, question, indexKey);
                        }}
                        value={varianceExtra}
                        optionKey={"value"}
                        valueKey={"label"}
                      >
                        {(SelectOptionsExtra[question] || [])?.map((condition, index) => (
                          <Option
                            style={{
                              textTransform: "capitalize",
                            }}
                            key={`condition-${condition?.value} - ${index}`}
                            value={condition.value}
                          >
                            {condition?.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  ) : null}
                </>
              ) : (
                <button
                  id={`${choice.option && choice.option.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")}_${
                    question && question.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")
                  }`}
                  key={`question-choice-${index}`}
                  className={`HPIItem__option ${isLocked == 0 && "not-locked"}
                ${
                  choice.option == data?.checked_choice
                    ? hoverDelete?.status && hoverDelete?.index === index
                      ? "hover-delete"
                      : "active"
                    : checkHover &&
                      checkHover[`quest_${indexKey + 1}`] &&
                      checkHover[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                      isArtery
                    ? "hover-show"
                    : checkHover2 &&
                      checkHover2[`quest_${indexKey + 1}`] &&
                      checkHover2[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                      isArtery
                    ? "hover-green-show"
                    : "non-active"
                }`}
                  style={
                    checkHover2 &&
                    checkHover2[`quest_${indexKey + 1}`] &&
                    checkHover2[`quest_${indexKey + 1}`].some((r) => r == choice.option) &&
                    isArtery
                      ? {}
                      : data?.checked_choice == "" && errorHPI && !variance
                      ? {
                          border: "1px solid red",
                          backgroundColor: "#ff000010",
                        }
                      : {}
                  }
                  onClick={() => {
                    if (
                      hoverDelete?.status &&
                      hoverDelete?.index === index &&
                      question == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?"
                    ) {
                      setCheckedChoice(null);
                      onSelectChoice(null);
                      setHoverDelete(null);
                    } else if (
                      choice.option == "No" &&
                      checkedChoice == "No" &&
                      question == "Has the patient ever been screened for Abdominal Aortic Aneurysm (AAA)?"
                    ) {
                      setHoverDelete({
                        index: index,
                        status: true,
                      });
                    } else {
                      setIoDocState("");
                      setCheckedChoice(choice.option);
                      onSelectChoice(choice.option);
                      setHoverDelete(null);
                    }
                  }}
                  disabled={isLocked}
                >
                  {choice.option}
                </button>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default HPIConditionList;
