import { Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { badgePayPeriod } from "../../../../services/api.services";
import { getTimeEntries } from "../../../../store/actions";

const DashboardSidebar = React.forwardRef(({ isShow, currentUserInfo, bagdeTime }, ref) => {
  const dispatch = useDispatch();

  const [isBadgedIn, setIsBadgedIn] = useState(false);
  const [userIP, setUserIP] = useState(null);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  useEffect(() => {
    if (bagdeTime?.length > 0) {
      if (bagdeTime[0]["status"] === 0) {
        setIsBadgedIn(false);
      } else {
        setIsBadgedIn(true);
      }
      if (bagdeTime[0]["user_ip_out"]) {
        setUserIP(bagdeTime[0]["user_ip_out"]);
      } else if (bagdeTime[0]["user_ip_in"]) {
        setUserIP(bagdeTime[0]["user_ip_in"]);
      } else {
        setUserIP(null);
      }
    }
  }, [bagdeTime]);

  const recordPayPeriod = async () => {
    const response = await badgePayPeriod();
    if (response) {
      dispatch(getTimeEntries());
    }
  };

  if (!currentUserInfo) {
    return null;
  }

  return (
    <Drawer
      title={currentUserInfo?.name + (currentUserInfo.position && `( ${currentUserInfo.position} )`) || " "}
      placement="right"
      closable={false}
      className={sideNavStatus ? "wide" : ""}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "2rem",
              marginTop: "1.5rem",
            }}
          >
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Name"} value={currentUserInfo?.name} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Address"} value={currentUserInfo?.address} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Apt"} value={currentUserInfo?.address_2} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"City"} value={currentUserInfo?.city} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"State"} value={currentUserInfo?.state} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"SSN"} value={currentUserInfo?.ssn} mask={"ssn"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Phone"} value={currentUserInfo?.phone} mask={"phone"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"E-Mail"} value={currentUserInfo.email} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Employee Score"} value={currentUserInfo.points} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Wage"} value={currentUserInfo.meta && currentUserInfo.meta.salary} prefix={"$"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Start Date"}
                value={currentUserInfo.meta && moment(currentUserInfo.meta.start_date).format("MM/DD/YYYY")}
                type={"date"}
                noEditable={true}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"PTO Hours"} value={currentUserInfo.vacation_hours_per_year} unit={"hours"} />
            </div>
            <div className="actionContent">
              <button onClick={recordPayPeriod}>{!isBadgedIn ? "Badge In" : "Badge Out"}</button>
            </div>
            {userIP && isBadgedIn && (
              <p
                style={{
                  textAlign: "right",
                  margin: "1rem 0.5rem",
                }}
              >
                <b>IP Address : </b>
                {userIP}
                <br />
                <b>City, State : </b>
                Detroit, MI
              </p>
            )}
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default DashboardSidebar;
