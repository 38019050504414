import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteComponentPlanOder from "../AutoCompletePlanOder";
import DiseaseBody from "../DiseasBody";
import ScrollWrapper from "../ScrollBar";
import ToastMessage from "../Toast";

import { getPatient, getProviderSchedule } from "../../../services/api.services";
import { validateSteps } from "../../../services/validations";
import {
  acceptForce,
  clinicProviderReviewLoadInit,
  delete_plan,
  filterAppointments,
  filterLocations,
  filterProviders,
  getAppointments,
  getFieldMedication,
  get_plan_patient,
  removeProviderForPlan,
  save_params_plan,
  save_plan_patient,
  searchMedications,
  search_plan_suggestion,
  setGlobalLoading,
  setGlobalToastr,
  setSidebarState,
  setStepData,
  update_plan_patient,
} from "../../../store/actions";
import Sidebar from "./Sidebar";
import { getLocal } from "../../../helpers/Local";
import RemoveN from "../../../helpers/RemoveN";
import ReplaceN from "../../../helpers/ReplaceN";
import EmptySidebar from "./EmptySidebar";

const initialCondition = [
  { step: "demographics", status: true },
  { step: "insurance", status: true },
  { step: "medical", status: true },
  { step: "questions", status: true },
  { step: "schedule", status: true },
  { step: "communication", status: true },
  { step: "attempt", status: true },
];

let timer = null;
const PlanOrders = ({ sidebarIcon, navigateToReview }) => {
  const [isSidebar, setIsSlidebar] = useState(true);
  const [loading, setLoading] = useState(false);
  const [planNames, setPlanNames] = useState({});
  const [planNamesNoSide, setPlanNamesNoSide] = useState({});
  const [plans, setPlans] = useState([]);
  const [plansType, setPlansType] = useState([]);
  const scheduleLoading = useSelector((state) => state.newPatient.scheduleLoading);
  const dataMedications = useSelector((state) => state.common.dataMedications);
  const [enableError, setEnableError] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const referredProviders = useSelector((state) => state.common.referredProviders);
  const referralConditions = useSelector((state) => state.common.referralConditions);
  const filteredLocations = useSelector((state) => state.common.filteredLocations);
  const locations = useSelector((state) => state.procedure.locations);
  const forceActive = useSelector((state) => state.common.forceActive);
  const forceValue = useSelector((state) => state.common.forceValue);
  const scheduleOptions = useSelector((state) => state.newPatient.scheduleOptions);
  const filteredProviders = useSelector((state) => state.common.filteredProviders);
  const providers = useSelector((state) => state.procedure.providers);
  const [idPlan, setIDPlan] = useState([]);
  const [activeRow, setActiveRow] = useState({});
  const [keyCheck, setKeyCheck] = useState(null);
  const [procedure_id_row, setProcedure_id_row] = useState(0);
  const [currentPlanId, setCurrentPlanId] = useState(0);
  const [typePlanId, setTypePlanId] = useState(null);
  const [typeAllPlanId, setTypeAllPlanId] = useState([]);
  const [HPICheck, setHPICheck] = useState([]);
  const [selectedCheck, setSelectedCheck] = useState({});
  const [processHandle, setProcessHandle] = useState({});
  const [processSave, setProcessSave] = useState({});
  const [patientPlan, setPatientPlan] = useState([]);
  const [dataMedication, setDataMedication] = useState([]);
  const [sideHandle, setSideHandle] = useState({});
  const [textNotes, setTextNotes] = useState({});
  const [hasSideHandle, setHasSideHandle] = useState({});
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const [isToastr, setIsToastr] = useState(false);
  const [activeSide, setActiveSide] = useState(false);
  const [offSide, setOffSide] = useState(true);
  const [onButton, setOnButton] = useState(false);
  const [isDeleteToastr, setIsDeleteToastr] = useState(false);
  const all_plans = useSelector((state) => state.procedure.search_plan_all);
  const patient_plan = useSelector((state) => state.procedure.get_plan_patient);
  const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);
  const [dataPatientMedication, setDataPatientMedication] = useState(null);

  const updatePatient = async () => {
    if (current_patient_id) {
      const dataGetPatient = await getPatient({ id: current_patient_id });
      setDataPatientMedication(dataGetPatient?.id ? dataGetPatient?.medications || [] : null);
    }
  };

  useEffect(() => {
    setPatientPlan(patient_plan);
  }, [patient_plan]);

  useEffect(() => {
    if (patient_plan && patient_plan.length > 0) {
      const mapCheck = patient_plan.map((r, index) => {
        return {
          note: ReplaceN(r["plan_note"] || ""),
          hpi_condition_id: r["condition_id"] || "",
        };
      });
      setHPICheck([...mapCheck]);
    } else {
      setHPICheck([]);
    }
  }, [patient_plan]);

  const handleForce = () => {
    handleChangeSelect(null, null, true);
  };

  useEffect(() => {
    if (forceActive) {
      if (plansType && plansType[typePlanId] && plansType[typePlanId].length > 0 && plansType[typePlanId][activeRow[typePlanId]] && activeSide) {
        handleForce();
      } else {
        savePlan(true);
      }
      dispatch(acceptForce(false));
    }
  }, [forceActive]);

  useEffect(() => {
    dispatch(getFieldMedication(current_appointment_id));
  }, [current_appointment_id]);

  const dropdownOption = useSelector((state) => state.newPatient.dropdownOption);
  const [planMap, setPlanMap] = useState([]);

  const pcpList = useSelector((state) => state.common.pcpList);
  const dispatch = useDispatch();

  const loaderTabel = async () => {
    await dispatch(setGlobalLoading(true));
    await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
    // await dispatch(filterLocations("", "all"));
    // await dispatch(filterProviders(""));
    await dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    dispatch(search_plan_suggestion());
  }, []);

  useEffect(() => {
    dispatch(setSidebarState(true));
    loaderTabel();
    if (window.innerWidth <= 768) {
      setIsSlidebar(false);
    }
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  const removeState = () => {
    setPatientPlan([]);
    setPlanNamesNoSide([]);
    setKeyCheck(null);
    setActiveRow({});
  };

  useEffect(() => {
    removeState();
    updatePatient();
  }, []);

  const changeSetProcessHandle = (index, value, date, typeCheck) => {
    if (typeCheck == "ClinicDisease") {
      const indexCheck = patientPlan.findIndex((r) => r.condition_id == value);
      if (indexCheck != -1) {
        //
        let listItems = [...processHandle[value]];
        listItems[index] = true;
        setProcessHandle({ ...processHandle, [value]: listItems });
        //
        listItems = [...processSave[value]];
        listItems[index] = false;
        setProcessSave({ ...processSave, [value]: listItems });
        //
        const checkItem = [...patientPlan];
        checkItem[indexCheck]["plan"][index]["processed"] = 1;
        checkItem[indexCheck]["plan"][index]["followup_appointment"] = {
          ...checkItem[indexCheck]["plan"][index]["followup_appointment"],
          appointment_date: date?.followup_date,
        };
        checkItem[indexCheck]["plan"][index]["procedure"] = {
          ...checkItem[indexCheck]["plan"][index]["procedure"],
          appointment_date: date?.procedure_date,
        };
        setPatientPlan([...checkItem]);
      }
    } else if (typeCheck === "Angio") {
      const indexCheck = patientPlan.findIndex((r) => r.condition_id == value);
      if (indexCheck != -1) {
        //
        let listItems = [...processHandle[value]];
        listItems[index] = true;
        setProcessHandle({ ...processHandle, [value]: listItems });
        listItems = [...processSave[value]];
        listItems[index] = false;
        setProcessSave({ ...processSave, [value]: listItems });
        //
        const checkItem = [...patientPlan];
        checkItem[indexCheck]["plan"][index]["processed"] = 1;
        checkItem[indexCheck]["plan"][index]["followup_appointment"] = {
          ...checkItem[indexCheck]["plan"][index]["followup_appointment"],
          appointment_date: date?.followup_date,
        };
        checkItem[indexCheck]["plan"][index]["angio"] = {
          ...checkItem[indexCheck]["plan"][index]["angio"],
          appointment_date: date?.procedure_date,
        };
        setPatientPlan([...checkItem]);
      }
    }
  };

  useEffect(() => {
    if (patientPlan && all_plans) {
      let idArray = {};
      let nameArray = {};
      let urgencyArray = {};
      let typeArray = {};
      let mapIDPlan = {};
      let processCheck = {};
      let saveCheck = {};
      let sideCheck = {};
      let textNote = {};
      let hasSideCheck = {};
      let nameNoSideArray = {};
      const condition_id = (patientPlan || []).map((r, index) => {
        return r?.condition_id;
      });
      (patientPlan || []).map((patient, indexPatient) => {
        const checkIdArray = (patient.plan || []).map((r) => {
          if (r.bundle === "ultrasound_test") {
            return r.ultrasound && r.ultrasound.us_test && r.ultrasound.us_test?.id;
          } else if (r.bundle === "procedure") {
            return (
              (all_plans || []).find((item) => r.procedure && r.procedure.procedure_type && item?.name === r.procedure.procedure_type?.value) || {}
            ).id;
          } else if (r.bundle === "clinic" || r.bundle === "") {
            return ((all_plans || []).find((item) => r.procedure && r.procedure.clinic_type && item?.name === r.procedure.clinic_type?.name) || {})
              .id;
          } else if (r.bundle === "general" || r.bundle === "ct" || r.bundle === "referral") {
            return (
              (all_plans || []).find(
                (item) => r.procedure_extra && r.procedure_extra.procedure_type && item?.name === r.procedure_extra.procedure_type?.name
              ) || {}
            ).id;
          } else if (r.bundle === "angio") {
            return ((all_plans || []).find((item) => r.angio && r.angio.angio_type && item?.name === r.angio.angio_type?.name) || {}).id;
          } else {
            return r;
          }
        });
        const checkNameArray = (patient.plan || []).map((r) => {
          if (r.bundle === "ultrasound_test") {
            if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
              return r.ultrasound && r.ultrasound.ultrasound_type && r.side + " " + r.ultrasound.ultrasound_type?.name;
            } else {
              return r.ultrasound && r.ultrasound.ultrasound_type && r.ultrasound.ultrasound_type?.name;
            }
          } else if (r.bundle === "procedure") {
            if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
              return r.procedure && r.procedure.procedure_type && r.side + " " + r.procedure.procedure_type?.value;
            } else {
              return r.procedure && r.procedure.procedure_type && r.procedure.procedure_type?.value;
            }
          } else if (r.bundle === "clinic" || r.bundle === "") {
            return r.procedure && r.procedure.clinic_type && r.procedure.clinic_type?.name;
          } else if (r.bundle === "general" || r.bundle === "ct" || r.bundle === "referral") {
            return r.procedure_extra && r.procedure_extra.procedure_type && r.procedure_extra.procedure_type?.name;
          } else if (r.bundle === "angio") {
            if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
              return r.angio && r.angio.angio_type && r.side + " " + r.angio.angio_type?.name;
            } else {
              return r.angio && r.angio.angio_type && r.angio.angio_type?.name;
            }
          } else if (r.bundle === "prescription") {
            return r;
          }
        });
        const checkNameNoSideArray = (patient.plan || []).map((r) => {
          if (r.bundle === "ultrasound_test") {
            return r.ultrasound && r.ultrasound.ultrasound_type && r.ultrasound.ultrasound_type?.name;
          } else if (r.bundle === "procedure") {
            return r.procedure && r.procedure.procedure_type && r.procedure.procedure_type?.value;
          } else if (r.bundle === "clinic" || r.bundle === "") {
            return r.procedure && r.procedure.clinic_type && r.procedure.clinic_type?.name;
          } else if (r.bundle === "general" || r.bundle === "ct" || r.bundle === "referral") {
            return r.procedure_extra && r.procedure_extra.procedure_type && r.procedure_extra.procedure_type?.name;
          } else if (r.bundle === "angio") {
            return r.angio && r.angio.angio_type && r.angio.angio_type?.name;
          } else if (r.bundle === "prescription") {
            return r;
          }
        });
        const checkUrgencyArray = (patient.plan || []).map((r, index) => {
          return r.urgency ? r.urgency : undefined;
        });
        const checkTypeArray = (patient.plan || []).map((r) => r.bundle);
        const checkMapIDPlan = (patient.plan || []).map((r) => r?.id);
        const checkProcessCheck = (patient.plan || []).map((r, index) => {
          return !!r.processed;
        });
        const saveProcessCheck = (patient.plan || []).map((r, index) => {
          return !!r.is_saved;
        });
        const checksideCheck = (patient.plan || []).map((r, index) => {
          return r.side;
        });
        const checkTextNote = (patient.plan || []).map((r, index) => {
          return r.note;
        });
        const checkHasSideCheck = (patient.plan || []).map((r, index) => {
          return r.has_side;
        });
        processCheck = {
          ...processCheck,
          [condition_id[indexPatient]]: checkProcessCheck,
        };
        saveCheck = {
          ...saveCheck,
          [condition_id[indexPatient]]: saveProcessCheck,
        };
        sideCheck = {
          ...sideCheck,
          [condition_id[indexPatient]]: checksideCheck,
        };
        textNote = {
          ...textNote,
          [condition_id[indexPatient]]: checkTextNote,
        };
        hasSideCheck = {
          ...hasSideCheck,
          [condition_id[indexPatient]]: checkHasSideCheck,
        };
        mapIDPlan = {
          ...mapIDPlan,
          [condition_id[indexPatient]]: checkMapIDPlan,
        };
        typeArray = {
          ...typeArray,
          [condition_id[indexPatient]]: checkTypeArray,
        };
        urgencyArray = {
          ...urgencyArray,
          [condition_id[indexPatient]]: checkUrgencyArray,
        };
        nameArray = {
          ...nameArray,
          [condition_id[indexPatient]]: checkNameArray,
        };
        nameNoSideArray = {
          ...nameNoSideArray,
          [condition_id[indexPatient]]: checkNameNoSideArray,
        };
        //
        idArray = { ...idArray, [condition_id[indexPatient]]: checkIdArray };
      });
      setSelectedCheck(urgencyArray);
      setIDPlan(mapIDPlan);
      setPlansType(typeArray);
      setPlans(idArray);
      setPlanNames(nameArray);
      setPlanNamesNoSide(nameNoSideArray);
      setProcessHandle(processCheck);
      setProcessSave(saveCheck);
      setSideHandle(sideCheck);
      setTextNotes(textNote);
      setHasSideHandle(hasSideCheck);
      setTypeAllPlanId(condition_id);
      if (activeRow && Object.keys(activeRow).length <= 0) {
        let check = false;
        (patientPlan || []).map((patient, index) => {
          (patient.plan || []).map((item, key) => {
            /*
            We build a map of plan that will be used for next button
             */
            if (item.processed === 0) {
              const temp_map = [...planMap];
              temp_map.push({ [patient?.condition_id]: key });
              setPlanMap(temp_map);
            }
            if (item.processed === 0 && !check) {
              setActiveRow({ [patient?.condition_id]: key });
              setProcedure_id_row(key);
              setTypePlanId(patient?.condition_id);
              check = true;
              setCurrentPlanId(item?.id);
            }
          });
        });
      }
      if (patientPlan) {
        let block = false;
        patientPlan.map((r, index) => {
          (r.plan || []).map((item, itemIndex) => {
            if (!block && r.plan.some((check) => check.processed == 0)) {
              setOnButton(false);
              block = true;
            } else if (!block && r.plan.some((check) => check.processed == 1)) {
              setOnButton(true);
              block = true;
            }
          });
        });
      }
    }
  }, [patientPlan]);

  useEffect(() => {
    if (patientPlan) {
      const check = (patientPlan || []).filter((r) => r?.condition_id === typePlanId);
      if (check && check[0]) {
        const arrayPlan = check[0].plan && check[0].plan[activeRow[typePlanId]];
        if (arrayPlan) {
          setProcedure_id_row(arrayPlan.entity_id);
        }
      }
    }
  }, [activeRow]);

  const handleCloseSidebar = () => {
    setIsSlidebar(false);
  };

  const handleChangeSelect = async (value, type, force) => {
    setLoading(true);
    dispatch(setGlobalLoading(true));
    const planAllArrayCheck = [];

    typeAllPlanId.map((r, mix) => {
      (plans[r] || []).map((item, index) => {
        if (item && item.entity_id && !force) {
          planAllArrayCheck.push({
            id: item?.id || null,
            entity_id: item?.entity_id,
            hpi_condition_id: item?.hpi_condition_id,
            processed: item?.processed,
            has_side: item?.has_side,
            frequency: item?.prescription?.frequency,
            showSide: item?.showSide,
            type: item?.bundle,
            note: item?.note,
          });
        } else if (force && r == forceValue) {
          // idPlan[r][index] == currentPlanId ||
          planAllArrayCheck.push({
            id: idPlan[r][index],
            entity_id: item,
            type: plansType[r][index],
            side: type === "side" ? value[r][index] : (sideHandle && sideHandle[r] && sideHandle[r][index]) || "",
            hpi_condition_id: r,
            processed: processHandle[r][index] ? processHandle[r][index] : 0,
            urgency: type === "urgency" ? value[r][index] : (selectedCheck && selectedCheck[r] && selectedCheck[r][index]) || "",
            showSide: hasSideHandle && hasSideHandle[r] && hasSideHandle[r][index],
            note: textNotes && textNotes[r] ? textNotes[r][index] : null,
            force: 1,
          });
        } else {
          planAllArrayCheck.push({
            id: idPlan[r][index],
            entity_id: item,
            type: plansType[r][index],
            side: type === "side" ? value[r][index] : (sideHandle && sideHandle[r] && sideHandle[r][index]) || "",
            hpi_condition_id: r,
            processed: processHandle[r][index] ? processHandle[r][index] : 0,
            urgency: type === "urgency" ? value[r][index] : (selectedCheck && selectedCheck[r] && selectedCheck[r][index]) || "",
            showSide: hasSideHandle && hasSideHandle[r] && hasSideHandle[r][index],
            note: textNotes && textNotes[r] ? textNotes[r][index] : null,
          });
        }
      });
    });
    const checkPlanTrue = [
      { value: "side", type: "default" },
      { value: "urgency", type: "default" },
    ];
    const checkPlanFalse = [{ value: "urgency", type: "default" }];
    if (planAllArrayCheck && planAllArrayCheck.length > 0) {
      // fix check process
      const check = planAllArrayCheck.map((item, index) => {
        if (
          item.showSide === false &&
          item.type !== "general" &&
          item.type !== "other" &&
          item.type !== "prescription" &&
          item.type !== "referral" &&
          item.type !== "ct"
        ) {
          if (
            !validateSteps(
              {
                urgency: item.urgency,
              },
              checkPlanFalse
            )
          ) {
            setEnableError(true);
            return false;
          } else {
            return true;
          }
        } else if (
          item.type !== "general" &&
          item.type !== "other" &&
          item.type !== "prescription" &&
          item.type !== "referral" &&
          item.type !== "ct"
        ) {
          if (
            !validateSteps(
              {
                urgency: item.urgency,
                side: item.side,
              },
              checkPlanTrue
            )
          ) {
            setEnableError(true);
            return false;
          } else {
            return true;
          }
        } else if (item.type == "other") {
          if (!item?.urgency) {
            return false;
          } else {
            return true;
          }
        } else if (item.type == "prescription" && !item?.frequency) {
          setEnableError(true);
          return false;
        } else if (item.type == "prescription" && item.frequency) {
          return true;
        } else {
          return true;
        }
      });
      if (check.some((r) => r == false)) {
        setLoading(false);
        dispatch(setGlobalLoading(false));
      } else {
        const param = {
          patient_id: current_patient_id,
          procedure_id: current_appointment_id,
          plans: planAllArrayCheck,
          medications: dataMedication.map((item) => {
            return {
              id: item?.id || null,
              entity_id: item?.entity_id,
              hpi_condition_id: item?.hpi_condition_id,
              processed: item?.processed,
              has_side: item?.has_side,
              showSide: item?.showSide,
              type: item?.bundle,
            };
          }),
          plan_notes: HPICheck.map((r) => {
            return {
              ...r,
              note: RemoveN(r?.note || ""),
            };
          }),
        };
        await dispatch(save_params_plan(param));
        const dataGet = await dispatch(save_plan_patient(param));
        if (dataGet && Array.isArray(dataGet)) {
          setPatientPlan([...dataGet]);
        }
        setLoading(false);
        dispatch(setGlobalLoading(false));
      }
    } else {
      setLoading(false);
      dispatch(setGlobalLoading(false));
    }
  };

  const onChangeSelect = (value, index, type) => {
    const arrayMap = (selectedCheck[type] || []).map((r, i) => {
      if (index === i) {
        return value;
      }
      return r;
    });
    setSelectedCheck({ ...selectedCheck, [type]: arrayMap });
    handleChangeSelect({ ...selectedCheck, [type]: arrayMap }, "urgency");
  };

  const savePlanCheckStorage = async (select, selectSide) => {
    const planAllArrayCheck = [];
    const condition_id = (patientPlan || []).map((r, index) => {
      return r?.condition_id;
    });
    (condition_id || []).map((r, mix) => {
      (plans[r] || []).map((item, index) => {
        if (item && item.entity_id) {
          planAllArrayCheck.push({
            id: item?.id || null,
            entity_id: item?.entity_id,
            hpi_condition_id: item?.hpi_condition_id,
            processed: item?.processed,
            has_side: item?.has_side,
            frequency: item?.prescription?.frequency || "qdaily",
            showSide: item?.showSide,
            type: item?.bundle,
            urgency: item?.urgency,
            note: item?.note,
          });
        } else {
          planAllArrayCheck.push({
            id: idPlan[r][index],
            entity_id: item,
            type: plansType[r][index],
            side: selectSide ? selectSide && selectSide[r] && selectSide[r][index] : sideHandle && sideHandle[r] && sideHandle[r][index],
            hpi_condition_id: r,
            processed: processHandle[r][index] ? processHandle[r][index] : 0,
            urgency: select ? select && select[r] && select[r][index] : selectedCheck && selectedCheck[r] && selectedCheck[r][index],
            note: textNotes && textNotes[r] ? textNotes[r][index] : null,
          });
        }
      });
    });
  };

  const onChangeSelectSide = (value, index, type) => {
    const arrayMap = (sideHandle[type] || []).map((r, i) => {
      if (index === i) {
        return value;
      }
      return r;
    });
    setSideHandle({ ...sideHandle, [type]: arrayMap });
    savePlanCheckStorage(null, { ...sideHandle, [type]: arrayMap });
    handleChangeSelect({ ...sideHandle, [type]: arrayMap }, "side");
  };

  const handleSearchPlan = (search, type) => {
    if (search) {
      if (type == "isPrescription") {
        setLoading(true);
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(searchMedications(search));
        }, 500);
        setLoading(false);
      } else if (type == "isGeneral") {
        const generalPlans = _.filter(all_plans, (plan) => {
          return ["general", "other"].includes(plan.type);
        });
        const filteredPlans_ =
          generalPlans && generalPlans.filter((plan) => plan?.name.toLowerCase().includes(search.toLowerCase()) && search.length > 0);
        setFilteredPlans(filteredPlans_);
        setLoading(false);
      } else {
        setLoading(true);
        const normalPlans = _.filter(all_plans, (plan) => {
          return !["general"].includes(plan.type);
        });
        const filteredPlans_ =
          normalPlans && normalPlans.filter((plan) => plan?.name.toLowerCase().includes(search.toLowerCase()) && search.length > 0);
        setFilteredPlans(filteredPlans_);
        setLoading(false);
      }
    }
  };
  const selectItemPlan = async (planName, planId, planType, type, hasSide, isPrescription, keyIdPlan, selectedAll) => {
    const checkInSideHaveMedication = (dataPatientMedication || []).some((r) => {
      const splitState = r?.medication?.drug_name ? r?.medication?.drug_name.toLowerCase().split(" ")[0] : null;
      const planNameState = planName ? planName.toLowerCase().split(" ")[0] : null;
      return splitState == planNameState;
    });
    if (!checkInSideHaveMedication) {
      if (isPrescription) {
        const checkSide = [...planNamesNoSide[`${keyIdPlan}`]];
        checkSide.push({
          bundle: "prescription",
          entity_id: selectedAll["item"]["id"],
          id: null,
          hpi_condition_id: keyIdPlan,
          has_side: false,
          showSide: false,
          processed: 0,
          medication_id: selectedAll["item"]["frequency"] || "qdaily",
          prescription: {
            frequency: selectedAll["item"]["frequency"] || "qdaily",
            medication: {
              drug_name: selectedAll["item"]["drug_name"],
              form: selectedAll["item"]["form"],
              full_medication: planName,
              id: selectedAll["item"]["id"],
              is_active: selectedAll["item"]["is_active"],
              strength: selectedAll["item"]["strength"],
              route: selectedAll["item"]["route"],
            },
          },
        });
        setPlanNamesNoSide({
          ...planNamesNoSide,
          [`${keyIdPlan}`]: checkSide,
        });
        setPlans({
          ...plans,
          [`${keyIdPlan}`]: checkSide,
        });
        const temp_plans = { ...plans, [`${keyIdPlan}`]: checkSide };

        const planAllArrayCheck = [];
        typeAllPlanId.map((r, mix) => {
          (temp_plans[r] || []).map((item, index) => {
            if (item && item.entity_id) {
              planAllArrayCheck.push({
                id: item?.id || null,
                entity_id: item?.entity_id,
                hpi_condition_id: item?.hpi_condition_id,
                processed: item?.processed,
                has_side: item?.has_side,
                frequency: item?.prescription?.frequency || "qdaily",
                showSide: item?.showSide,
                type: item?.bundle,
                urgency: item?.urgency,
                note: item?.note,
              });
            } else {
              planAllArrayCheck.push({
                id: idPlan[r][index],
                entity_id: item,
                type: plansType[r][index],
                side: sideHandle && sideHandle[r] && sideHandle[r][index],
                hpi_condition_id: r,
                processed: processHandle[r][index] ? processHandle[r][index] : 0,
                urgency: selectedCheck && selectedCheck[r] && selectedCheck[r][index],
                showSide: hasSideHandle && hasSideHandle[r] ? hasSideHandle[r][index] : null,
                note: textNotes && textNotes[r] ? textNotes[r][index] : null,
              });
            }
          });
        });

        if (planAllArrayCheck && planAllArrayCheck.length > 0) {
          const param = {
            patient_id: current_patient_id,
            procedure_id: current_appointment_id,
            plans: planAllArrayCheck,
            medications: dataMedication.map((item) => {
              return {
                id: item?.id || null,
                entity_id: item?.entity_id,
                hpi_condition_id: item?.hpi_condition_id,
                processed: item?.processed,
                has_side: item?.has_side,
                showSide: item?.showSide,
                type: item?.bundle,
              };
            }),
            plan_notes: HPICheck.map((r) => {
              return {
                ...r,
                note: RemoveN(r?.note || ""),
              };
            }),
          };
          const isUrgencySet = planAllArrayCheck.every((item) => {
            if (item.type != "general" && item.type != "prescription") {
              return item.urgency;
            }

            return true;
          });
          if (!isUrgencySet) {
            return false;
          }

          await dispatch(save_params_plan(param));
          const dataGet = await dispatch(save_plan_patient(param));
          if (dataGet && Array.isArray(dataGet)) {
            setPatientPlan([...dataGet]);
          }
        }
      } else {
        if (planName === "") return;
        const lengthArray = plansType[type].length + 1;
        let listItems = [...selectedCheck[type]];
        listItems.splice(lengthArray, 0, undefined);
        const selectedCheckSave = { ...selectedCheck, [type]: listItems };
        await setSelectedCheck({ ...selectedCheck, [type]: listItems });
        //
        listItems = [...processHandle[type]];
        listItems.splice(lengthArray, 0, undefined);
        const processHandleCheck = { ...processHandle, [type]: listItems };
        await setProcessHandle({ ...processHandle, [type]: listItems });
        //
        listItems = [...processSave[type]];
        listItems.splice(lengthArray, 0, undefined);
        await setProcessSave({ ...processSave, [type]: listItems });
        //
        listItems = [...sideHandle[type]];
        listItems.splice(lengthArray, 0, undefined);
        const sideHandleCheck = { ...sideHandle, [type]: listItems };
        await setSideHandle({ ...sideHandle, [type]: listItems });
        //
        listItems = [...textNotes[type]];
        listItems.splice(lengthArray, 0, undefined);
        const textNoteHandleCheck = { ...textNotes, [type]: listItems };
        await setTextNotes({ ...textNotes, [type]: listItems });
        //
        listItems = [...hasSideHandle[type]];
        listItems.splice(lengthArray, 0, hasSide);
        await setHasSideHandle({ ...hasSideHandle, [type]: listItems });
        //
        const typeItems = [...plansType[type]];
        typeItems.splice(lengthArray, 1, planType);
        const planTypeCheck = { ...plansType, [type]: typeItems } || {};
        await setPlansType({ ...plansType, [type]: typeItems });
        listItems = [...plans[type]];
        listItems.splice(lengthArray, 0, planId);
        const planCheck = { ...plans, [type]: listItems } || {};
        await setPlans({ ...plans, [type]: listItems });
        listItems = [...planNames[type]];
        listItems.splice(lengthArray, 0, planName);
        await setPlanNames({ ...planNames, [type]: listItems });
        listItems = [...planNamesNoSide[type]];
        listItems.splice(lengthArray, 0, planName);
        await setPlanNamesNoSide({ ...planNamesNoSide, [type]: listItems });
        const planAllArrayCheck = [];
        const condition_id = (patientPlan || []).map((r, index) => {
          return r?.condition_id;
        });
        (condition_id || []).map((r) => {
          (planCheck[r] || []).map((item, index) => {
            if (item && item.entity_id) {
              planAllArrayCheck.push({
                id: item?.id || null,
                entity_id: item?.entity_id,
                hpi_condition_id: item?.hpi_condition_id,
                processed: item?.processed,
                has_side: item?.has_side,
                frequency: item?.prescription?.frequency || "qdaily",
                showSide: item?.showSide,
                type: item?.bundle,
                urgency: item?.urgency,
                note: item?.note,
              });
            } else {
              planAllArrayCheck.push({
                id: idPlan[r][index],
                entity_id: item,
                type: planTypeCheck[r][index],
                side: sideHandleCheck && sideHandleCheck[r] && sideHandleCheck[r][index],
                hpi_condition_id: r,
                processed: processHandleCheck[r][index] ? processHandleCheck[r][index] : 0,
                urgency: selectedCheckSave && selectedCheckSave[r] && selectedCheckSave[r][index],
                note: textNoteHandleCheck && textNoteHandleCheck[r] ? textNoteHandleCheck[r][index] : null,
              });
            }
          });
        });
        setTypeAllPlanId(condition_id);
        const isUrgencySet = planAllArrayCheck.every((item) => item.urgency);

        if (!isUrgencySet) {
          return false;
        }

        const param = {
          patient_id: current_patient_id,
          procedure_id: current_appointment_id,
          plans: planAllArrayCheck,
          plan_notes: HPICheck.map((r) => {
            return {
              ...r,
              note: RemoveN(r?.note || ""),
            };
          }),
        };
        await dispatch(save_params_plan(param));
        const dataGet = await dispatch(save_plan_patient(param));
        if (dataGet && Array.isArray(dataGet)) {
          setPatientPlan([...dataGet]);
        }
      }
    } else {
      dispatch(
        setGlobalToastr({
          header: "Alert",
          type: "failed",
          message: "Patient is already on this medication!",
        })
      );
    }
  };

  const removeRowPlan = async (plan, index, type, isPrescription, indexAll) => {
    dispatch(removeProviderForPlan());
    setLoading(true);
    const condition_id = (patientPlan || []).map((r, index) => {
      return r?.condition_id;
    });
    let listItems = [...idPlan[type]];
    const plan_id = listItems[index];
    listItems.splice(index, 1);
    setIDPlan({ ...idPlan, [type]: listItems });
    listItems = [...plans[type]];
    listItems.splice(index, 1);
    setPlans({ ...plans, [type]: listItems });
    //
    listItems = [...hasSideHandle[type]];
    listItems.splice(index, 1);
    setHasSideHandle({ ...hasSideHandle, [type]: listItems });
    //
    listItems = [...sideHandle[type]];
    listItems.splice(index, 1);
    setSideHandle({ ...sideHandle, [type]: listItems });
    //
    listItems = [...planNames[type]];
    listItems.splice(index, 1);
    setPlanNames({ ...planNames, [type]: listItems });
    listItems = [...planNamesNoSide[type]];
    listItems.splice(index, 1);
    if (isPrescription) {
      setDataMedication(listItems);
    }
    setPlanNamesNoSide({ ...planNamesNoSide, [type]: listItems });
    listItems = [...plansType[type]];
    listItems.splice(index, 1);
    setPlansType({ ...plansType, [type]: listItems });
    //
    if (patientPlan[indexAll]["plan"] && patientPlan[indexAll]["plan"].length > 0) {
      patientPlan[indexAll]["plan"].splice(index, 1);
      setPatientPlan([...patientPlan]);
    }
    if (plan_id) {
      const deleteSuccess = await dispatch(delete_plan(plan_id));
      if (listItems.length == 0) {
        setActiveSide(false);
      }
      if (deleteSuccess) {
        setIsDeleteToastr(true);
        setTimeout(() => {
          setIsDeleteToastr(false);
        }, 2000);
      }
    }
    setTypeAllPlanId(condition_id);
    setLoading(false);
  };

  const savePlan = async (force) => {
    setLoading(true);
    dispatch(setGlobalLoading(true));
    const planAllArrayCheck = [];
    typeAllPlanId.map((r, mix) => {
      (plans[r] || []).map((item, index) => {
        if (item && item.entity_id) {
          planAllArrayCheck.push({
            id: item?.id || null,
            entity_id: item?.entity_id,
            hpi_condition_id: item?.hpi_condition_id,
            processed: item?.processed,
            has_side: item?.has_side,
            frequency: item?.prescription?.frequency,
            showSide: item?.showSide,
            type: item?.bundle,
            note: item?.note,
            force: force ? 1 : 0,
          });
        } else {
          planAllArrayCheck.push({
            id: idPlan[r][index],
            entity_id: item,
            type: plansType[r][index],
            side: sideHandle && sideHandle[r] && sideHandle[r][index],
            hpi_condition_id: r,
            processed: processHandle[r][index] ? processHandle[r][index] : 0,
            urgency: selectedCheck && selectedCheck[r] && selectedCheck[r][index],
            showSide: hasSideHandle && hasSideHandle[r] ? hasSideHandle[r][index] : null,
            note: textNotes && textNotes[r] ? textNotes[r][index] : null,
            force: force ? 1 : 0,
          });
        }
      });
    });
    const checkPlanTrue = [
      { value: "side", type: "default" },
      { value: "urgency", type: "default" },
    ];
    const checkPlanFalse = [{ value: "urgency", type: "default" }];

    if (planAllArrayCheck && planAllArrayCheck.length > 0 && planAllArrayCheck?.some((r) => r?.processed == false)) {
      // fix check process
      const check = planAllArrayCheck.map((item, index) => {
        if (
          item.showSide === false &&
          item.type !== "general" &&
          item.type !== "other" &&
          item.type !== "prescription" &&
          item.type !== "referral" &&
          item.type !== "ct"
        ) {
          if (
            !validateSteps(
              {
                urgency: item.urgency,
              },
              checkPlanFalse
            )
          ) {
            setEnableError(true);
            return false;
          } else {
            return true;
          }
        } else if (
          item.type !== "general" &&
          item.type !== "other" &&
          item.type !== "prescription" &&
          item.type !== "referral" &&
          item.type !== "ct"
        ) {
          if (
            !validateSteps(
              {
                urgency: item.urgency,
                side: item.side,
              },
              checkPlanTrue
            )
          ) {
            setEnableError(true);
            return false;
          } else {
            return true;
          }
        } else if (item.type == "other") {
          if (!item?.urgency) {
            return false;
          } else {
            return true;
          }
        } else if (item.type == "prescription" && !item?.frequency) {
          setEnableError(true);
          return false;
        } else if (item.type == "prescription" && item.frequency) {
          return true;
        } else {
          return true;
        }
      });
      if (check.some((r) => r == false)) {
        setLoading(false);
        dispatch(setGlobalLoading(false));
        dispatch(
          setGlobalToastr({
            header: "Alert!",
            message: "please chose all require field to process order",
            type: "failed",
          })
        );
      } else {
        const param = {
          patient_id: current_patient_id,
          procedure_id: current_appointment_id,
          plans: planAllArrayCheck,
          medications: dataMedication.map((item) => {
            return {
              id: item?.id || null,
              entity_id: item?.entity_id,
              hpi_condition_id: item?.hpi_condition_id,
              processed: item?.processed,
              has_side: item?.has_side,
              showSide: item?.showSide,
              type: item?.bundle,
            };
          }),
          plan_notes: HPICheck.map((r) => {
            return {
              ...r,
              note: RemoveN(r?.note || ""),
            };
          }),
        };
        const saveSuccess = await dispatch(save_plan_patient(param));
        if (saveSuccess && Array.isArray(saveSuccess)) {
          setPatientPlan([...saveSuccess]);
          await dispatch(save_params_plan(param));
          let block = false;
          (saveSuccess || []).map((r, index) => {
            (r.plan || []).map((item, itemIndex) => {
              if (item.processed == 0 && !block) {
                setActiveRow({ [r.condition_id]: itemIndex });
                setKeyCheck(r?.condition_id);
                setTypePlanId(r?.condition_id);
                setCurrentPlanId(item?.id);
                setProcedure_id_row(item.entity_id);
                block = true;
              }
            });
          });
          setActiveSide(true);
          setLoading(false);
          setIsToastr(true);
          setIsSlidebar(true);
          setTimeout(() => {
            setIsToastr(false);
            dispatch(setGlobalLoading(false));
          }, 2000);
        } else {
          dispatch(setGlobalLoading(false));
        }
      }
    } else {
      const param = {
        patient_id: current_patient_id,
        procedure_id: current_appointment_id,
        plans: planAllArrayCheck,
        medications: dataMedication.map((item) => {
          return {
            id: item?.id || null,
            entity_id: item?.entity_id,
            hpi_condition_id: item?.hpi_condition_id,
            processed: item?.processed,
            has_side: item?.has_side,
            showSide: item?.showSide,
            type: item?.bundle,
          };
        }),
        plan_notes: HPICheck.map((r) => {
          return {
            ...r,
            note: RemoveN(r?.note || ""),
          };
        }),
      };
      await dispatch(save_plan_patient(param));
      setLoading(false);
      dispatch(setGlobalLoading(false));
      setOffSide(false);
      if (navigateToReview) {
        await dispatch(clinicProviderReviewLoadInit(current_appointment_id, 0));
        navigateToReview();
      }
    }
  };

  const onclickRow = (row, index, type, rowAll, allPlan) => {
    if (allPlan[index] && allPlan[index]["id"]) {
      setCurrentPlanId(allPlan[index]["id"]);
    }
    if (rowAll.processCheck || rowAll.processCheck == 1) {
      setActiveSide(true);
    } else {
      setActiveSide(false);
    }
    setKeyCheck(type);
    setActiveRow({ [type]: index });
    setTypePlanId(type);
  };

  const nextSave = async (plansCheck) => {
    let block = false;
    /*
    If block is still false that means the user has skipped all of the current plan in current condition
    then we increase the condition index and reset the current index
     */
    if (block === false) {
      let temp = 0;
      if (plansCheck && plansCheck.length > 0) {
        plansCheck.map((r, index) => {
          if (plansCheck[index]) {
            if ((plansCheck[index].plan || []).some((t) => t.processed === 0)) {
            } else {
              temp = index + 1;
            }
          }
          (r.plan || []).map((item, itemIndex) => {
            if (item.processed === 0 && !block) {
              setProcedure_id_row(item?.entity_id);
            }
            if (item.processed === 0 && !block && temp === index) {
              setActiveRow({ [r.condition_id]: itemIndex });
              setKeyCheck(r.condition_id);
              setTypePlanId(r?.condition_id);
              setCurrentPlanId(item?.id);
              block = true;
            }
          });
        });
      } else {
        patientPlan.map((r, index) => {
          if (patientPlan[index]) {
            if ((patientPlan[index].plan || []).some((t) => t.processed === 0)) {
            } else {
              temp = index + 1;
            }
          }
          (r.plan || []).map((item, itemIndex) => {
            if (item.processed === 0 && !block) {
              setProcedure_id_row(item?.entity_id);
            }
            if (item.processed === 0 && !block && temp === index) {
              setActiveRow({ [r.condition_id]: itemIndex });
              setKeyCheck(r.condition_id);
              setTypePlanId(r?.condition_id);
              setCurrentPlanId(item?.id);
              block = true;
            }
          });
        });
      }
    }

    if (block === false) {
      setOffSide(false);
      if (navigateToReview) {
        await dispatch(clinicProviderReviewLoadInit(current_appointment_id, 0));
        navigateToReview();
      }
    }
  };

  const handleSubmit = () => {};
  const initialSchedule = () => {
    dispatch(setStepData("initialLoading", true));
    dispatch(getAppointments(current_patient_id));
  };

  const handleSchedule = (location_id, provider_id, period, prev, idxOfSchedule, action = "", custom, load_next) => {
    const loadingOfSchedules = [...scheduleLoading];
    if (action === "row" && idxOfSchedule >= loadingOfSchedules.length - 1) {
      return;
    }
    if (action === "row") {
      loadingOfSchedules[idxOfSchedule + 1] = true;
    } else {
      loadingOfSchedules[idxOfSchedule] = true;
    }
    dispatch(setStepData("scheduleLoading", loadingOfSchedules));
    dispatch(filterAppointments(current_patient_id, location_id, provider_id, period, prev, idxOfSchedule, action, custom, load_next));
  };

  const handleScheduleOption = (idxOfSchedule, newOption) => {
    const newScheduleOption = [...scheduleOptions];
    newScheduleOption[idxOfSchedule] = newOption;
    dispatch(setStepData("scheduleOptions", newScheduleOption));
  };

  const handleProviderSchedule = async (location_id, provider_id, idxOfSchedule) => {
    if (location_id && provider_id) {
      return;
    }
    const newOption = [...dropdownOption];
    if (!location_id && !provider_id) {
      newOption[idxOfSchedule] = { type: "", list: [] };
      dispatch(setStepData("dropdownOption", newOption));
      return;
    }
    try {
      const result = await getProviderSchedule(current_patient_id, location_id, provider_id);
      let type = "";
      if (!location_id) {
        type = "location";
      } else if (!provider_id) {
        type = "provider";
      }
      newOption[idxOfSchedule] = { type, list: result.data };
      dispatch(setStepData("dropdownOption", newOption));
    } catch (error) {
      dispatch(setStepData("dropdownOption", dropdownOption));
    }
  };

  const handleChange = (type, value, index, row, keyIdPlan) => {
    const checkSide = [...planNamesNoSide[`${keyIdPlan}`]];

    if (checkSide) {
      if (type === "frequency") {
        checkSide.splice(index, 1, {
          ...checkSide[index],
          prescription: {
            ...checkSide[index]["prescription"],
            frequency: value,
          },
        });
      } else if (type === "strength" || type === "form" || type === "route") {
        checkSide.splice(index, 1, {
          ...checkSide[index],
          prescription: {
            ...checkSide[index]["prescription"],
            medication: {
              ...checkSide[index]["prescription"]["medication"],
              [type]: value,
            },
          },
        });
      }
      setPlanNamesNoSide({
        ...planNamesNoSide,
        [`${keyIdPlan}`]: checkSide,
      });
      setPlanNames({
        ...planNames,
        [`${keyIdPlan}`]: checkSide,
      });
      setPlans({
        ...plans,
        [`${keyIdPlan}`]: checkSide,
      });
    }
  };

  let conditionClassName = "";
  for (const condition_ of patientPlan) {
    conditionClassName += _.snakeCase(condition_["condition_name"]) + " ";
  }

  const sidebarRef = useRef();

  const onChangeNote = async (value, index) => {
    await dispatch(setGlobalLoading(true));

    const HPICheckMix = [...HPICheck];
    if (HPICheckMix && HPICheckMix[index]) {
      HPICheckMix[index]["note"] = value;
    }

    setHPICheck([...HPICheckMix]);
    const planAllArrayCheck = [];
    const condition_id = (patientPlan || []).map((r, index) => {
      return r?.condition_id;
    });

    (condition_id || []).map((r, mix) => {
      (plans[r] || []).map((item, index) => {
        if (item && item.entity_id) {
          planAllArrayCheck.push({
            id: item?.id || null,
            entity_id: item?.entity_id,
            hpi_condition_id: item?.hpi_condition_id,
            processed: item?.processed,
            has_side: item?.has_side,
            frequency: item?.prescription?.frequency || "qdaily",
            showSide: item?.showSide,
            type: item?.bundle,
            urgency: item?.urgency,
          });
        } else {
          planAllArrayCheck.push({
            id: idPlan[r][index],
            entity_id: item,
            type: plansType[r][index],
            side: sideHandle && sideHandle[r] && sideHandle[r][index],
            hpi_condition_id: r,
            processed: processHandle[r][index] ? processHandle[r][index] : 0,
            urgency: selectedCheck && selectedCheck[r] && selectedCheck[r][index],
          });
        }
      });
    });

    const param = {
      medications: dataMedication.map((item) => {
        return {
          id: item?.id || null,
          entity_id: item?.entity_id,
          hpi_condition_id: item?.hpi_condition_id,
          processed: item?.processed,
          has_side: item?.has_side,
          showSide: item?.showSide,
          type: item?.bundle,
        };
      }),
      patient_id: current_patient_id,
      procedure_id: current_appointment_id,
      plans: planAllArrayCheck,
      plan_notes: HPICheck.map((r) => {
        return {
          ...r,
          note: RemoveN(r?.note || ""),
        };
      }),
    };
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(save_params_plan(param));
      await dispatch(setGlobalLoading(false));
    }, 500);
  };

  const onChangeTextNote = async (value, index, row) => {
    // TODO MIKE
    const arrayMap = (textNotes[row?.keyIdPlan] || []).map((r, i) => {
      if (index === i) {
        return value;
      }
      return r;
    });
    setTextNotes({ ...textNotes, [row?.keyIdPlan]: arrayMap });
  };

  const handleUpdateStateFrenquency = (value, plans) => {
    if (planNames && planNames[typePlanId] && planNames[typePlanId][activeRow[typePlanId]] && plans) {
      const idCheck = planNames[typePlanId].findIndex((r) => r?.prescription?.id === value?.prescription?.id);
      const indexPlans = plans.findIndex((r) => r?.condition_id === typePlanId);
      const arrPlans = [...plans];
      //
      const arrFix = { ...planNames };
      const insertData = [...arrFix[typePlanId]];

      if (idCheck !== -1) {
        insertData.splice(idCheck, 1, value);
        setPlanNames({
          ...planNames,
          [typePlanId]: insertData,
        });
        if (indexPlans !== -1 && arrPlans[indexPlans]) {
          arrPlans.splice(indexPlans, 1, {
            condition_id: typePlanId,
            condition_name: "Prescription",
            plan: insertData,
            plan_note: arrPlans[indexPlans]["plan_note"] || "",
          });
          setPatientPlan([...arrPlans]);
        }
      }
    }
  };

  return (
    <div className="clinicWorkflow__main-section">
      {isToastr && <ToastMessage type="Plan/Orders" status="success" msg="Process Orders Successfully!" />}
      {isDeleteToastr && <ToastMessage type="Plan/Orders" status="success" msg="Plan is deleted successfully!" />}

      <div className="eventsContainer has-sub-header">
        <div className="patientContainer">
          <div className={`imageSection disease-icon ${_.snakeCase("")}-clicked ${conditionClassName}`}>
            <DiseaseBody />
          </div>
          <div className="detailSection">
            <ScrollWrapper css="no-padding-plan x-hidden-plan">
              {(patientPlan || []).map((item, index) => {
                return (
                  <React.Fragment key={`${item?.name}-${index}`}>
                    <div className="detailTitle" style={{ marginTop: index > 0 ? 30 : 0 }}>
                      {`${item?.condition_name}`}
                    </div>
                    <AutoCompleteComponentPlanOder
                      indexItem={index}
                      isMarginTop={true}
                      HPICheck={HPICheck}
                      onChangeNote={onChangeNote}
                      onChangeSelect={(value, index, type) => {
                        onChangeSelect(value, index, type);
                      }}
                      placeholder={item?.condition_name == "Prescription" ? "Search Medications Name" : "Search Plans"}
                      isGeneral={item?.condition_name == "General" ? true : false}
                      isPrescription={item?.condition_name == "Prescription" ? true : false}
                      onChangeSelectSide={(value, index, type) => {
                        onChangeSelectSide(value, index, type);
                      }}
                      onChangeTextNote={(value, index, type) => {
                        onChangeTextNote(value, index, type);
                      }}
                      enableError={enableError}
                      processArray={processHandle}
                      processSave={processSave}
                      textNotes={textNotes}
                      valueSelected={selectedCheck}
                      handleValueSelect={plansType}
                      sideHandle={sideHandle}
                      isSelectRow={true}
                      title="condition-plans"
                      keyLabel={item?.condition_name == "Prescription" ? "full_medication" : "name"}
                      keyValue={"id"}
                      onclickRow={(row, index, type, rowAll, allPlan) => {
                        onclickRow(row, index, type, rowAll, allPlan);
                      }}
                      handleChange={handleChange}
                      keyCheck={keyCheck}
                      arrCheck={(item && item["plan"] && item["plan"]) || []}
                      keyType={"type"}
                      keyIdPlan={item?.condition_id}
                      activeIndex={activeRow[`${item?.condition_id}`]}
                      isResult={false}
                      loading={loading}
                      disableSearchIcon={false}
                      handleSearch={handleSearchPlan}
                      selectItem={selectItemPlan}
                      removeItem={(plan, indexCheck, type, isPrescription) => {
                        removeRowPlan(plan, indexCheck, type, isPrescription, index);
                      }}
                      rows={planNamesNoSide}
                      options={item?.condition_name == "Prescription" ? dataMedications : filteredPlans}
                      isWhite={false}
                      hasSideHandle={hasSideHandle}
                      isLocked={is_locked}
                    />
                  </React.Fragment>
                );
              })}
            </ScrollWrapper>
          </div>
        </div>
      </div>
      {offSide && (
        <React.Fragment>
          {plansType && plansType[typePlanId] && plansType[typePlanId].length > 0 && plansType[typePlanId][activeRow[typePlanId]] && activeSide ? (
            <Sidebar
              ref={sidebarRef}
              planTypes={plansType}
              plans={patientPlan}
              planNames={planNames}
              sidebarIcon={sidebarIcon}
              typePlanId={typePlanId}
              activeRow={(activeRow[typePlanId] && activeRow[typePlanId]) || 0}
              titlePlan={planNames && planNames[typePlanId] && planNames[typePlanId][activeRow[typePlanId]]}
              handleUpdateStateFrenquency={handleUpdateStateFrenquency}
              changeSetProcessHandle={changeSetProcessHandle}
              onButton={onButton}
              handleLoading={setLoading}
              handleCloseSidebar={handleCloseSidebar}
              isShow={isSidebar}
              nextSave={nextSave}
              procedure_id_row={procedure_id_row}
              handleContinue={handleSubmit}
              filteredLocations={filteredLocations || locations}
              filteredProviders={filteredProviders || providers}
              conditions={initialCondition}
              referredProviders={referredProviders}
              referralConditions={referralConditions}
              pcpList={(pcpList && pcpList["active_pcp"]) || []}
              handleAppointmentSchedule={handleSchedule}
              initialAppointmentSchedule={initialSchedule}
              handleProviderSchedule={handleProviderSchedule}
              handleScheduleOption={handleScheduleOption}
              currentPlanId={currentPlanId}
              is_locked={!!is_locked}
            />
          ) : (
            <EmptySidebar
              processOrders={savePlan}
              ref={sidebarRef}
              is_locked={!!is_locked}
              isShow={isSidebar}
              handleCloseSidebar={handleCloseSidebar}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default PlanOrders;
