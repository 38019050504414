import { Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import ToastMessage from "../../../common/components/Toast";
import { addProcedureDetails, edit_clinic_procedure_type, getProviders, setProcedureDetails } from "../../../store/actions";

const ProcedureTimes = [
  { value: 15, label: "15 mins" },
  { value: 30, label: "30 mins" },
  { value: 60, label: "60 mins" },
  { value: 90, label: "90 mins" },
  { value: 120, label: "120 mins" },
  { value: 150, label: "150 mins" },
  { value: 180, label: "180 mins" },
  { value: 210, label: "210 mins" },
  { value: 240, label: "240 mins" },
  { value: 270, label: "270 mins" },
  { value: 300, label: "300 mins" },
];

const ProcedureProviders = ({ resource }) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [isToastr, setIsToastr] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const providers = useSelector((state) => state.common.providers);
  const providersState = useSelector((state) => state.procedureDetail.providers_clinic);
  const detail_clinic_type = useSelector((state) => state.procedureDetail.detail_clinic_type);

  const updateProviders = async (type, index, value) => {
    if (!providersState.some((r) => r.physician === value?.id)) {
      if (type === "duration") {
        const dataCheck = [...providersState];
        dataCheck[index][type] = value;
        dispatch(setProcedureDetails("providers_clinic", [...dataCheck]));
        callApiUpdate([...dataCheck]);
      } else {
        await dispatch(
          addProcedureDetails("providers_clinic", {
            physician_name: value?.value,
            physician: value?.id,
            duration: null,
          })
        );
        callApiUpdate([
          ...providersState,
          {
            physician_name: value?.value,
            physician: value?.id,
            duration: null,
          },
        ]);
      }
    } else {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const removeProvider = async (index) => {
    providersState.splice(index, 1);
    await dispatch(setProcedureDetails("providers_clinic", [...providersState]));
    callApiUpdate([...providersState]);
  };

  useEffect(() => {
    if (detail_clinic_type) {
      const providerDetail = (detail_clinic_type.providers || []).map((r) => {
        return {
          physician: r?.id,
          physician_name: r?.value,
          duration: r?.pivot && r?.pivot?.interval === 0 ? undefined : r?.pivot?.interval || null,
        };
      });
      dispatch(setProcedureDetails("providers_clinic", [...providerDetail]));
    }
  }, [detail_clinic_type]);

  useEffect(() => {
    if (search && search.length > 0) {
      const checkfilter = providers.filter((r) => r?.value.toLowerCase().includes(search.toLowerCase()));
      setSearchResult(checkfilter);
    } else {
      setSearchResult(providers);
    }
  }, [search]);

  useEffect(() => {
    if (providers) {
      setSearchResult(providers);
    }
  }, [providers]);

  useEffect(() => {
    // dispatch(getProviders());
  }, []);

  const callApiUpdate = async (value) => {
    if (providersState && detail_clinic_type && Object.keys(detail_clinic_type).length > 0) {
      const renderArray = value.map((r) => {
        return `"${r.physician}":{"interval":${r.duration}}`;
      });
      await dispatch(
        edit_clinic_procedure_type(detail_clinic_type?.id, {
          name: detail_clinic_type?.name,
          providers: `{${renderArray.join()}}`,
        })
      );
    }
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isToastr && <ToastMessage type="Provider Already Exists" status="failed" msg="Can Not Add Same Value!" />}
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          isLargeHeight={true}
          title="Procedure-Provider-Search"
          options={searchResult}
          handlePopulate={(value) => updateProviders("physician", providersState.length, value)}
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Providers"
          keyLabel="value"
          actionLabel="Add"
        />
      </div>
      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="procedure_provider"
          labels={["Provider", "Duration"]}
          widths={["50%", "50%"]}
          fullHeight={true}
          columns={[
            { sortable: true, key: "provider" },
            { sortable: true, key: "duration" },
          ]}
          parentHeight={tableRef && tableRef.current && tableRef.current.clientHeight}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(providersState || []).map((item, index) => {
            return {
              provider: item.physician_name,
              duration: (
                <Select
                  placeholder="Duration"
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  value={item.duration}
                  onChange={(value) => {
                    updateProviders("duration", index, value);
                  }}
                  style={{ width: "100%", borderBottom: 0 }}
                >
                  {ProcedureTimes.map((r) => (
                    <Select.Option key={r?.value} value={r?.value}>
                      {r.label}
                    </Select.Option>
                  ))}
                </Select>
              ),
            };
          })}
          emptyRows={new Array(4).fill(1)}
          sorts={[""]}
        />
      </div>
    </div>
  );
};

export default ProcedureProviders;
