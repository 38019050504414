import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  choosePatientOnSchedules,
  clinicProviderReviewLoadInit,
  getClinicNursingLocations,
  getLock,
  getPatientSchedules,
  getProviders,
  get_patient_notes,
  patient_insurances_id_insurances,
  removeDataPatient,
  remove_patient_on_schedules,
  resetStateResource,
  setDataPatient,
  setErrorHPI,
  setPatientSnapshotData,
  removeReviewUltrasound,
  setGlobalToastr,
  setActionActive,
} from "../../../../store/actions";
import DayCalendar from "../DayCalendar";
import { getLocal, setLocal } from "src/helpers/Local";

const resources = [
  {
    id: "clinic",
    title: "Clinic",
  },
  {
    id: "us",
    title: "Ultrasound",
  },
];

function ClinicSchedule(props) {
  const [isSidebar, setIsSidebar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [schedulesState, setSchedulesState] = useState([]);
  const [filters, setFilters] = useState({
    date: moment(new Date()).format("YYYY-MM-DD"),
    location: "",
    provider_id: "",
  });

  const dispatch = useDispatch();
  const locations = useSelector((state) => state.common.clinicNursingLocations);
  const providers = useSelector((state) => state.common.providers);
  const schedules = useSelector((state) => state.clinicProvider.schedules);
  const scheduleProviders = useSelector((state) => state.patientSchedule.providers);
  const loadingSchedules = useSelector((state) => state.clinicProvider.loadingSchedules);
  const loadingResource = useSelector((state) => state.clinicProvider.loadingResource);
  const activeReview = useSelector((state) => state.common.activeReview);
  const actionActive = useSelector((state) => state.common.actionActive);

  useEffect(() => {
    const { date, location } = filters;
    const localCheck = JSON.parse(getLocal("scheduleParams"));
    const scheduleFilters = {
      date: localCheck && localCheck?.date ? localCheck?.date || date : date,
      location: (localCheck && localCheck?.location) || "",
      provider_id: (localCheck && localCheck?.provider_id) || "",
    };
    // dispatch(getProviders());
    handleEvents(scheduleFilters);
    loadInitialPaymentJs();
  }, []);

  useEffect(() => {
    if (!locations || locations.length == 0) {
      dispatch(getClinicNursingLocations());
    }
  }, [locations]);

  const loadInitialPaymentJs = () => {
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleEvents = ({ date, location, provider_id }) => {
    setFilters({
      ...filters,
      date,
      location,
      provider_id,
    });
    if (!actionActive?.status) {
      dispatch(
        getPatientSchedules({
          date,
          location,
          provider_id,
          patient_id: "",
          only_clinics: 1,
          only_procedures: 0,
          only_us: 1,
          only_angio: 0,
        })
      );
    }
  };

  const onSearch = (searchValue) => {
    setSearchValue(searchValue);
    if (searchValue.length > 0) {
      const searchPatientName = (schedules || []).filter((item) => {
        return item.patientName.toLowerCase().includes(searchValue.toLowerCase());
      });
      setSchedulesState(searchPatientName);
    } else {
      setSchedulesState(schedules);
    }
  };

  const onSelectResource = async (patient_id, appointment_id) => {
    const { goNextTab } = props;
    setLocal("current_patient_id", patient_id);
    setLocal("current_appointment_id", appointment_id);

    await dispatch(
      setActionActive({
        ...actionActive,
        status: false,
      })
    );

    await dispatch(remove_patient_on_schedules());
    const locked = await dispatch(getLock(appointment_id));
    await dispatch(resetStateResource());
    if (!locked?.data?.locked) {
      const result = await dispatch(choosePatientOnSchedules(patient_id));
      if (result) {
        dispatch(setDataPatient(result));
      }
      await dispatch(removeDataPatient());
      await dispatch(get_patient_notes(patient_id));
      await dispatch(setPatientSnapshotData("resource", { ...result }));
      // await dispatch(patient_insurances_id_insurances(patient_id));
      await dispatch(removeReviewUltrasound());
      //
      dispatch(clinicProviderReviewLoadInit(appointment_id, 0));
      dispatch(setErrorHPI(false));
      goNextTab();
    } else {
      await dispatch(
        setGlobalToastr({
          header: "Clinic Workflow",
          message: "This procedure has been locked by " + locked?.data?.user,
          type: "failed",
          link: "Notify " + locked?.data?.user,
          exlink: "Force release " + locked?.data?.user,
        })
      );
    }
    //
  };

  const onSelectAppointment = (appointment_id) => {};

  useEffect(() => {
    if (activeReview) {
      if (props.goNextTabReview) {
        props.goNextTabReview();
      }
    }
    if (schedules) {
      setSchedulesState(schedules);
    }
    if (props?.stateStatusProps && schedules) {
      const indexCheck = (schedules || []).findIndex((r) => r?.id === props?.stateStatusProps?.appointment_id);
      if (indexCheck !== -1) {
        const dataFix = [...schedules];
        dataFix.splice(indexCheck, 1, {
          ...dataFix[indexCheck],
          status: props?.stateStatusProps?.status,
        });
        setSchedulesState([...dataFix]);
      }
    }
  }, [schedules, activeReview, props.stateStatusProps]);

  return (
    <div style={{ display: props.display ? "flex" : "none" }} className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="calendarContainer">
          <DayCalendar
            locations={locations || []}
            providers={providers || []}
            pusherCheckBuild={props.pusherCheckBuild}
            pusherCheckUnfinalized={props.pusherCheckUnfinalized}
            events={schedulesState || []}
            resources={resources}
            slotProviders={scheduleProviders || []}
            loading={loadingSchedules || loadingResource}
            fetchEvents={handleEvents}
            valueSearch={searchValue}
            onSearch={onSearch}
            onSelectResource={onSelectResource}
            calendarType="clinic-schedule"
            onSelectAppointment={onSelectAppointment}
            isClinicWorkFlow={props.isClinicWorkFlow}
          />
        </div>
      </div>
    </div>
  );
}

export default ClinicSchedule;
