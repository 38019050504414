import { Drawer, Popconfirm } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditableInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import YesNo from "../../../../common/components/YesNo/YesNoOne";
import { validateField } from "../../../../services/validations";
import { disableUser, enableUser, getUsers, saveDetailUsers, setGlobalLoading } from "../../../../store/actions";
import AddUserDetails from "./AddUserDetail";

const InActiveSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, usersDetail }, ref) => {
  const [isToastr, setIsToastr] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [isEnable, setIsEnable] = useState(false);
  const [popUpProvider, setPopUpProvider] = useState({});
  const [popUpAdmin, setPopUpAdmin] = useState({});
  const [isAddForm, setIsAddForm] = useState(false);
  const [salaryOption, setSalaryOption] = useState("Salary");
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  const [isReset, setIsReset] = useState(false);
  const [state, setState] = useState({});

  const dispatch = useDispatch();

  const openAddForm = () => {
    setIsAddForm(true);
  };
  const closeAddForm = () => {
    setIsAddForm(false);
  };

  const fetchState = () => {
    setState({
      id: usersDetail?.id,
      isProvider: usersDetail.is_provider === -1 ? null : usersDetail.is_provider,
      isPCM: usersDetail.is_pcm === -1 ? null : usersDetail.is_pcm,
      isAdmin: usersDetail.is_admin === -1 ? null : usersDetail.is_admin,
      isSupport: usersDetail.is_support === -1 ? null : usersDetail.is_support,
      isBilling: usersDetail.is_billing === -1 ? null : usersDetail.is_billing,
      isTechnician: usersDetail.is_technician === -1 ? null : usersDetail.is_technician,
      points: usersDetail.meta ? usersDetail.meta.points : "",
      userName: usersDetail.username,
      email: usersDetail.email,
      phone: usersDetail.phone,
      address: usersDetail.address,
      address_2: usersDetail.address_2,
      city: usersDetail.city,
      state: usersDetail.state,
      zipcode: usersDetail.zipcode,
      name: usersDetail?.name,
      login: usersDetail.username,
      salary: usersDetail.meta && usersDetail.meta.salary,
      exemptions: usersDetail.meta && usersDetail.meta.exemption,
      hours: usersDetail.vacation_hours_per_year,
      hire: usersDetail.meta && usersDetail.meta.hire_date,
      end: usersDetail.meta && usersDetail.meta.end_date,
      ops_provider_id: usersDetail.ops_provider_id,
      npi: usersDetail.npi,
      dea: usersDetail.dea,
      facility_conversion_factor: usersDetail.facility_conversion_factor,
      medicare_conversion_factor: usersDetail.medicare_conversion_factor,
    });
    setIsEnable(!!usersDetail.status);
  };

  const saveAll = async (value) => {
    dispatch(setGlobalLoading(true));
    const saveSuccess = await dispatch(
      saveDetailUsers(value?.id, {
        name: value?.name,
        username: value.login,
        password: value.password,
        salary: value.salary,
        exemption: value.exemptions,
        email: value.email,
        phone: value.phone,
        address: value.address,
        address_2: value.address_2,
        city: value.city,
        state: value.state,
        zipcode: value.zipcode,
        ssn: value.ssn,
        points: value.points,
        vacation_hours: value.hours,
        hire_date: value.hire,
        end_date: value.end,
        is_provider: value.isProvider,
        is_pcm: value.isPCM,
        is_admin: value.isAdmin,
        is_support: value.isSupport,
        is_billing: value.isBilling,
        is_technician: value.isTechnician,
        ops_provider_id: value.ops_provider_id,
        npi: value.npi,
        dea: value.dea,
        facility_conversion_factor: value.facility_conversion_factor,
        medicare_conversion_factor: value.medicare_conversion_factor,
      })
    );
    const loadListSuccess = await dispatch(getUsers());
    dispatch(setGlobalLoading(false));
    if (saveSuccess && loadListSuccess) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const changeHandler = useCallback(
    debounce((value) => saveAll(value), 1000),
    []
  );

  const handleChange = (type, value) => {
    const newValue = {
      ...state,
      [type]: value,
    };
    setState(newValue);
    if (type == "password" || type == "confirm_password") {
      if (newValue["password"] == newValue["confirm_password"]) {
        if (!isAddForm) {
          isReset && changeHandler(newValue);
        }
      }
    } else {
      if (!isAddForm) {
        isReset && changeHandler(newValue);
      }
    }
  };

  const handleChangeOption = (key, value) => {
    if (key === "isProvider") {
      handleChange("isProvider", value);
    } else if (key === "isPCM") {
      handleChange("isPCM", value);
    } else if (key === "isTechnician") {
      handleChange("isTechnician", value);
    } else if (key === "isSupport") {
      handleChange("isSupport", value);
    } else if (key === "isBilling") {
      handleChange("isBilling", value);
    } else {
      handleChange("isAdmin", value);
    }
  };

  const disableClickUser = async () => {
    if (Object.keys(usersDetail).length > 0) {
      const disableSuccess = await dispatch(disableUser(usersDetail?.id));
      const loadListSuccess = await dispatch(getUsers());
      if (disableSuccess && loadListSuccess) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          handleCloseSidebar();
          setState({});
        }, 1000);
      }
    }
  };

  const enableClickUser = async () => {
    if (Object.keys(usersDetail).length > 0) {
      const enableSuccess = await dispatch(enableUser(usersDetail?.id));
      const loadListSuccess = await dispatch(getUsers());
      if (enableSuccess && loadListSuccess) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          handleCloseSidebar();
          setState({});
        }, 1000);
      }
    }
  };

  useEffect(() => {
    setPopUpProvider({});
    setPopUpAdmin({});
    if (usersDetail && usersDetail?.id !== state?.id) {
      fetchState();
      setReset(true);
      setIsReset(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
  }, [usersDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  if (!usersDetail) {
    return null;
  }

  return (
    <Drawer
      title={!isAddForm ? usersDetail?.name : " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="User" status="success" msg="Changed Successfully!" />}
        <ScrollWrapper>
          {isAddForm && <AddUserDetails closeAddForm={closeAddForm} />}
          {!isAddForm && (
            <React.Fragment>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                  marginTop: "1.5rem",
                }}
              >
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Name"}
                    value={state?.name}
                    handleChange={(value) => {
                      handleChange("name", value);
                    }}
                    required={true}
                    isError={validateField("text", state?.name)}
                    helperText={"Name is Required!"}
                  />
                </div>
              </div>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                }}
              >
                <div className="resourceInfo d-flex align-items-end required-field" style={{ marginTop: "0.5rem" }}>
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Salary/Wage"}
                    value={salaryOption}
                    type={"check"}
                    handleChange={(value) => {
                      setSalaryOption(value);
                    }}
                    options={[
                      { value: "Salary", label: "Salary" },
                      { value: "Wage", label: "Wage" },
                    ]}
                    optionKey={"label"}
                    valueKey={"label"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={salaryOption}
                    value={state.salary}
                    prefix={"$"}
                    handleChange={(value) => {
                      handleChange("salary", value);
                    }}
                    required={true}
                    isError={validateField("text", state.salary)}
                    helperText={"Salary/Wage is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Points"}
                    value={state.points}
                    handleChange={(value) => {
                      handleChange("points", value);
                    }}
                    required={true}
                    isError={validateField("text", state.points)}
                    helperText={"Points is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Email"}
                    value={state.email}
                    handleChange={(value) => {
                      handleChange("email", value);
                    }}
                    required={true}
                    isError={validateField("email", state.email)}
                    helperText={"Email is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Phone"}
                    value={state.phone}
                    handleChange={(value) => {
                      handleChange("phone", value);
                    }}
                    required={true}
                    isError={validateField("phone", state.phone)}
                    helperText={"Phone is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Address"}
                    value={state.address}
                    handleChange={(value) => {
                      handleChange("address", value);
                    }}
                    required={true}
                    isError={validateField("text", state.address)}
                    helperText={"Address is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Address 2"}
                    value={state.address_2}
                    handleChange={(value) => {
                      handleChange("address_2", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"City"}
                    value={state.city}
                    handleChange={(value) => {
                      handleChange("city", value);
                    }}
                    required={true}
                    isError={validateField("text", state.city)}
                    helperText={"City is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"State"}
                    value={state.state}
                    handleChange={(value) => {
                      handleChange("state", value);
                    }}
                    required={true}
                    isError={validateField("text", state.state)}
                    helperText={"State is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Zipcode"}
                    value={state.zipcode}
                    handleChange={(value) => {
                      handleChange("zipcode", value);
                    }}
                    required={true}
                    isError={validateField("text", state.zipcode)}
                    helperText={"Zipcode is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"SSN"}
                    value={state.ssn}
                    handleChange={(value) => {
                      handleChange("ssn", value);
                    }}
                    required={true}
                    isError={validateField("text", state.ssn)}
                    helperText={"SSN is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Exemptions"}
                    value={state.exemptions}
                    handleChange={(value) => {
                      handleChange("exemptions", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Vacation Hours/yr"}
                    value={state.hours}
                    handleChange={(value) => {
                      handleChange("hours", value);
                    }}
                    unit={"hrs"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Hire Date"}
                    value={state.hire}
                    handleChange={(value) => {
                      handleChange("hire", value);
                    }}
                    type={"date"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"End Date"}
                    value={state.end}
                    handleChange={(value) => {
                      handleChange("end", value);
                    }}
                    type={"date"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Login"}
                    value={state.login}
                    handleChange={(value) => {
                      handleChange("login", value);
                    }}
                    required={true}
                    isError={validateField("text", state.userName)}
                    helperText={"User name is required"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Password"}
                    value={"*******"}
                    handleChange={(value) => {
                      handleChange("password", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Confirm Password"}
                    value={state.confirm_password}
                    handleChange={(value) => {
                      handleChange("confirm_password", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"OPS Provider ID"}
                    value={state.ops_provider_id}
                    handleChange={(value) => {
                      handleChange("ops_provider_id", value);
                    }}
                  />
                </div>
                {state.isProvider ? (
                  <div>
                    <div className="resourceInfo d-flex align-items-end">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"DEA"}
                        value={state.dea}
                        handleChange={(value) => {
                          handleChange("dea", value);
                        }}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"NPI"}
                        value={state.npi}
                        handleChange={(value) => {
                          handleChange("npi", value);
                        }}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"Medicare Conversion Factor"}
                        value={state.medicare_conversion_factor}
                        handleChange={(value) => {
                          handleChange("medicare_conversion_factor", value);
                        }}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"Facility Conversion Factor"}
                        value={state.facility_conversion_factor}
                        handleChange={(value) => {
                          handleChange("facility_conversion_factor", value);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Provider: </b>
                  <Popconfirm
                    placement="bottom"
                    visible={popUpProvider?.visible}
                    title={"Are you sure you want to change provider settings? Doing so will reset the schedule."}
                    onConfirm={() => {
                      handleChangeOption("isProvider", popUpProvider?.value);
                      setPopUpProvider({
                        ...popUpProvider,
                        visible: false,
                      });
                    }}
                    okText="Yes"
                    onCancel={() => {
                      setPopUpProvider({
                        ...popUpProvider,
                        visible: false,
                      });
                    }}
                    cancelText="No"
                  >
                    <YesNo
                      option={state.isProvider}
                      setOption={(value) => {
                        setPopUpAdmin({});
                        setPopUpProvider({
                          visible: true,
                          value,
                        });
                      }}
                    />
                  </Popconfirm>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Technician: </b>
                  <YesNo
                    option={state.isTechnician}
                    setOption={(value) => {
                      handleChangeOption("isTechnician", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>PCM: </b>
                  <YesNo
                    option={state.isPCM}
                    setOption={(value) => {
                      handleChangeOption("isPCM", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Admin: </b>
                  <Popconfirm
                    placement="bottom"
                    visible={popUpAdmin?.visible}
                    title={"Are you sure you want to change admin settings? Doing so will give admin right to the user"}
                    onConfirm={() => {
                      handleChangeOption("isAdmin", popUpAdmin?.value);
                      setPopUpAdmin({
                        ...popUpAdmin,
                        visible: false,
                      });
                    }}
                    okText="Yes"
                    onCancel={() => {
                      setPopUpAdmin({
                        ...popUpAdmin,
                        visible: false,
                      });
                    }}
                    cancelText="No"
                  >
                    <YesNo
                      option={state.isAdmin}
                      setOption={(value) => {
                        setPopUpProvider({});
                        setPopUpAdmin({
                          visible: true,
                          value,
                        });
                      }}
                    />
                  </Popconfirm>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Support: </b>
                  <YesNo
                    option={state.isSupport}
                    setOption={(value) => {
                      handleChangeOption("isSupport", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Billing: </b>
                  <YesNo
                    option={state.isBilling}
                    setOption={(value) => {
                      handleChangeOption("isBilling", value);
                    }}
                  />
                </div>
              </div>
              <div
                className="actionContent"
                style={{
                  marginTop: "2rem",
                  paddingRight: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <button className="outlined-btn" style={{ marginTop: "0.5rem" }} onClick={openAddForm}>
                  Add New User
                </button>
                {Object.keys(usersDetail).length > 0 && !isEnable ? (
                  <button onClick={enableClickUser} className="outlined-btn" style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}>
                    Enable User
                  </button>
                ) : (
                  <button onClick={disableClickUser} className="outlined-btn" style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}>
                    Delete User
                  </button>
                )}
              </div>
            </React.Fragment>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default InActiveSidebar;
