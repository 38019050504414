import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clinicProviderPatientSnapshotLoadInit,
  delete_clinic_provider_patient_snapshot,
  getClinicWorkflowAppointments,
  loadUltrasoundHistoryForCondition,
  patientExaminationLoadInit,
  patient_insurances_id_insurances,
  setFirstLoadedSnapshot,
  setGlobalLoading,
  setPatientSnapshotData,
  setSidebarState,
} from "../../../store/actions";
import DiseaseBody from "../../components/DiseasBody";
import "./DiseaseBody.scss";
import PatientOverviewList from "./PatientOverviewList";
import SnapshotSideBar from "./SnapSidebar";
import { getLocal } from "../../../helpers/Local";

const PatientOverview = ({ isClinic, sidebarIcon, patientResource, display, isShowGoto, hasAppointment, ...restProps }) => {
  const list = useSelector((state) => state.clinicProviderPatientSnapshot.list);
  const oldPatientResource = useSelector((state) => state.patientSnapshot.resource);
  const globalLoading = useSelector((state) => state.common.globalLoading);
  const dispatch = useDispatch();
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeDelete, setActiveDelete] = useState(false);
  const [indexDelete, setIndexDelete] = useState(-1);
  const [clickedCondition, setClickedCondition] = useState("");
  const current_patient_id = getLocal("current_patient_id");
  const appointment_id = getLocal("current_appointment_id");

  useEffect(() => {
    if (isClinic) {
      dispatch(patientExaminationLoadInit(appointment_id));
      // dispatch(
      //   getClinicWorkflowAppointments({
      //     patient_id: current_patient_id,
      //     paging: 1,
      //   })
      // );
    }
  }, [isClinic]);

  useEffect(() => {
    dispatch(setSidebarState(true));
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  const fetchApi = async () => {
    if (current_patient_id && appointment_id) {
      dispatch(clinicProviderPatientSnapshotLoadInit(current_patient_id, appointment_id));
    }
    dispatch(patient_insurances_id_insurances(current_patient_id));
  };

  useEffect(() => {
    if (current_patient_id) {
      fetchApi();
    }
  }, [current_patient_id, appointment_id]);

  useEffect(() => {
    if (patientResource) {
      if (oldPatientResource?.id !== patientResource?.id) {
        dispatch(setPatientSnapshotData("resource", { ...patientResource }));
        dispatch(setFirstLoadedSnapshot());
      }
      setIsSidebar(true);
    }
  }, [patientResource]);

  useEffect(() => {
    if (restProps && restProps.loadingResource !== undefined) {
      dispatch(setGlobalLoading(restProps.loadingResource));
    }
  }, [restProps]);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const onClickConditionPanel = async (condition) => {
    if (condition) {
      condition = condition.replace("collapse-", "");
      await dispatch(setGlobalLoading(true));
      await dispatch(loadUltrasoundHistoryForCondition(patientResource?.id, condition, appointment_id));
      await dispatch(setGlobalLoading(false));
      setClickedCondition(_.snakeCase(condition));
    } else {
      setClickedCondition("");
    }
  };

  const removeItem = async (index, row) => {
    setIndexDelete(index);
    if (!activeDelete) {
      setActiveDelete(true);
    } else {
      if (!globalLoading && indexDelete == index) {
        await dispatch(setGlobalLoading(true));
        const params = {
          condition: row?.id,
        };
        await dispatch(delete_clinic_provider_patient_snapshot(patientResource?.id, params));
        await dispatch(clinicProviderPatientSnapshotLoadInit(patientResource?.id, appointment_id));
        await dispatch(setGlobalLoading(false));
        setActiveDelete(false);
        setIndexDelete(-1);
      }
    }
  };

  let conditionClassName = "";
  for (const condition_ of list) {
    conditionClassName += _.snakeCase(condition_["condition"]) + " ";
  }

  const sidebarRef = useRef();

  return (
    <div
      style={{
        display: !display ? "flex" : display === "flex" ? "flex" : "none",
      }}
      className="clinicWorkflow__main-section"
    >
      <div className="eventsContainer has-sub-header">
        <div className="patientContainer" style={{ overflow: "scroll" }}>
          <div className={`imageSection disease-icon ${_.snakeCase(clickedCondition)}-clicked ${conditionClassName}`}>
            <DiseaseBody />
          </div>
          <div className="detailSection new-padding">
            <PatientOverviewList
              indexDelete={indexDelete}
              activeDelete={activeDelete}
              onClickConditionPanel={onClickConditionPanel}
              removeItem={removeItem}
              hasAppointment={hasAppointment}
            ></PatientOverviewList>
          </div>
        </div>
      </div>
      {
        <SnapshotSideBar
          isShowGoto={isShowGoto || false}
          patientResource={patientResource}
          sidebarIcon={sidebarIcon}
          isShow={isSidebar}
          isScan={restProps.isScan}
          handleCloseSidebar={handleCloseSidebar}
          ref={sidebarRef}
        />
      }
    </div>
  );
};

export default PatientOverview;
