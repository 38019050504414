import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import {
  adminReferralConditionGet,
  adminReferralConditionList,
  getProviders,
  getUltrasounds,
  setAdminReferralCondition,
  setGlobalLoading,
} from "../../../../store/actions";
import ConditionDetailSidebar from "./ConditionDetailSidebar";

const OldConditionDetail = ({ data, title }) => {
  const referralConditions = useSelector((state) => state.adminReferralCondition.referralConditions);
  const loading = useSelector((state) => state.adminReferralCondition.loading);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);

  const dispatch = useDispatch();

  const [isSidebar, setIsSidebar] = useState(true);
  const [activeIndex, setRow] = useState(-1);

  useEffect(() => {
    fetchData();
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, id) => async () => {
    setRow(index);
    if (activeIndex !== index) {
      dispatch(adminReferralConditionGet(id));
      handleSidebar();
    }
  };

  const fetchData = () => {
    // dispatch(getProviders());
    dispatch(adminReferralConditionList());
    dispatch(getUltrasounds());
    dispatch(setAdminReferralCondition("selectedReferralCondition", undefined));
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableHeader">
            <div className="tableItem">
              <div className="th" style={{ width: "50%", marginLeft: "2rem" }}>
                <b>Name</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div className="tableContainer">
              <ScrollWrapper css="no-padding x-hidden">
                <div className="tableList">
                  {(referralConditions || []).map((row, index) => (
                    <div
                      className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                      key={`resultTable-${title}-${index}`}
                      onClick={handleRow(index, row?.id)}
                    >
                      <div className="td with-icon" style={{ width: "50%", marginLeft: "2rem" }}>
                        <p>{row?.value}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>

      {selectedReferralCondition && activeIndex >= 0 && (
        <ConditionDetailSidebar ref={sidebarRef} isShow={isSidebar} handleCloseSidebar={handleCloseSidebar} />
      )}

      {selectedReferralCondition && activeIndex >= 0 && (
        <SidebarExtension
          startingWidth="calc(28.3% - 20px + 0.3rem)"
          sidebarReference={sidebarRef}
          sidebarToggle={toggleSidebar}
          sidebarState={isSidebar}
        />
      )}
    </div>
  );
};

export default OldConditionDetail;
