import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ClinicalCalendar from "./components/ClinicalCalendar";
import {
  getUltrasoundEvents,
  setUltrasoundData,
  getProcedureLocations,
  getOnlyProviders,
  setGlobalLoading,
  getClinicLocations,
} from "../../store/actions";

const ProcedureCalendar = (props) => {
  const events = useSelector((state) => state.ultrasound.events);
  const activeOpenings = useSelector((state) => state.ultrasound.activeOpenings);
  const closedDates = useSelector((state) => state.ultrasound.closedDates);
  const openingTime = useSelector((state) => state.ultrasound.openingTime);
  const closingTime = useSelector((state) => state.ultrasound.closingTime);
  const locations = useSelector((state) => state.common.clinicLocations);
  const providers = useSelector((state) => state.common.only_providers);
  const dispatch = useDispatch();

  const fetchApi = async (value) => {
    dispatch(setGlobalLoading(true));
    if (!value) {
    }
    dispatch(setUltrasoundData("resource", null));
    const date = moment(new Date()).format("MM/DD/YYYY");
    dispatch(setUltrasoundData("loadingEvents", true));
    await dispatch(getUltrasoundEvents(value ? value : { date }));
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    if (!locations || locations.length == 0) {
      dispatch(getClinicLocations());
    }
  }, [locations]);

  useEffect(() => {
    if (!providers || providers.length == 0) {
      dispatch(getOnlyProviders());
    }
  }, [providers]);

  const handleEvents = (date, location, provider) => {
    fetchApi({ date, location, provider });
  };

  const onClickEvent = (event) => {
    if (props.onHandleClick && event.patient_id) {
      props.onHandleClick(event);
    }
  };

  return (
    <div className="calendarContainer" style={{ position: "relative", left: 10, right: 10 }}>
      <ClinicalCalendar
        activeID={props?.activeID}
        onClickEvent={onClickEvent}
        eventData={events || []}
        fetchEvents={handleEvents}
        locations={locations || []}
        providers={providers || []}
        activeOpenings={activeOpenings || []}
        closedDates={closedDates || []}
        openingTime={openingTime || ""}
        closingTime={closingTime || ""}
      />
    </div>
  );
};

export default ProcedureCalendar;
