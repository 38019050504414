import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";

export const setGlobalLoading = createActionThunk("SET_GLOBAL_LOADING", (loading) => ({ loading }));
export const setNewLoading = createActionThunk("SET_NEW_LOADING", (loading) => {
  return loading;
});

export const save_params_plan = createActionThunk("SAVE_PARAMS_PLAN", (params) => ({ params }));
export const setDataLocalChangeRecord = createActionThunk("SET_DATA_LOCAL_CHANGE_RECORD", (params) => ({ params }));
export const setGlobalToastr = createActionThunk("SET_GLOBAL_TOASTR", (message) => message);
export const setActiveRequireDiameter = createActionThunk("SET_ACTIVE_REQUIRE_DIAMETER", (status) => ({ status }));
export const setActiveRequireIndication = createActionThunk("SET_ACTIVE_REQUIRE_INDICATION", (status) => ({ status }));
export const setActionActive = createActionThunk("SET_ACTION_ACTIVE", (status) => ({ status }));
export const removeReviewUltrasound = createActionThunk("REMOVE_REVIEW_ULTRASOUND", () => {});
export const acceptForce = createActionThunk("ACCEPT_FORCE", (status, value) => ({ status, value }));
export const goToCharting = createActionThunk("GO_TO_CHARTING", (value) => ({ value }));
export const removeStateDeleteChart = createActionThunk("REMOVE_STATE_DELETE_CHART", () => {});

export const changeActiveRow = createActionThunk(AT.CHANGE_ACTIVE_ROW, (status) => status);

export const getActiveStaff = createActionThunk(AT.GET_ACTIVE_STAFF, (query) => API.getActiveStaff(query));

export const getLocations = createActionThunk(AT.GET_LOCATIONS, () => API.getLocations());

export const get_intra_answer = createActionThunk(AT.GET_INTRA_ANSWER, (id) => API.get_intra_answer(id));

export const getLocationsStatus = createActionThunk(AT.GET_LOCATIONS_STATUS, (status) => API.getLocationsStatus(status));

export const getClinicLocations = createActionThunk(AT.GET_CLINIC_LOCATIONS, () => API.getLocations("", "clinic"));

export const getVhcLocations = createActionThunk(AT.GET_VHC_LOCATIONS, () => API.getLocations("", "vhc"));

export const getClinicNursingLocations = createActionThunk(AT.GET_CLINIC_NURSING_LOCATIONS, () => API.getLocations("", "clinic_nursing"));

export const getAssignedDocuments = createActionThunk(AT.GET_ASSIGNED_DOCUMENTS, (id) => API.getAssignedDocuments(id));

export const get_filter_patient_document = createActionThunk(AT.GET_FILTER_PATIENT_DOCUMENT, (param) => API.get_filter_patient_document(param));

export const get_sorted_patient_document = createActionThunk(AT.GET_SORTED_PATIENT_DOCUMENT, (param) => API.get_filter_patient_document(param));

export const clear_patient_documents = createActionThunk(AT.CLEAR_PATIENT_DOCUMENT, () => null);
export const setCheckRequireForReview = createActionThunk(AT.SET_CHECK_REQUIRE_FOR_REVIEW, (status) => status);

export const get_patient_document = createActionThunk(AT.GET_PATIENT_DOCUMENT, (id) => API.get_patient_document(id));

export const put_patient_document = createActionThunk(AT.PUT_PATIENT_DOCUMENT, (id, param) => API.put_patient_document(id, param));

export const setCommonData = createActionThunk(AT.SET_COMMON_DATA, (type, data) => ({ [type]: data }));

export const unlock_finalize = createActionThunk(AT.UNLOCK_FINALIZE, (id) => API.unlock_finalize(id));

export const getCurrentUser = createActionThunk(AT.GET_CURRENT_USER_LOGIN, () => API.getCurrentUserInfo());
export const getCurrentPatient = createActionThunk(AT.GET_CURRENT_PATIENT, () => API.getCurrentPatient());
export const getCurrentProvider = createActionThunk(AT.GET_CURRENT_PROVIDER, () => API.getCurrentProvider());
export const getDetailLocations = createActionThunk(AT.GET_DETAIL_LOCATIONS, (id) => API.getDetailLocations(id));
export const addProcedure = createActionThunk(AT.ADD_PROCEDURE_DATA, (params) => API.addProcedure(params));
export const updateDetailProcedureData = createActionThunk(AT.UPDATE_DETAIL_PROCEDURE_DATA, (id, params) =>
  API.updateDetailProcedureData(id, params)
);
export const updateDetailLocations = createActionThunk(AT.UPDATE_DETAIL_LOCATIONS, (id, prams) => API.updateDetailLocations(id, prams));
export const getProcedureLocations = createActionThunk(AT.GET_PROCEDURE_LOCATIONS, (type) => API.getProcedureLocations(type));
export const deleteProcedure = createActionThunk(AT.DELETE_PROCEDURE, (id) => API.deleteProcedure(id));
export const getProviders = createActionThunk(AT.GET_PROVIDERS, () => API.getProviders());
export const getOnlyProviders = createActionThunk(AT.GET_ONLY_PROVIDERS, () => API.getProviders(null, null, 1));
export const setChannelCheck = createActionThunk(AT.SET_CHANNEL_CHECK, (value) => value);
export const getProvidersLocation = createActionThunk(AT.GET_PROVIDERS_LOCATION, (id) => API.getProvidersLocation(id));

export const upload_document_setting = createActionThunk(AT.UPLOAD_DOCUMENT_SETTING, (params) => API.upload_document_setting(params));
export const editProviders = createActionThunk(AT.EDIT_PROVIDERS, (id, params) => API.editProviders(id, params));

export const showButtonBack = createActionThunk(AT.SHOW_BUTTON_BACK, (status) => status);
export const setReduxPlanSidebar = createActionThunk(AT.SET_REDUX_PLAN_SIDE_BAR, (data) => data);
export const setTaskManager = createActionThunk(AT.SET_TASK_MANAGER, (data) => data);
export const resetStateResource = createActionThunk(AT.RESET_STATE_RESOURCE, () => {});

export const getProvidersSortSchedule = createActionThunk(AT.GET_PROVIDERS_SORT_SCHEDULE, () => API.getProviders(null, "schedule"));
export const getUltrasounds = createActionThunk(AT.GET_ULTRASOUNDS, () => API.getUltrasounds());
export const getDetailUltrasounds = createActionThunk(AT.GET_DETAIL_ULTRASOUNDS, (id) => API.getDetailUltrasounds(id));
export const deleteUltrasoundType = createActionThunk(AT.DELETE_ULTRASOUND_TYPE, (id) => API.deleteUltrasoundType(id));
export const getEvaluationsTagert = createActionThunk(AT.GET_EVALUATIONS_DETAIL_TARGERT, (id) => API.getEvaluationsTagert(id));

// ultrasound_finding_value
export const setDetailUltrasoundProps = createActionThunk(AT.SET_DETAIL_ULTRASOUND_PROPS, (type, newData) => ({
  [type]: newData,
}));

export const getListTask = createActionThunk(AT.GET_LIST_TASK, (page) => API.getListTask(page));

export const setFirstLoadingActive = createActionThunk(AT.SET_FIRST_LOADING_ACTIVE, (status) => status);
export const setErrorHPI = createActionThunk(AT.SET_ERROR_HPI, (status) => status);

export const get_ultrasound_rule_result = createActionThunk(AT.GET_ULTRASOUND_RULE_RESULT, (id) => API.get_ultrasound_rule_result(id));

export const save_ultrasound_rule_result = createActionThunk(AT.SAVE_ULTRASOUND_RULE_RESULT, (id, params) =>
  API.save_ultrasound_rule_result(id, params)
);

export const get_ultrasound_finding_value = createActionThunk(AT.GET_ULTRASOUND_FINDING_VALUE, (id) => API.get_ultrasound_finding_value(id));
export const setErrorUltrasound = createActionThunk(AT.SET_ERROR_ULTRASOUND, (status) => status);
export const save_ultrasound_finding_value = createActionThunk(AT.SAVE_ULTRASOUND_FINDING_VALUE, (params) =>
  API.save_ultrasound_finding_value(params)
);
export const detail_ultrasound_finding_value = createActionThunk(AT.DETAIL_ULTRASOUND_FINDING_VALUE, (id) => API.detail_ultrasound_finding_value(id));
//

export const getEvaluationsDetail = createActionThunk(AT.GET_EVALUATIONS_DETAIL, (id) => API.getEvaluationsDetail(id));

export const getEvaluations = createActionThunk(AT.GET_EVALUATIONS, (date, employee) => API.getEvaluations(date, employee));

export const getEvaluationsUser = createActionThunk(AT.GET_EVALUATIONS_USER, (id) => API.getEvaluationsUser(id));

export const incident_reports_all = createActionThunk(AT.GET_INCIDENT_REPORTS_ALL, () => API.incident_reports_all());

export const incident_reports = createActionThunk(AT.GET_INCIDENT_REPORTS, (id) => API.incident_reports(id));

export const incident_reports_update = createActionThunk(AT.GET_INCIDENT_REPORTS_UPDATE, (id, prams) => API.incident_reports_update(id, prams));

export const incident_reports_post = createActionThunk(AT.INCIDENT_REPORTS_POST, (prams) => API.incident_reports_post(prams));
export const updateDetailUltrasounds = createActionThunk(AT.UPDATE_DETAIL_ULTRASOUNDS, (id, params) => API.updateDetailUltrasounds(id, params));
export const timeoff_requests_all = createActionThunk(AT.TIMEOFF_REQUESTS_ALL, () => API.timeoff_requests_all());
export const timeoff_requests = createActionThunk(AT.TIMEOFF_REQUESTS, (id) => API.timeoff_requests(id));
export const approve_timeoff_requests = createActionThunk(AT.APPROVE_TIMEOFF_REQUESTS, (id, formData) => API.approve_timeoff_requests(id, formData));
export const addNewLocation = createActionThunk(AT.ADD_NEW_LOCATION, (params) => API.addNewLocation(params));
export const deleteLocation = createActionThunk(AT.DELETE_LOCATION, (id, params) => API.deleteLocation(id, params));
export const removeZipAuto = createActionThunk(AT.REMOVE_ZIP_AUTO, () => {});
export const autoZip = createActionThunk(AT.AUTO_ZIP, (zipcode) => API.autoZip(zipcode));
export const add_timeoff_requests = createActionThunk(AT.ADD_TIMEOFF_REQUESTS, (prams) => API.add_timeoff_requests(prams));
export const getPolicies = createActionThunk(AT.GET_POLICIES, () => API.getPolicies());

export const removeDataPatient = createActionThunk(AT.REMOVE_DATA_PATIENT, () => {});

export const procedure_cancel = createActionThunk(AT.PROCEDURE_CANCEL, (id, prams) => API.procedure_cancel(id, prams));

export const patient_cancel = createActionThunk(AT.PATIENT_CANCEL, (id, prams) => API.patient_cancel(id, prams));

export const checkApointment = createActionThunk(AT.CHECK_APOINTMENT, (status) => status);

export const actionAddAndSelect = createActionThunk(AT.ACTION_ADD_AND_SELECT, () => {});

export const set_cpt_code_review = createActionThunk(AT.SET_CPT_CODE_REVIEW, (id, params) => API.set_cpt_code_review(id, params));

export const get_cpt_code_review = createActionThunk(AT.GET_CPT_CODE_REVIEW, (id) => API.get_cpt_code_review(id));

export const search_cpt_code = createActionThunk(AT.SREARCH_CPT_CODE, (page, perpage) => API.search_cpt_code(page, perpage));

export const putPolicies = createActionThunk(AT.PUT_POLICIES, (id, params) => API.putPolicies(id, params));
export const postPolicies = createActionThunk(AT.POST_POLICIES, (params) => API.postPolicies(params));
export const deletePolicies = createActionThunk(AT.DELETE_POLICIES, (id) => API.deletePolicies(id));
export const getDetailPolicies = createActionThunk(AT.GET_DETAIL_POLICIES, (id) => API.getDetailPolicies(id));
export const setDataFieldCheckIn = createActionThunk(AT.SET_DATA_FIELD_CHECK_IN, (type, newData) => ({
  [type]: newData,
}));
export const timeoff_detail_requests = createActionThunk(AT.TIMEOFF_DETAIL_REQUESTS, (id) => API.timeoff_detail_requests(id));
export const filterLocations = createActionThunk(AT.FILTER_LOCATIONS, (patient_id, type) => API.getLocations(patient_id, type));
export const getUsers = createActionThunk(AT.GET_USERS, (id) => API.getUsers(id));
export const getPayperiods = createActionThunk(AT.GET_PAYPERIODS, () => API.getPayperiods());
export const disableUser = createActionThunk(AT.DISABLE_USERS, (id) => API.disableUser(id));
export const setActiveReviewChange = createActionThunk("SET_ACTIVE_REVIEW_CHANGE", (value) => value);
export const lock_ultrasound = createActionThunk("LOCK_ULTRASOUND", (value) => value);
// export const getPCM = createActionThunk(
//   AT.GET_PCM,
//   (page, type, sortColumn = {}) => API.getPCM(page, type, sortColumn)
// );
export const getPCMOverview = createActionThunk(AT.GET_PCM_OVERVIEW, (page, sortColumn = {}) => API.getPCMOverview(page, sortColumn));
export const getPCMSummary = createActionThunk(AT.GET_PCM_SUMMARY, (type, startDate, endDate) => API.getPCMSummary(type, startDate, endDate));
export const getPatientsAnalytics = createActionThunk(AT.GET_PATIENTS_ANALYTICS, (startDate, endDate) =>
  API.getPatientsAnalytics(startDate, endDate)
);
export const getPatientsTableAnalytics = createActionThunk(AT.GET_PATIENT_STATISTICS, (startDate, endDate) =>
  API.getPatientsTableAnalytics(startDate, endDate)
);
export const getPatientReferralTypes = createActionThunk(AT.GET_PATIENT_REFERRAL_TYPES, (startDate, endDate) =>
  API.getPatientReferralTypes(startDate, endDate)
);
export const getPatientReferralProviders = createActionThunk(AT.GET_PATIENT_REFERRAL_PROVIDERS, (startDate, endDate) =>
  API.getPatientReferralProviders(startDate, endDate)
);
export const getProceduresAnalytics = createActionThunk(AT.GET_PROCEDURES_ANALYTICS, (startDate, endDate, providerId) =>
  API.getProceduresAnalytics(startDate, endDate, providerId)
);
export const getClinicAnalytics = createActionThunk(AT.GET_CLINIC_ANALYTICS, (startDate, endDate, providerId) =>
  API.getClinicAnalytics(startDate, endDate, providerId)
);
export const getUltrasoundAnalytics = createActionThunk(AT.GET_ULTRASOUND_ANALYTICS, (startDate, endDate, providerId) =>
  API.getUltrasoundAnalytics(startDate, endDate, providerId)
);
export const getPCMDetail = createActionThunk(AT.GET_DETAIL_PCM, (id) => API.getPCMDetail(id));
export const getPCMForUser = createActionThunk(AT.GET_PCM_FOR_USER, (user_id, start_dos, end_dos) => API.getPCMForUser(user_id, start_dos, end_dos));

export const getPCMFilter = createActionThunk(AT.GET_PCM_FILTER, (data) => API.getPCMFilter(data));

export const getPCMProvider = createActionThunk(AT.GET_PCM_PROVIDER, () => API.getPCMProvider());

export const updatePCM = createActionThunk(AT.UPDATE_PCM, (id, params) => API.updatePCM(id, params));

export const deleteFaxLog = createActionThunk(AT.DELETE_FAX_LOG, (id) => {
  API.deleteFaxLog(id);
  return {
    id,
  };
});

export const deleteScanLog = createActionThunk(AT.DELETE_SCAN_LOG, (id) => API.deleteScanLog(id));

export const sortProviderNone = createActionThunk(AT.SORT_PROVIDER_NONE, (id, params) => API.sortProvider(id, params));

export const sortProviderEndo = createActionThunk(AT.SORT_PROVIDER_ENDO, (id, params) => API.sortProvider(id, params));

export const deletePatientDocument = createActionThunk(AT.DELETE_PATIENT_DOCUMENT, (id) => {
  API.deletePatientDocument(id);
  return {
    id,
  };
});

export const get_image_list = createActionThunk(AT.GET_IMAGE_LIST, (id, param) => API.get_image_list(id, param));

export const enableUser = createActionThunk(AT.ENABLE_USERS, (id) => API.enableUser(id));

export const getDetailUsers = createActionThunk(AT.GET_DETAIL_USERS, (id) => API.getDetailUsers(id));
export const getCurrentUserInfo = createActionThunk(AT.GET_CURRENT_USER, (id) => API.getDetailUsers(id));
export const getTimeEntries = createActionThunk(AT.GET_TIME_ENTRIES, (userId, payPeriodId) => API.getTimeEntries(userId, payPeriodId));
export const saveDetailUsers = createActionThunk(AT.SAVE_DETAIL_USERS, (id, params) => API.saveDetailUsers(id, params));

export const postUser = createActionThunk(AT.POST_USER, (params) => API.postUser(params));

export const saveEmployeeInfo = createActionThunk(AT.SAVE_EMPLOYEE_INFO, (id, params) => API.saveEmployeeInfo(id, params));

export const filterProviders = createActionThunk(AT.FILTER_PROVIDERS, (patient_id) => API.getProviders(patient_id));

export const saveTextReview = createActionThunk(AT.SAVE_TEXT_REVIEW, (id, params) => API.saveTextReview(id, params));

export const getReferralConditions = createActionThunk(AT.GET_REFERRAL_CONDITIONS, () => API.getReferralCondition());
export const getConditionDetailData = createActionThunk(AT.GET_DETAIL_CONDITION_DATA, (id) => API.getConditionDetailData(id));
export const getProviderUser = createActionThunk(AT.GET_PROVIDER_USER, (id) => API.getProviderUser(id));
export const getDiseases = createActionThunk(AT.GET_DISEASES, (query) => API.getDiseases(query));
export const getSurgicalHistories = createActionThunk(AT.GET_SURGICAL_HISTORIES, (query) => API.getSurgicalHistories(query));
export const getProcedureData = createActionThunk(AT.GET_PROCEDURE_DATA, () => API.getProcedureData());
export const updateProcedureTypeCPT = createActionThunk(AT.UPDATE_PROCEDURE_TYPE_CPT, (id, params) => API.updateProcedureTypeCPT(id, params));
export const getDetailProcedureData = createActionThunk(AT.GET_DETAIL_PROCEDURE_DATA, (id) => API.getDetailProcedureData(id));
export const getConditionICDCode = createActionThunk(AT.GET_CONDITION_ICD_CODE, (id) => API.getConditionICDCode(id));
export const copy_node_hpi = createActionThunk(AT.COPY_NODE_HPI, (id, params) => API.copy_node_hpi(id, params));

export const deleteProcedureData = createActionThunk(AT.DELETE_PROCEDURE_DATA, (id) => API.deleteProcedureData(id));
export const postProviderSaveSchedule = createActionThunk(AT.POST_PROVIDER_SAVE_SCHEDULE, (id, prams) => API.postProviderSaveSchedule(id, prams));
export const deleteProviderSchedule = createActionThunk(AT.DELETE_PROVIDER_SCHEDULE, (id, prams) => API.deleteProviderSchedule(id, prams));
export const saveProcedureCondition = createActionThunk(AT.SAVE_PROCEDURE_CONDITION, (id, prams) => API.saveProcedureCondition(id, prams));
export const getReferredProviders = createActionThunk(AT.GET_REFERRED_PROVIDERS, () => API.getRefferredProviders());
export const getPCPList = createActionThunk(AT.GET_PCP_LIST, (page, search) => API.getPCPList(page, search));
export const getPCPFromNPE = createActionThunk(AT.GET_PCP_FROM_NPE, (page, search) => API.getPCPFromNPE(page, search));
export const get_list_patients = createActionThunk(AT.GET_LIST_PATIENTS, (page, status, search) => API.get_list_patients(page, status, search));
export const getCallAttemptResult = createActionThunk(AT.GET_CALL_RESULTS, () => API.getCallAttempResult());
export const deleteCallAttemp = createActionThunk(AT.DELETE_CALL_ATTEMP, (id, params) => API.deleteCallAttemp(id, params));

export const set_toast_put_bar_code = createActionThunk(AT.SET_TOAST_PUT_BAR_CODE, () => {});

export const setPCPNull = createActionThunk(AT.SET_PCP_NULL, () => {});
export const resetAllStatus = createActionThunk(AT.RESET_ALL_STATES, () => ({
  reset: "all",
}));

export const set_communication_compelete = createActionThunk(AT.SET_COMMUNICATION_COMPELETE, (action) => action);

export const set_id_image = createActionThunk(AT.SET_ID_IMAGE, (id) => id);

export const setGlobalErrorMessage = createActionThunk(AT.SET_GLOBAL_ERROR_MESSAGE, (error) => error);

export const exportPCM = createActionThunk(AT.EXPORT_PCM, (user_id, startDate, endDate) => API.exportPCM(user_id, startDate, endDate));

export const exportPCMOverview = createActionThunk(AT.EXPORT_PCM_OVERVIEW, (icon_review_status) => API.exportPCMOverview(icon_review_status));

export const exportPCMUser = createActionThunk(AT.EXPORT_PCM_USER, (user_id, startDate, endDate) =>
  API.exportPCMforUser(user_id, startDate, endDate)
);

export const searchAllergy = createActionThunk(AT.SEARCH_ALLERGY, (query) => API.searchAllergy(query));

export const save_temp = createActionThunk(AT.SAVE_TEMP, (id, param) => API.save_temp(id, param));

export const setActiveSupportTicket = createActionThunk(AT.ACTIVE_SUPPORT_TICKET, (params) => params);

export const setAuthorRead = createActionThunk(AT.UPDATE_AUTHOR_READ, (id) => API.updateAuthorReadStatus(id));

export const setSupportRead = createActionThunk(AT.UPDATE_SUPPORT_READ, (id) => API.updateSupportReadStatus(id));

export const getPharmacies = createActionThunk(AT.GET_PHARMACIES, (query) => API.getPharmacies(query));

export const searchMedications = createActionThunk(AT.SEARCH_MEDICATIONS, (query) => API.searchMedications(query));

export const getFieldMedication = createActionThunk(AT.GET_FIELD_MEDICATION, (id) => API.getFieldMedication(id));

export const getPharmacy = createActionThunk(AT.GET_PHARMACY, (id) => API.getSinglePharmacy(id));

export const savePharmacy = createActionThunk(AT.SAVE_PHARMACY, (params) => API.savePharmacy(params));

export const editPharmacy = createActionThunk(AT.EDIT_PHARMACY, (id, params) => API.editPharmacy(id, params));

export const deletePharmacy = createActionThunk(AT.DELETE_PHARMACY, (id) => API.deletePharmacy(id));

export const getSingleMedication = createActionThunk(AT.GET_SINGLE_MEDICATION, (id) => API.getSingleMedication(id));

export const get_location_provider = createActionThunk(AT.GET_LOCATION_PROVIDER, (params) => API.get_location_provider(params));

export const getCalendarProcedureTypes = createActionThunk(AT.GET_CALENDAR_PROCEDURE_TYPES, (params) => API.getCalendarProcedureTypes(params));

// SIDENAV STATUS

export const collapseSideNav = createActionThunk(AT.UPDATE_SIDENAV_STATE, (params) => params);

export const getLock = createActionThunk(AT.GET_LOCK, (id) => API.getLock(id));

export const releaseLock = createActionThunk(AT.RELEASE_LOCK, (id, params) => API.releaseLock(id, params));

export const notify_lock = createActionThunk(AT.NOTIFY_LOCK, (id, isForce) => API.notify_lock(id, isForce));

export const deleteNoteHpi = createActionThunk(AT.DELETE_NOTE_HPI, (id, params) => API.deleteNoteHpi(id, params));
export const resetMedication = createActionThunk(AT.RESET_MEDICATION, () => {});

//
export const setActionGoNextTab = createActionThunk(AT.SET_ACTION_GO_NEXT_TAB, (status) => status);
