import { Tabs } from "@material-ui/core";
import { Icon, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlanOrders from "../../../common/components/PlanOder/PlanOrders";
import PusherCheck from "../../../common/components/PusherCheck";
import TopBar from "../../../common/components/SubTopBar";
import Appointments from "../../../common/views/Appointment";
import Medications from "../../../common/views/Medications";
import PatientChart from "../../../common/views/PatientChart";
import PatientOverview from "../../../common/views/PatientSnapshot";
import { getLocal } from "../../../helpers/Local";
import RemoveN from "../../../helpers/RemoveN";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import {
  HPISaveCondition,
  changeTab,
  choosePatientOnSchedules,
  clinicProviderReviewLoadInit,
  getOnlyProviders,
  get_patient_notes,
  patientExaminationCopyLast,
  patientExaminationSave,
  patientExaminationSetData,
  patientExaminationSetNomalCheck,
  patient_insurances_id_insurances,
  releaseLock,
  remove_patient_on_schedules,
  resetStateResource,
  save_plan_patient,
  setActionGoNextTab,
  setActiveReviewChange,
  setDataPatient,
  setFirstLoadingActive,
  setGlobalLoading,
  setGlobalToastr,
  setPatientSnapshotData,
} from "../../../store/actions";
import { TabHeaders } from "../constant";
import ClinicSchedule from "./providerSubViews/ClinicSchedule";
import HPIContainer from "./providerSubViews/HPIContainer";
import PhysicalExamination from "./providerSubViews/PhysicalExamination";
import Review from "./providerSubViews/Review";
import RosVitals from "./providerSubViews/RosVitals";
import moment from "moment";

function Provider() {
  const [activeTab, setActiveTab] = useState(0);
  const [isSelectedPatient, setIsSelectedPatient] = useState(false);
  const [pusherCheckBuild, setPusherCheckBuild] = useState([]);
  const [rosCoppy, setRosCoppy] = useState(false);
  const [physicalCoppy, setPhysicalCoppy] = useState(false);
  const [stateStatusChange, setStateStatusChange] = useState(null);

  const dispatch = useDispatch();
  const firstLoadingActive = useSelector((state) => state.common.firstLoadingActive);
  const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);
  const conditions = useSelector((state) => state.clinicProviderHPI.conditions);
  const tabActive = useSelector((state) => state.clinicProviderReview.tabActive);
  const loadingResource = useSelector((state) => state.clinicProvider.loadingResource);
  const ROSs = useSelector((state) => state.patientExamination.ROSs);
  const original_ROSs = useSelector((state) => state.patientExamination.original_ROSs);
  const original_vitals = useSelector((state) => state.patientExamination.original_vitals);
  const vitals = useSelector((state) => state.patientExamination.vitals);
  const physical_examinations = useSelector((state) => state.patientExamination.physical_examinations || []);
  const original_physical_examinations = useSelector((state) => state.patientExamination.original_physical_examinations || []);
  const vessel_states = useSelector((state) => state.patientExamination.vessel_states);
  const original_vessel_states = useSelector((state) => state.patientExamination.original_vessel_states);
  const resourceCheck = useSelector((state) => state.patientSnapshot.resource);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  const copy_from_last_visit_ros = useSelector((state) => state.patientExamination.copy_from_last_visit_ros);
  const original_copy_from_last_visit_exam = useSelector((state) => state.patientExamination.original_copy_from_last_visit_exam);
  const original_copy_from_last_visit_ros = useSelector((state) => state.patientExamination.original_copy_from_last_visit_ros);
  const copy_hpi_from_previous_appointment = useSelector((state) => state.clinicProviderHPI.copy_hpi_from_previous_appointment);
  const copy_from_last_visit_exam = useSelector((state) => state.patientExamination.copy_from_last_visit_exam);
  const vascuqol6 = useSelector((state) => state.patientExamination.vascuqol6);
  const editLock = useSelector((state) => state.common.editLock);
  const params_plan = useSelector((state) => state.common.params_plan);
  const loadAgain = useSelector((state) => state.common.loadAgain);
  const actionGoNextTab = useSelector((state) => state.common.actionGoNextTab);
  const isSaveHPI = useSelector((state) => state.clinicProviderHPI.isSaveHPI);
  const globalLoading = useSelector((state) => state.common.globalLoading);
  const only_providers = useSelector((state) => state.common.only_providers);

  useEffect(() => {
    if (!only_providers || only_providers.length == 0) {
      dispatch(getOnlyProviders());
    }
  }, [only_providers]);

  useEffect(() => {
    const procedure_id = getLocal("current_appointment_id");
    return () => {
      dispatch(releaseLock(procedure_id, {}));
    };
  }, []);

  useEffect(() => {
    if (actionGoNextTab && !loadAgain) {
      setActiveTab(1);
      dispatch(setActionGoNextTab(false));
    }
  }, [actionGoNextTab, loadAgain]);

  useEffect(() => {
    if ((!patientResource || Object.keys(patientResource).length < 1) && activeTab !== 0) {
      setActiveTab(0);
    }
    if (activeTab === 0 && patientResource && firstLoadingActive) {
      setActiveTab(1);
      dispatch(setFirstLoadingActive(false));
    }
    if (loadAgain) {
      setActiveTab(0);
    }
    if (tabActive && tabActive === "patient_snapshot") {
      setActiveTab(1);
    } else if (tabActive && tabActive === "plan_order") {
      setActiveTab(6);
    } else if (tabActive && tabActive === "vital") {
      setActiveTab(4);
    }
    const patientResourceProps = patientResource || {};
    if (Object.keys(patientResourceProps).length > 0) {
      setIsSelectedPatient(true);
      setRosCoppy(false);
      setPhysicalCoppy(false);
    } else {
      setIsSelectedPatient(false);
    }
  }, [activeTab, firstLoadingActive, loadAgain, tabActive, patientResource]);

  const refreshImageList = () => {};

  const goNextTab_ = () => {
    goNextTab();
  };

  const goNextTab = () => {
    setActiveTab(1);
  };

  const renderSideComponent = () => {
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";
    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {resourceCheck?.gender && resourceCheck?.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  const goNextTabReview = () => {
    setActiveTab(7);
    dispatch(setActiveReviewChange(false));
  };

  const RosChange = async (scope, data) => {
    dispatch(setGlobalLoading(true));
    await dispatch(patientExaminationSetData(scope, data, true));
    dispatch(setGlobalLoading(false));
  };

  const RosCoppy = async (id) => {
    dispatch(setGlobalLoading(true));
    const procedure_id = getLocal("current_appointment_id");
    await dispatch(patientExaminationCopyLast(procedure_id, "ROS"));
    setRosCoppy(true);
    dispatch(setGlobalLoading(false));
  };

  const RosCheckAll = async (scope, data, checked) => {
    dispatch(setGlobalLoading(true));
    await dispatch(patientExaminationSetData(scope, data, true));
    dispatch(setGlobalLoading(false));
  };

  const PhysicalChange = async (scope, data) => {
    dispatch(setGlobalLoading(true));
    await dispatch(patientExaminationSetData(scope, data, true));
    dispatch(setGlobalLoading(false));
  };

  const PhysicalCoppy = async (id) => {
    dispatch(setGlobalLoading(true));
    const procedure_id = getLocal("current_appointment_id");
    await dispatch(patientExaminationCopyLast(procedure_id, "patient_examination"));
    setPhysicalCoppy(true);
    dispatch(setGlobalLoading(false));
  };

  const PhysicalCheckAll = async (scope, data, checked) => {
    dispatch(setGlobalLoading(true));
    await dispatch(patientExaminationSetData(scope, data, true));
    dispatch(setGlobalLoading(false));
  };

  const changeTabIndex = async (index) => {
    if (globalLoading) {
      return;
    }
    if (activeTab === 3 && isSaveHPI && (is_locked === 0 || is_locked === null || is_locked === false)) {
      dispatch(
        setGlobalToastr({
          header: "Error",
          message: "Please save HPI first",
          type: "failed",
        })
      );
      return;
    }
    const procedure_id = getLocal("current_appointment_id");
    const patient_id = getLocal("current_patient_id");
    if (index === 0) {
      await dispatch(releaseLock(procedure_id, {}));
      dispatch(remove_patient_on_schedules());
      dispatch(resetStateResource());
    }
    if (activeTab !== 0 && index === 1 && patient_id) {
      const result = await dispatch(choosePatientOnSchedules(patient_id));
      if (result) {
        dispatch(setDataPatient(result));
      }
      dispatch(setPatientSnapshotData("resource", { ...result }));

      await dispatch(get_patient_notes(patient_id));
      // await dispatch(patient_insurances_id_insurances(patient_id));
    }
    if (is_locked === 0 || is_locked === null || is_locked === false) {
      if (activeTab === 0 || activeTab === 1 || activeTab === 2 || activeTab === 8 || activeTab === 9) {
        if (index === 7) {
          dispatch(clinicProviderReviewLoadInit(procedure_id, 1));
        }
      }
      if (activeTab === 3) {
        const patient_id = patientResource?.id;
        const conditionCheck = conditions;
        dispatch(setGlobalLoading(true));
        const fixArr = (conditionCheck || []).map((r) => {
          return {
            ...r,
            questions: (r?.questions || []).map((q) => {
              return {
                ...q,
                comments: RemoveN(q?.comments || ""),
              };
            }),
            text: RemoveN(r?.text || ""),
          };
        });
        await dispatch(HPISaveCondition(patient_id, procedure_id, fixArr, copy_hpi_from_previous_appointment));
        if (index === 7) {
          await dispatch(clinicProviderReviewLoadInit(procedure_id, 1));
        }
        dispatch(setGlobalLoading(false));
      }
      if (activeTab === 4) {
        const patient_id = patientResource?.id;
        const fixArr = (ROSs || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        dispatch(setGlobalLoading(true));
        if (
          !_.isEqual(ROSs, original_ROSs) ||
          !_.isEqual(vitals, original_vitals) ||
          copy_from_last_visit_ros !== original_copy_from_last_visit_ros ||
          vascuqol6
        ) {
          await dispatch(
            patientExaminationSave(procedure_id, {
              patient_id,
              ROSs: fixArr,
              ignore_bp_right: (vitals?.find((r) => r?.type === "ignore_bp_right") || {})?.value,
              ignore_bp_left: (vitals?.find((r) => r?.type === "ignore_bp_left") || {})?.value,
              copy_from_last_visit_ros,
              vitals,
              vascuqol6,
            })
          );
        }
        if (index === 7) {
          dispatch(clinicProviderReviewLoadInit(procedure_id, 1));
        }
        dispatch(setGlobalLoading(false));
      }
      if (activeTab === 5) {
        const patient_id = patientResource?.id;
        const fixArr = (physical_examinations || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || r?.default_comments || ""),
          };
        });
        const procedure_id = getLocal("current_appointment_id");
        dispatch(setGlobalLoading(true));

        if (vessel_states && !Array.isArray(vessel_states) && Object.values(vessel_states).some((r) => !r)) {
          await dispatch(
            patientExaminationSave(procedure_id, {
              patient_id,
              physical_examinations: fixArr,
              copy_from_last_visit_exam,
            })
          );
          dispatch(setGlobalLoading(false));
        } else {
          await dispatch(
            patientExaminationSave(procedure_id, {
              patient_id,
              physical_examinations: fixArr,
              copy_from_last_visit_exam,
              vessel_states,
            })
          );
          dispatch(setGlobalLoading(false));
        }
        if (index === 7) {
          dispatch(clinicProviderReviewLoadInit(procedure_id, 1));
        }
        dispatch(setGlobalLoading(false));
      }
      if (activeTab === 6) {
        const procedure_id = getLocal("current_appointment_id");
        await dispatch(save_plan_patient(params_plan));
        if (index === 7) {
          dispatch(clinicProviderReviewLoadInit(procedure_id, 1));
        }
      }
    }
    dispatch(
      changeTab({
        tabActive: null,
        accordion: null,
        step: null,
      })
    );
    setActiveTab(index);
  };

  const setIndexForce = () => {
    changeTabIndex(0);
  };

  const getItemBuild = (value) => {
    if (value !== null) {
      if (pusherCheckBuild.length === 0 || !pusherCheckBuild.some((r) => r === value)) {
        setPusherCheckBuild([...pusherCheckBuild, value]);
      }
    }
  };

  const updateStatusPusher = (value) => {
    if (value && value.appointment_id) {
      setStateStatusChange(value);
    }
  };

  return (
    <div className={`clinicWorkflow__main-page`}>
      <TopBar
        title="Provider Module"
        subStatus={""}
        sideComponent={renderSideComponent()}
        onRefreshImageList={refreshImageList}
        isBarcode={isSelectedPatient}
      />

      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {TabHeaders.map((tab, index) =>
                (isSelectedPatient && index < 3 && editLock.locked === 0) ||
                (isSelectedPatient && patientResource?.has_valid_insurance === 1 && editLock.locked === 0) ? (
                  <div
                    id={`${tab?.label && tab?.label.length > 0 && tab?.label.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")}`}
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                    style={{ width: tab.width }}
                    onClick={() => changeTabIndex(index)}
                  >
                    {tab.label}
                  </div>
                ) : (
                  <div
                    id={`${tab?.label && tab?.label.length > 0 && tab?.label.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")}`}
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? "active" : ""} ${index > 0 && "disabled"}`}
                    style={{ width: tab.width }}
                  >
                    {tab.label}
                  </div>
                )
              )}
            </Tabs>
          </div>
        </div>

        <PusherCheck updateStatus={updateStatusPusher} getItemBuild={getItemBuild} setIndex={setIndexForce}></PusherCheck>
        {activeTab >= 0 && (
          <ClinicSchedule
            stateStatusProps={stateStatusChange}
            updateStatusProps={() => {
              setStateStatusChange(null);
            }}
            pusherCheckBuild={pusherCheckBuild}
            pusherCheckUnfinalized={[]}
            display={activeTab === 0}
            goNextTab={goNextTab_}
            goNextTabReview={goNextTabReview}
            isClinicWorkFlow={true}
          />
        )}
        {patientResource && activeTab === 1 && (
          <PatientOverview
            sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
            isShowGoto={false}
            isClinic={true}
            patientResource={patientResource}
            loadingResource={loadingResource}
            hasAppointment={true}
          />
        )}
        {patientResource && activeTab === 2 && (
          <PatientChart sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} patientResource={patientResource} />
        )}
        {patientResource && patientResource?.has_valid_insurance === 1 && activeTab === 3 && <HPIContainer />}

        {patientResource && patientResource?.has_valid_insurance === 1 && activeTab === 4 && (
          <RosVitals
            display={true}
            RosChange={RosChange}
            RosCheckAll={RosCheckAll}
            RosCoppy={RosCoppy}
            rosCoppy={rosCoppy}
            isSlide={patientResource}
          />
        )}

        {patientResource && patientResource?.has_valid_insurance === 1 && activeTab === 5 && (
          <PhysicalExamination
            display={true}
            PhysicalChange={PhysicalChange}
            PhysicalCheckAll={PhysicalCheckAll}
            PhysicalCoppy={PhysicalCoppy}
            physicalCoppy={physicalCoppy}
            isSlide={patientResource}
          />
        )}

        {patientResource && patientResource?.has_valid_insurance === 1 && activeTab === 6 && (
          <PlanOrders navigateToReview={goNextTabReview} sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} />
        )}

        {patientResource && patientResource?.has_valid_insurance === 1 && activeTab === 7 && (
          <Review sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} />
        )}
        {patientResource && patientResource?.has_valid_insurance === 1 && activeTab === 8 && (
          <Medications sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} />
        )}
        {patientResource && patientResource?.has_valid_insurance === 1 && activeTab === 9 && (
          <Appointments sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} display={activeTab === 9 ? "flex" : "none"} />
        )}
      </div>
    </div>
  );
}

export default Provider;
