import { Drawer, Icon, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import CptReview from "../../../../common/components/CptReview/index";
import { default as EditableFormInput, default as EditableInput } from "../../../../common/components/EditableText/EditableFormInput";
import Loading from "../../../../common/components/Loading";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SubDataTable from "../../../../common/components/SubDataTable/ExtendDataTable";
import { getLocal } from "../../../../helpers/Local";
import {
  change_medical_decision,
  clinicProviderReviewLoadInit,
  getOnlyProviders,
  getPatientSchedules,
  get_cpt_code_review,
  get_plan_patient,
  lockClinicProcedure,
  save_temp,
  search_cpt_code,
  setErrorHPI,
  setGlobalLoading,
  setGlobalToastr,
  setReduxPlanSidebar,
  set_cpt_code_review,
  unlockClinicProcedure,
  unlock_finalize,
} from "../../../../store/actions";
import ReviewIcdCode from "./ReviewIcdCode";
let timer = null;

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const ReviewSideBar = React.forwardRef(({ isShow, handleCloseSidebar, valueText, sidebarIcon, handleChangeTime, handleChangeProvider }, ref) => {
  const patientResource = useSelector((state) => state.clinicProvider?.patientResource);
  const showBack = useSelector((state) => state.common?.showBack);
  const usersCheck = useSelector((state) => state.common.currentUserInfo);
  const patient_all_insurances_id = useSelector((state) => state.newPatient.patient_all_insurances_id);
  const fullName = (patientResource?.first_name + " " + patientResource?.last_name).toUpperCase();
  const { deceased, cancelled_reason } = patientResource;
  const [dataSearch, setDataSearch] = useState([]);
  const [dataICD, setDataICD] = useState([]);
  const [cptData, setCptData] = useState([]);
  const [isResult, setIsResult] = useState(false);
  const [searchInput, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const dispatch = useDispatch();
  const procedure_id = getLocal("current_appointment_id");
  const scheduleParams = JSON.parse(getLocal("scheduleParams"));
  const vitals = useSelector((state) => state.patientExamination.vitals);
  const has_empty_hpi_question = useSelector((state) => state.clinicProviderReview.has_empty_hpi_question);
  const has_wrong_carotid = useSelector((state) => state.clinicProviderReview.has_wrong_carotid);
  const has_empty_vasquol6 = useSelector((state) => state.clinicProviderReview.has_empty_vasquol6);
  const has_unprocessed_plans = useSelector((state) => state.clinicProviderReview.has_unprocessed_plans);
  const only_providers = useSelector((state) => state.common.only_providers);
  const CPT = useSelector((state) => state.common.CPT);
  const searchData = useSelector((state) => state.common.searchCPT);
  const patient_plan = useSelector((state) => state.procedure.get_plan_patient);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const [dataPlan, setDataPlan] = useState([]);
  const [medicalDecision, setMeicalDecision] = useState("");
  const [loadingButton, setLoadingButton] = useState(true);
  const [localProviderId, setLocalProviderId] = useState(0);
  const [localFinalizedDate, setLocalFinalizedDate] = useState(moment().format("MM/DD/YYYY"));
  const [isLock, setIsLock] = useState(0);
  const [statusAbort, setStatusAbort] = useState("");
  const reason_for_visit = useSelector((state) => state.clinicProviderHPI.reason_for_visit);
  const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);
  const has_wrong_physical_exam = useSelector((state) => state.clinicProviderReview.has_wrong_physical_exam);
  const has_wrong_physical_exam_2_left = useSelector((state) => state.clinicProviderReview.has_wrong_physical_exam_2_left);
  const has_wrong_physical_exam_2_right = useSelector((state) => state.clinicProviderReview.has_wrong_physical_exam_2_right);
  const has_wrong_physical_exam_3_left = useSelector((state) => state.clinicProviderReview.has_wrong_physical_exam_3_left);
  const has_wrong_physical_exam_3_right = useSelector((state) => state.clinicProviderReview.has_wrong_physical_exam_3_right);
  const require_other_symptom_right = useSelector((state) => state.clinicProviderReview.require_other_symptom_right);
  const require_other_symptom_left = useSelector((state) => state.clinicProviderReview.require_other_symptom_left);
  const has_trial_right = useSelector((state) => state.clinicProviderReview.has_trial_right);
  const has_trial_left = useSelector((state) => state.clinicProviderReview.has_trial_left);
  const has_same_choice = useSelector((state) => state.clinicProviderReview.has_same_choice);
  const medical_decision_making = useSelector((state) => state.clinicProviderReview.medical_decision_making);
  const meaningful_uses = useSelector((state) => state.clinicProviderReview.meaningful_uses);
  const redReasons = ["Deceased", "Death", "Changed Provider"];
  //
  const copy_from_last_visit_exam = useSelector((state) => state.patientExamination.copy_from_last_visit_exam);
  const copy_from_last_visit_ros = useSelector((state) => state.patientExamination.copy_from_last_visit_ros);
  const history = useHistory();

  const checkIsLock = () => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      setIsLock(is_locked);
      setLoadingButton(false);
    }, 1000);
  };

  useEffect(() => {
    checkIsLock();
  }, [is_locked]);

  useEffect(() => {
    setStatusAbort(CPT?.appointment_status);
  }, [CPT]);

  useEffect(() => {
    if (!only_providers || only_providers.length == 0) {
      dispatch(getOnlyProviders());
    }
  }, [only_providers]);

  useEffect(() => {
    setMeicalDecision(medical_decision_making);
  }, [medical_decision_making]);

  useEffect(() => {
    callApis();
  }, []);
  async function callApis() {
    setLoading(true);
    await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
    setLoading(false);
  }
  const removeRow = (index) => {
    let newRows = [];
    newRows = [...cptData];
    newRows.splice(index, 1);
    setCptData([...newRows]);
  };

  const selectItem = (itemcheck, item, type) => {
    const newRows = cptData;
    let checkExist = false;
    (cptData || []).some((r) => {
      if (r.cpt_code === item) {
        checkExist = true;
      }
    });
    if (!checkExist) {
      newRows.push({ cpt_code: item, short_desc: type });
      setCptData(newRows);
      setIsResult(false);
      setInput("");
    } else {
      setIsResult(false);
      setInput("");
    }
  };

  useEffect(() => {
    if (patient_plan && patient_plan.length > 0) {
      let checkArray = [];
      (patient_plan || []).map((item) => {
        (item?.plan || []).map((r) => {
          if (r.bundle === "ultrasound_test") {
            if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
              checkArray = [
                ...checkArray,
                {
                  value: r?.side + " " + r?.ultrasound?.ultrasound_type?.name,
                  processed: r.processed,
                },
              ];
            } else {
              checkArray = [
                ...checkArray,
                {
                  value: r?.ultrasound?.ultrasound_type?.name,
                  processed: r.processed,
                },
              ];
            }
          } else if (r.bundle === "procedure") {
            if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
              checkArray = [
                ...checkArray,
                {
                  value: r?.side + " " + r?.procedure?.procedure_type?.value,
                  processed: r.processed,
                },
              ];
            } else {
              checkArray = [
                ...checkArray,
                {
                  value: r?.procedure?.procedure_type?.value ?? "",
                  processed: r.processed,
                },
              ];
            }
          } else if (r.bundle === "general" || r.bundle === "referral" || r.bundle === "ct") {
            checkArray = [
              ...checkArray,
              {
                value: r?.procedure_extra?.procedure_type?.name,
                processed: r.processed,
              },
            ];
          } else if (r.bundle === "angio") {
            if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
              checkArray = [
                ...checkArray,
                {
                  value: r?.side + " " + r?.angio?.angio_type?.name ?? "",
                  processed: r.processed,
                },
              ];
            } else {
              checkArray = [
                ...checkArray,
                {
                  value: r?.angio?.angio_type?.name ?? "",
                  processed: r.processed,
                },
              ];
            }
          } else if (r.bundle === "clinic" || r.bundle === "") {
            checkArray = [
              ...checkArray,
              {
                value: r?.procedure?.clinic_type?.name ?? "",
                processed: r.processed,
              },
            ];
          } else if (r.bundle === "prescription") {
            checkArray = [
              ...checkArray,
              {
                value: r?.prescription?.medication?.full_medication + " " + r?.prescription?.frequency,
                processed: r.processed,
              },
            ];
          }
        });
      });
      setDataPlan(checkArray);
      dispatch(setReduxPlanSidebar(checkArray));
    }
  }, [patient_plan]);

  const handleSearch = async (search) => {
    await setInput(search);
    clearTimeout(timer);
    setLoading(true);
    timer = setTimeout(async () => {
      await dispatch(search_cpt_code(search));
      setLoading(false);
    }, 1000);
  };

  const saveTemp = async (value) => {
    if (isLock != 1) {
      setMeicalDecision(value);
      dispatch(change_medical_decision(value));
      setLoading(true);
      const param = {
        medical_decision_making: value,
      };
      await dispatch(save_temp(procedure_id, param));
      setLoading(false);
    }
  };

  const handleGoBackReview = (e) => {
    e.stopPropagation();
    e.isPropagationStopped();
    history.push("/administrator/records-review");
  };

  const checkBp = (vitals) => {
    const hasBpLeftEmpty = vitals.some((r) => r?.type == "BP (Left)" && (r?.value == null || r?.value == ""));
    const hasIgnoreLeftEmpty = vitals.some((r) => r?.type == "ignore_bp_left" && (r?.value == null || r?.value == ""));

    const hasBpRightEmpty = vitals.some((r) => r?.type == "BP (Right)" && (r?.value == null || r?.value == ""));
    const hasIgnoreRightEmpty = vitals.some((r) => r?.type == "ignore_bp_right" && (r?.value == null || r?.value == ""));

    if (hasBpLeftEmpty && hasIgnoreLeftEmpty) return true;
    else if (hasBpRightEmpty && hasIgnoreRightEmpty) return true;

    return false;
  };

  const checkEmptyVital = (vitals) => {
    const hasEmptyValue = vitals.some(
      (r) =>
        r?.type != "BP (Left)" &&
        r?.type != "BP (Right)" &&
        r?.type != "ignore_bp_left" &&
        r?.type != "ignore_bp_right" &&
        (r?.value == null || r?.value == "")
    );
    return hasEmptyValue;
  };

  const saveAll = async () => {
    if (
      patient_all_insurances_id &&
      patient_all_insurances_id.length > 0 &&
      (patient_all_insurances_id.some((r) => r?.is_eligible && r?.is_verified) || patient_all_insurances_id.some((r) => r?.skip_checking))
    ) {
      if (isLock == 0 && vitals && vitals.length > 0 && (checkEmptyVital(vitals) || checkBp(vitals))) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Vitals are required",
            type: "failed",
          })
        );
      } else if (meaningful_uses.length > 0 && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Meaningful Use have value",
            type: "failed",
          })
        );
      } else if (has_empty_hpi_question === true && isLock == 0) {
        await dispatch(setErrorHPI(true));
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "HPI question must be answered",
            type: "failed",
          })
        );
      } else if (has_wrong_carotid === true && isLock == 0) {
        await dispatch(setErrorHPI(true));
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Patient needs to have documented ever experiencing TIA, Stroke or Carotid Bruit",
            type: "failed",
          })
        );
      } else if (has_empty_vasquol6 === true && isLock == 0) {
        await dispatch(setErrorHPI(true));
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Patient needs to have VASCUQOL-6",
            type: "failed",
          })
        );
      } else if (has_unprocessed_plans === true && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Please process all plan order",
            type: "failed",
          })
        );
      } else if (!reason_for_visit && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Reason For Visit is Empty",
            type: "failed",
          })
        );
      } else if (has_wrong_physical_exam && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "Patient pulse examination is not concordant with Condition",
            type: "failed",
          })
        );
      } else if (has_wrong_physical_exam_2_left && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "The pulse examination must be 0 or D for the rest pain selection of the LEFT",
            type: "failed",
          })
        );
      } else if (has_wrong_physical_exam_2_right && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "The pulse examination must be 0 or D for the rest pain selection of the RIGHT",
            type: "failed",
          })
        );
      } else if (has_wrong_physical_exam_3_left && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "The pulse examination must be 0 or D or 1+ for the claudication selection of the LEFT",
            type: "failed",
          })
        );
      } else if (has_wrong_physical_exam_3_right && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "The pulse examination must be 0 or D or 1+ for the claudication selection of the RIGHT",
            type: "failed",
          })
        );
      } else if (has_trial_right && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "The patient is a new patient and needs a trial of 3 months conservative therapy prior to scheduling an angiogram on the RIGHT.",
            type: "failed",
          })
        );
      } else if (has_trial_left && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "The patient is a new patient and needs a trial of 3 months conservative therapy prior to scheduling an angiogram on the LEFT.",
            type: "failed",
          })
        );
      } else if (require_other_symptom_right && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "The patient requires symptoms with restenosis prior to scheduling an angiogram on the RIGHT.",
            type: "failed",
          })
        );
      } else if (require_other_symptom_left && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "The patient requires symptoms with restenosis prior to scheduling an angiogram on the LEFT.",
            type: "failed",
          })
        );
      } else if (has_same_choice && isLock == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message:
              "The patients HPI has not changed after intervention of the Angiogram. Please select the reason for not changing or change the selection",
            type: "failed",
          })
        );
      } else {
        await dispatch(setGlobalLoading(true));
        setLoading(true);
        if (medicalDecision == "" || medicalDecision == undefined || medicalDecision == null) {
          setLoading(false);
        } else {
          const current_provider_id = getLocal("cvai-current-user-provider-id");
          let params = {};
          // if is_provider  == 1 will pass provider_id
          console.log("current_provider_id", current_provider_id);
          console.log("localProviderId", localProviderId);

          if (current_provider_id && current_provider_id !== localProviderId) {
            params = {
              text_template: valueText,
              cpt_codes: (cptData || []).map((r) => {
                return r?.cpt_code;
              }),
              icd_code: (dataICD || []).map((r) => {
                return r?.id;
              }),
              medical_decision_making: medicalDecision,
              provider_id: localProviderId,
              finalized_date: localFinalizedDate,
            };
          } else {
            params = {
              text_template: valueText,
              cpt_codes: (cptData || []).map((r) => {
                return r?.cpt_code;
              }),
              icd_code: (dataICD || []).map((r) => {
                return r?.id;
              }),
              medical_decision_making: medicalDecision,
              finalized_date: localFinalizedDate,
            };
          }
          if (isLock == 1) {
            await dispatch(unlock_finalize(procedure_id));
            await dispatch(unlockClinicProcedure());
            setIsLock(0);
          } else {
            await dispatch(set_cpt_code_review(procedure_id, params));
            setIsLock(1);
            await dispatch(lockClinicProcedure());
            await dispatch(clinicProviderReviewLoadInit(procedure_id, 1));
            await dispatch(
              setGlobalToastr({
                message: "The procedure is finalized",
              })
            );
          }
          await dispatch(get_cpt_code_review(procedure_id));
          setLoading(false);
          await dispatch(
            getPatientSchedules({
              date: scheduleParams?.date || moment().format("MM/DD/YYYY"),
              location: scheduleParams?.location || "",
              provider_id: scheduleParams?.provider_id || "",
              patient_id: "",
              only_clinics: 1,
              only_procedures: 0,
              only_us: 1,
              only_angio: 0,
            })
          );
        }
        await dispatch(setGlobalLoading(false));
      }
    } else {
      await dispatch(
        setGlobalToastr({
          header: "Alert",
          type: "failed",
          message: "This patient does not have any eligible insurance!",
        })
      );
    }
  };
  useEffect(() => {
    if (searchData && searchData.length > 0) {
      const checkedArray = (searchData || []).map((item) => {
        return {
          id: item?.id,
          cpt_code: item.code,
          short_desc: item.short_desc,
          created_at: item.created_at,
          updated_at: item.updated_at,
          fee: item.fee,
          cpt_code_name: item.cpt_code_name,
        };
      });
      setDataSearch(checkedArray);
    }
  }, [searchData]);

  useEffect(() => {
    if (CPT && CPT.cpt_codes && CPT.cpt_codes.length > 0) {
      const checkedArray = (CPT.cpt_codes || []).map((r) => {
        return {
          cpt_code: r.code,
          short_desc: r.short_desc,
          pivot: r.pivot,
        };
      });
      setCptData(checkedArray);
      setIsLock(CPT.is_locked);
    } else {
      setCptData([]);
      setIsLock(CPT?.is_locked || 0);
    }

    if (CPT.finalized_date) {
      setLocalFinalizedDate(CPT?.finalized_date);
    } else {
      setLocalFinalizedDate(moment().format("MM/DD/YYYY hh:mm:ss A"));
    }

    if (CPT?.provider_id) {
      setLocalProviderId(CPT?.provider_id);
    }
  }, [CPT]);

  const getTitle = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            ...titleStyle,
            minWidth: "75%",
            width: "75%",
            paddingLeft: 15,
            borderLeft: 0,
          }}
        >
          Review
        </div>
        <div style={{ ...titleStyle, minWidth: "25%", width: "25%" }}>Result</div>
      </div>
    );
  };

  const getActionTitle = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            ...titleStyle,
            minWidth: "30%",
            width: "30%",
            paddingLeft: 0,
            borderLeft: 0,
          }}
        >
          Meaningful Use
        </div>
        <div style={{ ...titleStyle, minWidth: "70%", width: "70%" }}>Recommendation</div>
      </div>
    );
  };

  const handleDate = (value) => {
    if (value) {
      let temp_value = moment(value)
        .hours(moment(localFinalizedDate).hours())
        .minutes(moment(localFinalizedDate).minutes())
        .seconds(moment(localFinalizedDate).seconds())
        .format("MM/DD/YYYY hh:mm:ss A");
      setLocalFinalizedDate(temp_value);
      handleChangeTime(temp_value);
    } else {
      setLocalFinalizedDate(moment().format("MM/DD/YYYY"));
      handleChangeTime(moment().format("MM/DD/YYYY hh:mm:ss A"));
    }
  };

  const handleTime = (value) => {
    let temp = moment(localFinalizedDate).hours(value.hours()).minutes(value.minutes()).seconds(value.seconds()).format("MM/DD/YYYY hh:mm:ss A");
    setLocalFinalizedDate(temp);
    handleChangeTime(temp);
  };

  const onChangeLocalProviderId = (value) => {
    setLocalProviderId(value);
    handleChangeProvider(value);
  };

  return (
    <Drawer
      title={`Summary Dashboard - ${fullName} ${getLocal("current_appointment_id") ? `[${getLocal("current_appointment_id")}]` : ""}`}
      placement="right"
      variable="permanent"
      className={`${sideNavStatus ? "wide" : ""} ${deceased == "Yes" || (redReasons.includes(cancelled_reason) && cancelled_reason) ? "red" : ""}`}
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      {loading ? (
        <Loading
          extra={{
            width: "40px",
            height: "40px",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
          color="#ffffff"
        />
      ) : (
        <div ref={ref} className="resourceContainer new">
          <ScrollWrapper>
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "8px",
                minHeight: "calc(100% - 116px)",
              }}
            >
              <div className="resourceInfo d-flex">
                <SubDataTable
                  title={getTitle()}
                  data={(dataPlan || []).map((item) => ({
                    label: (
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "8px",
                        }}
                      >
                        <span className="oneLineText">{item?.value}</span>
                      </p>
                    ),
                    value: (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div>
                          {item.processed === 1 ? (
                            <span
                              className="normal-icon"
                              style={{
                                width: "1rem",
                                minWidth: "1rem",
                                height: "18px",
                              }}
                            >
                              <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                            </span>
                          ) : (
                            <span
                              className="normal-icon"
                              style={{
                                width: "1rem",
                                minWidth: "1rem",
                                height: "18px",
                              }}
                            >
                              <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                            </span>
                          )}
                        </div>
                      </div>
                    ),
                  }))}
                  options={["label", "value"]}
                  widths={["75%", "25%"]}
                  minRows={7}
                  isFixed
                />
              </div>
              <div className="resourceInfo" style={{ marginTop: "1rem" }}>
                <ReviewIcdCode disableButton={is_locked} setDataICD={setDataICD} isHaveSearch={true} />
              </div>
              <div className="resourceInfo" style={{ marginTop: "1rem" }}>
                <EditableFormInput
                  id={"Medical_Decision"}
                  label={"Medical Decision Making"}
                  value={medicalDecision}
                  type={"check"}
                  options={[
                    { value: "Low-Complexity", label: "Low-Complexity" },
                    {
                      value: "Moderate-Complexity",
                      label: "Moderate-Complexity",
                    },
                    { value: "High-Complexity", label: "High-Complexity" },
                  ]}
                  optionKey={"value"}
                  valueKey={"label"}
                  enableErrorText={true}
                  helperText={"Medical Decision is required"}
                  required={true}
                  isError={!medicalDecision}
                  handleChange={(value) => saveTemp(value)}
                  disabled={is_locked}
                />
              </div>
              <div className="resourceInfo between" style={{ marginTop: "1rem" }}>
                <CptReview
                  DataOpSearch={dataSearch}
                  keySearch="cpt_code_name"
                  keyType="short_desc"
                  title="cpt_review"
                  keyValue="cpt_code"
                  rows={cptData}
                  removeItem={removeRow}
                  loading={false}
                  isResult={isResult}
                  query={searchInput}
                  selectItem={selectItem}
                  handleSearch={handleSearch}
                  isLocked={is_locked}
                />
              </div>
              <div className="resourceInfo d-flex" style={{ marginTop: "1rem" }}>
                <SubDataTable
                  isBrightBlueColor={{
                    color: "rgb(15, 98, 254)",
                    fontWeight: "600",
                  }}
                  title={getActionTitle()}
                  data={meaningful_uses}
                  options={["measure_desc", "recommendation"]}
                  widths={["30%", "70%"]}
                  minRows={3}
                  isFixed
                />
              </div>
              {usersCheck && usersCheck.id == 5 ? (
                <>
                  <div className="resourceInfo d-flex align-items-end required-field">
                    <EditableInput
                      label={"Provider"}
                      type={"check"}
                      options={only_providers}
                      value={localProviderId}
                      handleChange={onChangeLocalProviderId}
                      optionKey={"id"}
                      valueKey={"value"}
                      required={true}
                      helperText={"Provider is required"}
                      disabled={is_locked}
                    />
                  </div>
                  <div className="resourceInfo d-flex required-field">
                    <EditableFormInput
                      label={"Finalized Date"}
                      value={localFinalizedDate ? localFinalizedDate : null}
                      type="date"
                      handleChange={handleDate}
                      disabled={is_locked}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <div className="editableText material">
                      <div className={`MuiFormControl-root MuiTextField-root date-picker`}>
                        <TimePicker
                          disabled={is_locked}
                          format={"h:mm:ss A"}
                          use12Hours
                          placeholder="Time"
                          onChange={handleTime}
                          value={localFinalizedDate ? moment(localFinalizedDate) : null}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {usersCheck && (usersCheck?.is_provider == 1 || usersCheck?.is_admin == 1) ? (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    {usersCheck?.is_admin == 1 && showBack && (
                      <button
                        style={{
                          marginRight: 10,
                        }}
                        onClick={handleGoBackReview}
                      >
                        Go back to review
                      </button>
                    )}
                    <button
                      style={{
                        backgroundColor: statusAbort === "aborted" ? "#C6C6C6" : "#1164F2",
                      }}
                      onClick={saveAll}
                      disabled={statusAbort === "aborted"}
                    >
                      {isLock == 1 && statusAbort !== "aborted" ? "Unfinalize" : "Finalize +"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: statusAbort === "aborted" ? "#C6C6C6" : "#1164F2",
                      }}
                    >
                      {"Save for Review"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            )}
          </ScrollWrapper>
        </div>
      )}
    </Drawer>
  );
});

export default ReviewSideBar;
