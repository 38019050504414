import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import ProcedureDetail from "../../procedureDetail";
import AngioDetail from "../../procedureDetail/angioDetail";
import AIO from "../../procedureDetail/AIO";
import ClinicProcedure from "../../procedureDetail/clinicProcedure";
import ConditionDetail from "../../procedureDetail/ConditionDetail";
import MeaningfulUseDetail from "../../procedureDetail/meaningfulUse";
import UltrasoundDetail from "../../procedureDetail/ultrasoundDetail";

import { Tabs } from "@material-ui/core";
import { refrest_state_tab } from "../../../store/actions";
import { ProcedureDetailsHeaders } from "../constant";

const ProcedureDetails = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);

  const changeTab = (index) => {
    dispatch(refrest_state_tab());
    setActiveTab(index);
  };

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Clinical Logic" subStatus={""} isBarcode={false} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {ProcedureDetailsHeaders.map((tab, index) => (
                <div
                  key={`tabHeaders-${tab.label}-${index}`}
                  className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                  style={{ width: tab.width }}
                  onClick={() => changeTab(index)}
                >
                  {tab.label}
                </div>
              ))}
            </Tabs>
          </div>
        </div>
        {activeTab === 0 && <ProcedureDetail />}
        {activeTab === 1 && <ConditionDetail />}
        {activeTab === 2 && <AngioDetail />}
        {activeTab === 3 && <ClinicProcedure />}
        {activeTab === 4 && <MeaningfulUseDetail />}
        {activeTab === 5 && <UltrasoundDetail />}
        {activeTab === 6 && <AIO />}
      </div>
    </div>
  );
};

export default ProcedureDetails;
